import React, { useEffect, useState } from "react";
import Navbar from "../Dashboard/Navbar";
import SortIcon from "../../Images/FilterIcon.png";
import plus from "../../Images/Symbol.png";
import breadcrumb from "../../Images/breadcrumbhome.svg";
import Sidebar from "../Dashboard/Sidebar";
import { FilterProvider } from "../Dashboard/FilterContext";
import "../Request/RequestPage.css";
import Magnify from "../../Images/Magnify.png";
import ReactPaginate from "react-paginate";
import { IconContext } from "react-icons/lib";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import RightArrow from "../../Images/RightArrow.png";
import LeftArrow from "../../Images/LeftArrow.png";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { db } from "../Firebase/firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FilterMobile from "../../Images/Filtermobile.svg";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  or,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { FaBullseye } from "react-icons/fa6";
import InboxSegmentControl from "./InboxSegmentControl";
import RaisaLogo from "../../Images/RaisaN.svg";
import dis from "../../Images/discovernew.svg";
import inb from "../../Images/inboxnew.svg";
import req from "../../Images/requestnew.svg";
import sav from "../../Images/savenew.svg";
import noti from "../../Images/notificationnew.svg";
import Pcagination from "../Paginantion";
import MobilePagination from "../mobilepagination";
import disb from "../../Images/discoverblue.svg";
import inbb from "../../Images/inboxblue.svg";
import reqb from "../../Images/requestblue.svg";
import savb from "../../Images/saveblue.svg";
import notib from "../../Images/notifiyblue.svg";
import { toast, ToastContainer } from "react-toastify";
import { useAuth } from "../../AuthContext";
import { useRadioGroup } from "@mui/material";
const InboxPage = ({user1}) => {
  const location1 = useLocation();
  const user = location1.state?.currentUser;
  // Sample data for the tables
  //  const { user } = useAuth();

  const [currentUser, setCurrentUser] = useState(null);
  const [userConnectsList, setUserConnectsList] = useState([]);
  const [matchingPendingRequests, setMatchingPendingRequests] = useState([]);
  const [matchingSendRequests, setMatchingSendRequests] = useState([]);
  const [matchingRejectedRequests, setMatchingRejectedRequests] = useState([]);
  const [matchingAcceptedRequests, setMatchingAcceptedRequests] = useState([]);
  const [samplePendingList, setSamplePendingList] = useState([]);
  const [sampleFilterPendingList, setSampleFilterPendingList] = useState([]);
  const [sampleSendList, setSampleSendList] = useState([]);
  const [sampleFilterSendList, setSampleFilterSendList] = useState([]);
  const [sampleRejectList, setSampleRejectList] = useState([]);
  const [sampleFilterRejectList, setSampleFilterRejectList] = useState([]);

  const [mydata, setMyData] = useState(null); // Initialize as null or empty object
  const [messages, setMessages] = useState([]); // Store fetched messages

  const [searchQuery, setSearchQuery] = useState("");
  //page
  const [pendingPage, setPendingPage] = useState(0);
  const [sendPage, setSendPage] = useState(0);
  const [rejectPage, setRejectPage] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [sortOptions, setSortOptions] = useState({
    newest: false,
    oldest: false,
    az: false,
    za: false,
  });
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState("test");
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [buttonActive, setButtonActive] = useState(null);
  const location = useLocation();
  const handleButtonClick = (button) => {
    setButtonActive(button);
    localStorage.setItem("buttonActive", button);
  };


  useEffect(() => { 
   
    const savedActiveButton = localStorage.getItem("buttonActive");
    if (savedActiveButton) {
      setButtonActive(savedActiveButton);
    }

    const currentPath = location.pathname.replace("/", "");
    setButtonActive(currentPath);
  }, [location.pathname]);

  // Handle mouse enter and leave for hover state
  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  const navigate = useNavigate(); // To programmatically navigate after logout

  const handlePageChange = (rejectPage) => {
    setRejectPage(rejectPage - 1); // Adjust for 0-based index
  };

  const handleLogout = () => {
    localStorage.removeItem("selectedProject");
    const auth = getAuth(); // Get the current authentication instance
    signOut(auth)
      .then(() => {
        // Sign-out successful, redirect user to login page
        navigate("/login"); // Redirecting to login page after logout
      })
      .catch((error) => {
        // An error happened during logout
        console.error("Logout Error:", error);
      });
  };

  useEffect(  () => {
    const login = ()=>{
    console.log("user checking",user)
    const auth = getAuth();
    // Listen for authentication state changes
    const unsubscribe =  onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }

    });

    // Cleanup the listener on unmount
    return () => unsubscribe();}

    login()
  }, []);

  
  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user);
        await user.reload(); // Reload to get the latest email verification status

        // Real-time listener for user's Firestore document
        const userDocRef = doc(db, "User", user.uid);
        const unsubscribeFirestore = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            // Check if 'verified' field is true and update state
            setIsVerified(userData.verified || false);
          }
        });

        // Stop loading once the document is fetched
        setLoading(false);

        // Clean up Firestore listener on unmount
        return () => unsubscribeFirestore();
      }
      setLoading(false); // Stop loading if no user is logged in
    });

    // Cleanup auth listener on unmount
    return () => unsubscribeAuth();
  }, []);

 

  // const [isChecking, setIsChecking] = useState(true); // To prevent infinite loops

  // useEffect(() => {
  //   const auth = getAuth();
  //   let isAuthResolved = false; // Flag to stop the loop when auth state is determined

  //   const checkAuthState = async () => {
  //     while (!isAuthResolved) {
  //       await new Promise((resolve) => {
  //         // Firebase auth listener
  //         const unsubscribe = onAuthStateChanged(auth, (user) => {
  //           if (user) {
  //             setCurrentUser(user); // Set the authenticated user
  //             isAuthResolved = true; // Exit the loop
  //           } else {
  //             setCurrentUser(null); // User not authenticated
  //             isAuthResolved = true; // Exit the loop
  //           }
  //           unsubscribe(); // Stop the listener
  //           resolve(); // Resolve the promise
  //         });
  //       });
  //     }
  //     setIsChecking(false); // Mark checking as complete
  //   };

  //   checkAuthState();

  //   // Cleanup function (though `unsubscribe` is already called in the loop)
  //   return () => {
  //     isAuthResolved = true;
  //   };
  // }, []);

  // useEffect(() => {
  //   const auth = getAuth();
  //   const unsubscribe = onAuthStateChanged(auth, async (user) => {
  //     if (!user) {
  //       setCurrentUser(user);
  //       await user.reload();
  //     } else {
  //       setCurrentUser(user);
  //       await user.reload();
  //     }
  //   });
  //   console.log("user checking fetchuser");
  //   return () => unsubscribe();
  // });

  /*    // Fetch userConnects data
   const fetchUserConnects = async () => {
    try {
      // Reference to the UserConnects collection
      const userConnectsCollection = collection(db, "UserConnects");

      // Fetch all documents from the collection
      const querySnapshot = await getDocs(userConnectsCollection);

      // Map through the documents to create a list
      const userConnectsList = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Document ID
        ...doc.data(), // Document data
      }));

     
      setUserConnectsList(userConnectsList);

      

      const matchingRejectedRequests = userConnectsList.filter(
        (ele) =>
          ( ele.toUserId === currentUser?.uid || 
            ele.userId === currentUser?.uid) &&
          ele.status === "Accepted"
      );

      

      

      setMatchingPendingRequests(matchingPendingRequests);
      setMatchingSendRequests(matchingSendRequests);
      setMatchingRejectedRequests(matchingRejectedRequests);
      setMatchingAcceptedRequests(matchingAcceptedRequests)

      
      console.log(matchingRejectedRequests)
    } catch (e) {
      toast.error("Error fetching Data");
      return [];
    }
  };

  useEffect(() => {
    fetchUserConnects();
  }, [db, currentUser?.uid]); */

  // function to fetch the current user id and project id   from userproject

  //  const [mydata, setMyData]= useState([])
  //  const fetchMyData = async () => {
  //   try {
  //     const userProjectsCollection = collection(db, "UserProject");
  //     const userProjectsQuery = query(
  //       userProjectsCollection,
  //       where("userId", "==", currentUser?.uid)
  //     );
  //     console.log("current user id", currentUser?.uid)
  //     const querySnapshot = await getDocs(userProjectsQuery);

  //     // Map through the documents to create a list
  //     const userProject = querySnapshot.docs.map((doc) => ({
  //       id: doc.id, // Document ID
  //       ...doc.data(), // Document data
  //     }));
  //     console.log("fetching my data up ",userProject[0])
  //     setMyData(userProject[0]);

  //     console.log("fetching my data ",mydata)
  //   } catch (e) {
  //     console.error("Error fetching User Connects: ", e);
  //   }
  // };

  // useEffect( ()=>{
  //   fetchMyData()
  // },[])

  // const fetchinboxmsglist = async () => {
  //   try {
  //     // Reference to the "inbox" collection in Firestore
  //     const inboxRef = collection(db, "inbox");

  //     // Assuming `mydata?.id` contains the current project ID
  //     const q1 = query(
  //       inboxRef,
  //       where("toProjectId", "==", mydata?.id),
  //       where("fromProjectId", "==", mydata?.id)
  //     );

  //     // Fetch all the messages based on the query
  //     const querySnapshot = await getDocs(q1);

  //     // Process the fetched messages
  //     const messages = [];
  //     querySnapshot.forEach((doc) => {
  //       messages.push({ id: doc.id, ...doc.data() }); // Include the document ID and data
  //     });

  //     console.log("Fetched Messages:", messages);

  //     // Return or handle the messages as needed
  //     return messages;
  //   } catch (error) {
  //     console.error("Error fetching messages:", error);
  //     throw error; // Re-throw if you want to handle it in the calling function
  //   }
  // };

  // useEffect(()=>{
  //   fetchinboxmsglist()
  // })
  //display all the list

  // const [mydata, setMyData] = useState(null); // Initialize as null or empty object
  //   const [messages, setMessages] = useState([]); // Store fetched messages

  //   const fetchMyData = async () => {
  //     try {
  //       const userProjectsCollection = collection(db, "UserProject");
  //       const userProjectsQuery = query(
  //         userProjectsCollection,
  //         where("userId", "==", currentUser?.uid)
  //       );
  //       console.log("Current user ID:", currentUser?.uid);

  //       const querySnapshot = await getDocs(userProjectsQuery);
  //       const userProject = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //       console.log("Fetched my data:", userProject[0]);
  //       setMyData(userProject[0]); // Set the first project as `mydata`
  //     } catch (e) {
  //       console.error("Error fetching User Connects:", e);
  //     }
  //   };

  //   const fetchInboxMsgList = async () => {
  //     if (!mydata?.id) {
  //       console.warn("Cannot fetch messages, mydata is not set");
  //       return;
  //     }

  //     try {
  //       const inboxRef = collection(db, "inbox");
  //       // const q1 = query(
  //       //   inboxRef,
  //       //   where("toProjectId", "==", mydata?.id),
  //       //   where("fromProjectId", "==", mydata?.id)
  //       // );

  //       const q1 = query(
  //         inboxRef,
  //         or(
  //           where("toProjectId", "==", mydata.id),
  //           where("fromProjectId", "==", mydata.id)
  //         ),
  //         orderBy("dateTime", "desc") // Ensure `timestamp` field exists

  //       );

  //       const querySnapshot = await getDocs(q1);
  //       const fetchedMessages = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //       const uniqueMessages = [
  //         ...new Map(
  //           fetchedMessages.map((msg) => [
  //             `${msg.toProjectId}-${msg.fromProjectId}`,
  //             // Create a unique key
  //             msg,
  //           ])
  //         ).values(),
  //       ];

  //       console.log("Unique messages:", uniqueMessages);
  //       setMessages(uniqueMessages); // Store unique messages in state

  //       // console.log("Fetched messages:", fetchedMessages);
  //       // setMessages(fetchedMessages); // Store messages in state

  //       const currentUserId = mydata.id; // Replace this with the actual current user ID

  // // Separate the messages where `toProjectId` matches the current user ID
  // const messagesToCurrentUser = uniqueMessages.filter(
  //   (msg) => msg.toProjectId === currentUserId
  // );

  // // Separate the messages where `fromProjectId` matches the current user ID
  // const messagesFromCurrentUser = uniqueMessages.filter(
  //   (msg) => msg.fromProjectId === currentUserId
  // );

  // console.log("Messages to current user:", messagesToCurrentUser);
  // console.log("Messages from current user:", messagesFromCurrentUser);

  //     } catch (error) {
  //       console.error("Error fetching messages:", error);
  //     }
  //   };

  //   // Fetch `mydata` on component mount
  //   useEffect(() => {
  //     fetchMyData();
  //   }, []); // Empty dependency array ensures this runs only once

  //   // Fetch inbox messages when `mydata` is updated
  //   useEffect(() => {
  //     if (mydata) {
  //       fetchInboxMsgList();
  //     }
  //   }, [mydata]); // Dependency on `mydata`

  //   // Function to fetch messages for a specific projectId
  // const fetchMessagesByProjectId = async (projectId) => {
  //   try {
  //     const inboxRef = collection(db, "inbox");

  //     // Query for messages where projectId matches either `toProjectId` or `fromProjectId`
  //     const q = query(
  //       inboxRef,
  //       or(where("toProjectId", "==", projectId), where("fromProjectId", "==", projectId))
  //     );

  //     const querySnapshot = await getDocs(q);

  //     // Process and return the messages
  //     const messages = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));

  //     return messages;
  //   } catch (error) {
  //     console.error("Error fetching messages for projectId:", projectId, error);
  //     return [];
  //   }
  // };

  // // Function to handle the entire process
  // const handleFetchMessagesForAcceptedRequests = async () => {
  //   try {
  //     // Filter `matchingRejectedRequests` where status === "Accepted"
  //     const acceptedRequests = matchingRejectedRequests.filter((request) => request.status === "Accepted");

  //     // Fetch messages for each request
  //     const messagesByProject = await Promise.all(
  //       acceptedRequests.map(async (request) => {
  //         // const projectId =
  //         //   request?.toUserId === currentUser?.uid ? request?.toProjectId : request?.fromProjectId;
  //         const name =
  //           request?.toUserId === currentUser?.uid ? request?.name : request?.toname;

  //         // Fetch messages for this projectId
  //         // const messages = await fetchMessagesByProjectId(projectId);

  //         return { name};
  //       })
  //     );

  //     // Log or process the fetched messages
  //     console.log("Messages grouped by project:", messagesByProject);

  //     // Optionally update state or handle the data
  //     // setMessagesByProject(messagesByProject);
  //   } catch (error) {
  //     console.error("Error processing accepted requests:", error);
  //   }
  // };

  // // Trigger the function (e.g., in useEffect or on some user interaction)
  // useEffect(() => {
  //   handleFetchMessagesForAcceptedRequests();
  // }, [matchingRejectedRequests]);

  // // Function to fetch project IDs for multiple names
  // // Function to fetch project IDs for a list of names
  // const fetchProjectIdsForNames = async (names) => {
  //   try {
  //     const userProjectsRef = collection(db, "UserProject");
  //     const projectIds = [];

  //     // Loop through each name to fetch its project ID
  //     for (const name of names) {
  //       // Query to match the name in the UserProject collection
  //       const q = query(userProjectsRef, where("name", "==", name));
  //       const querySnapshot = await getDocs(q);

  //       // Extract project IDs from the results
  //       querySnapshot.forEach((doc) => {
  //         projectIds.push({ name, projectId: doc.id });
  //       });
  //     }

  //     return projectIds; // Return the array of { name, projectId } pairs
  //   } catch (error) {
  //     console.error("Error fetching project IDs for names:", error);
  //     return [];
  //   }
  // };

  // // Example usage
  // const fetchProjectIdsFromMessages = async (messages) => {
  //   // Extract unique names from messages
  //   const names = Array.from(
  //     new Set(
  //       messages.map(
  //         (message) =>
  //           message?.toUserName || message?.fromUserName // Adjust field names if needed
  //       )
  //     )
  //   );

  //   // Fetch project IDs for the extracted names
  //   const projectIds = await fetchProjectIdsForNames(names);

  //   console.log("Project IDs for names:", projectIds);

  //   return projectIds;
  // };

  // // Call the function with messages

  // fetchProjectIdsFromMessages(messages);

  // const fetchProjectIdsForNames = async (names) => {
  //   try {
  //     const userProjectsRef = collection(db, "UserProject");
  //     const projectIdMapping = [];

  //     for (const name of names) {
  //       // Query to find the project by name
  //       const q = query(userProjectsRef, where("name", "==", name));
  //       const querySnapshot = await getDocs(q);

  //       // Map each matching document to its project ID
  //       querySnapshot.forEach((doc) => {
  //         projectIdMapping.push({ name, projectId: doc.id });
  //       });
  //     }

  //     return projectIdMapping; // Array of { name, projectId } pairs
  //   } catch (error) {
  //     console.error("Error fetching project IDs for names:", error);
  //     return [];
  //   }
  // };

  // // Function to extract names from the accepted requests
  // const mapProjectNamesToIds = async (acceptedRequests) => {
  //   // Extract unique names from the requests
  //   const names = Array.from(
  //     new Set(acceptedRequests.map((request) => request.name))
  //   );

  //   // Fetch project IDs for the names
  //   const projectIdMapping = await fetchProjectIdsForNames(names);

  //   console.log("Project IDs for each name:", projectIdMapping);
  //   return projectIdMapping;
  // };

  // // Example usage
  // const acceptedRequests = [
  //   { name: "Polkadot" },
  //   { name: "testlogin" },
  //   { name: "Multi Check Demo" },
  //   { name: "UniSwap" },
  // ];

  // mapProjectNamesToIds(messagesByProject);

  // const fetchProjectIdsForNames = async (names) => {
  //   try {
  //     const userProjectsRef = collection(db, "UserProject");
  //     const projectIdMapping = [];

  //     for (const name of names) {
  //       // Query to find the project by name
  //       const q = query(userProjectsRef, where("name", "==", name));
  //       const querySnapshot = await getDocs(q);

  //       // Map each matching document to its project ID
  //       querySnapshot.forEach((doc) => {
  //         projectIdMapping.push({ name, projectId: doc.id });
  //       });
  //     }

  //     return projectIdMapping; // Array of { name, projectId } pairs
  //   } catch (error) {
  //     console.error("Error fetching project IDs for names:", error);
  //     return [];
  //   }
  // };

  // const mapProjectNamesToIds = async (messagesByProject) => {
  //   try {
  //     // Extract unique names from messagesByProject
  //     const names = Array.from(
  //       new Set(messagesByProject.map((message) => message.name))
  //     );

  //     // Fetch project IDs for these names
  //     const projectIdMapping = await fetchProjectIdsForNames(names);

  //     console.log("Mapped project IDs:", projectIdMapping);

  //     // Return messagesByProject enriched with project IDs
  //     return messagesByProject.map((message) => {
  //       const projectMatch = projectIdMapping.find(
  //         (project) => project.name === message.name
  //       );
  //       return {
  //         ...message,
  //         projectId: projectMatch ? projectMatch.projectId : null,
  //       };
  //     });
  //   } catch (error) {
  //     console.error("Error mapping names to project IDs:", error);
  //     return [];
  //   }
  // };

  // // Example usage
  // const fetchAndMapProjects = async () => {
  //   try {
  //     const acceptedRequests = matchingRejectedRequests.filter((request) => request.status === "Accepted");
  //     const messagesByProject = await Promise.all(
  //       acceptedRequests.map(async (request) => {
  //         const name =
  //           request?.toUserId === currentUser?.uid ? request?.name : request?.toname;
  //         return { name };
  //       })
  //     );

  //     // Fetch project IDs and enrich messagesByProject
  //     const enrichedMessagesByProject = await mapProjectNamesToIds(messagesByProject);
  //     console.log("Enriched Messages by Project:", enrichedMessagesByProject);
  //   } catch (error) {
  //     console.error("Error fetching and mapping projects:", error);
  //   }
  // };

  // fetchAndMapProjects();

  const handleSortChange = (e) => {
    const { name, checked } = e.target;
    setSortOptions({ ...sortOptions, [name]: checked });
  };

  useEffect(() => {
    setSampleFilterRejectList(
      messages.filter((item, index) => {
        return (index >= rejectPage * 10) & (index < (rejectPage + 1) * 10);
      })
    );
  }, [matchingRejectedRequests, rejectPage]);

  //   const fetchMyData = async () => {
  //     if(!currentUser){
  //       return
  //     }
  //     if(mydata){
  //       return
  //     }
  //     try {
  //       const userProjectsCollection = collection(db, "UserProject");
  //       const userProjectsQuery = query(
  //         userProjectsCollection,
  //         where("userId", "==", currentUser?.uid)
  //       );
  //       console.log("Current user ID:", currentUser?.uid);

  //       const querySnapshot = await getDocs(userProjectsQuery);
  //        const userProject = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //         }));

  //         console.log("Fetched my data:", userProject[0]);
  //        setMyData(userProject[0]); // Set the first project as `mydata`
  //        console.log(" my data:", mydata);
  //        await fetchInboxMsgList()
  //       } catch (e) {
  //       console.error("Error fetching User Connects:", e);
  //     }
  //   };

  //   const fetchInboxMsgList = async () => {
  //     if (!mydata?.id) {
  //       console.warn("Cannot fetch messages, mydata is not set");
  //       return;
  //     }

  //     try {
  //       const inboxRef = collection(db, "inbox");
  //       // const q1 = query(
  //       //   inboxRef,
  //       //   where("toProjectId", "==", mydata?.id),
  //       //   where("fromProjectId", "==", mydata?.id)
  //       // );

  //       const q1 = query(
  //         inboxRef,

  //           where("toProjectId", "==", mydata?.id),

  //         orderBy("dateTime","desc") // Ensure `timestamp` field exists

  //       );

  //       const querySnapshot = await getDocs(q1);
  //       const fetchedMessages = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //      /*  const uniqueMessages = [
  //         ...new Map(
  //           fetchedMessages.map((msg) => [
  //             `${msg.toProjectId}-${msg.fromProjectId}`,
  //             // Create a unique key
  //             msg,
  //           ])
  //         ).values(),
  //       ];

  //       console.log("Unique messages:", uniqueMessages); */
  //       /*setMessages(uniqueMessages); */ // Store unique messages in state

  //        console.log("Fetched messages:", fetchedMessages);
  //        setMessages(fetchedMessages); // Store messages in state

  //        console.log(" messages:", messages);

  // /*
  //       const currentUserId = mydata.id; // Replace this with the actual current user ID

  // // Separate the messages where `toProjectId` matches the current user ID
  // const messagesToCurrentUser = uniqueMessages.filter(
  //   (msg) => msg.toProjectId === currentUserId
  // );
  // setMessagesToCurrentUser( messagesToCurrentUser)
  // // Separate the messages where `fromProjectId` matches the current user ID
  // const messagesFromCurrentUser = uniqueMessages.filter(
  //   (msg) => msg.fromProjectId === currentUserId
  // );

  // console.log("Messages to current user:", messagesToCurrentUser);
  // console.log("Messages from current user:", messagesFromCurrentUser);
  //  */

  //     } catch (error) {
  //       console.error("Error fetching messages:", error);
  //     }
  //   };

  //   fetchMyData()

  // Fetch `mydata` on component mount
  // Empty dependency array ensures this runs only once

  // Fetch inbox messages when `mydata` is updated
  // useEffect(() => {

  //   if(mydata){
  //        return}
  //   else{ fetchMyData();}

  // }, [ mydata]); // Dependency on `mydata`

  // useEffect(() => {
  //   // const fetchData = async () => {
  //   //    // Ensure this sets `mydata` correctly
  //   //   await fetchInboxMsgList(); // Then fetch messages
  //   // };

  //   if (currentUser){
  //      fetchData();}
  // }, [currentUser]);
  const [messagesWithNames, setMessagesWithNames] = useState([]);
  const fetchMyData = async (/* retryCount = 5, delay = 1000 */) => {
    // if (!currentUser) {
    //   console.warn("No current user available.");
    //   return;
    // }

    if (mydata) {
      console.log("My data already set:", mydata);
      return;
    }

    try {
      console.log("qwerty",user1?.id)
      const userProjectsCollection = collection(db, "UserProject");
      const userProjectsQuery = query(
        userProjectsCollection,
        where("userId", "==", user1?.uid)
      );
      console.log("Fetching data for user ID:", user1?.uid);

      const querySnapshot = await getDocs(userProjectsQuery);
      const userProject = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (userProject.length > 0) {
        console.log("Fetched my data:", userProject[0]);
        setMyData(userProject[0]); // Trigger state update
      } else {
        console.warn("No projects found for user.");
        /* if (retryCount > 0) {
          /* console.log(`Retrying fetchMyData (${retryCount} retries left)...`);
          setTimeout(() => fetchMyData(retryCount - 1, delay), delay); */
        // } */
      }
    } catch (e) {
      console.error("Error fetching User Projects:", e);
      // if (retryCount > 0) {
      //   console.log(`Retrying fetchMyData (${retryCount} retries left)...`);
      //   setTimeout(() => fetchMyData(retryCount - 1, delay), delay);
      // }
    }
  };

  const fetchInboxMsgList = async () => {
    // if (!mydata?.id) {
    //   console.warn("Cannot fetch messages, mydata is not set.");
    //   return;
    // }

    try {
      console.log("experi",mydata?.id)
      const inboxRef = collection(db, "inbox");
      const q1 = query(
        inboxRef,
        where("toProjectId", "==", mydata?.id),
        orderBy("dateTime", "desc") // Ensure `dateTime` field exists in Firestore
      );

      const querySnapshot = await getDocs(q1);
      const fetchedMessages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log("Fetched messages:", fetchedMessages);
      setMessages(fetchedMessages);
      
      
      console.log("Fetched messagesvvvv:", fetchedMessages); // Store messages in state
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  // UseEffect to Trigger fetchInboxMsgList When mydata is Updated
  // useEffect(() => {
  //   if (mydata?.id) {
  //     console.log("mydata updated, fetching messages...");
  //     fetchInboxMsgList();
  //   }
  // }, [mydata]);

  // Call fetchMyData
  // useEffect(() => {
  //   fetchMyData();
  // }, []);

 
  const fetchProjectName = async (fromProjectId) => {
    try {
      const projectDoc = await getDoc(doc(db, "UserProject", fromProjectId));
      if (projectDoc.exists()) {
        const projectData = projectDoc.data();
        console.log("Project Name:", projectData.name);
        console.log("Request Type:", projectData.requestType);
        console.log("Location:", projectData.country);

        return {
          name: projectData.name,
          requestType: projectData.requestType || [], // Default to empty array if not present
          country: projectData.country || "Unknown Location", // Default to "Unknown Location" if not present
          profilePicture: projectData?.profilePicture,
        };
      } else {
        console.warn(`No project found for ID: ${fromProjectId}`);
        return {
          name: "Unknown Project",
          requestType: [],
          country: "Unknown Location",
          profilePicture: "no",
        };
      }
    } catch (error) {
      console.error(`Error fetching project for ID ${fromProjectId}:`, error);
      return {
        name: "Error fetching name",
        requestType: [],
        country: "Error fetching location",
        profilePicture: "no",
      };
    }
  };

  const fetchProjectNames = async ( messages) => {
    try {
      const updatedMessages = await Promise.all(
        messages.map(async (msg) => {
          const projectData = await fetchProjectName(msg.fromProjectId); // Fetch project data

          return {
            ...msg,
            projectName: projectData.name,
            requestType: projectData.requestType, // Add the requestType array
            country: projectData.country, // Add the location
            profilePicture: projectData.profilePicture,
          };
        })
      );

      console.log("Updated Messages:", updatedMessages);
      setMessagesWithNames(updatedMessages); // Update state with the enriched messages
    } catch (error) {
      console.error("Error updating messages with project names:", error);
      // Fallback in case of a major error
      // const fallbackMessages = messages.map((msg) => ({
      //   ...msg,
      //   projectName: "Error fetching name",
      //   requestType: [],
      //   country: "Error fetching location",
      //   profilePicture:"no"
      // }));
      // setMessagesWithNames(fallbackMessages);
    }
  };

  // useEffect(() => {
  //   if (messagesWithNames) {
  //     return;
  //   } else {
  //     fetchProjectNames();
  //   }
  // });
  // const fetchProjectNamesWithWhere = async (messages) => {


  //   try {
  //     // Extract all unique fromUserId values from messages
  //     const userIds = [...new Set(messages.map((msg) => msg.fromUserId))];
  
  //     // Query Firestore to fetch all matching projects
  //     const q = query(collection(db, "Userproject"), where("__name__", "in", userIds));
  //     const querySnapshot = await getDocs(q);
  
  //     // Map the results to a dictionary for quick lookup
  //     const projectMap = {};
  //     querySnapshot.forEach((doc) => {
  //       projectMap[doc.id] = doc.data().name;
  //     });
  
  //     // Enrich the messages with project names
  //     const updatedMessages = messages.map((msg) => ({
  //       ...msg,
  //       projectName: projectMap[msg.fromUserId] || "Unknown Project",
  //     }));
  
  //     console.log("Updated Messages:", updatedMessages);
  //     setMessagesWithNames(updatedMessages); // Update the state
  //   } catch (error) {
  //     console.error("Error fetching project names with where query:", error);
  
  //     // Fallback: Assign an error message for all messages
  //     const fallbackMessages = messages.map((msg) => ({
  //       ...msg,
  //       projectName: "Error fetching name",
  //     }));
  //     setMessagesWithNames(fallbackMessages);
  //   }
  // };


  const filterLatestMessages = (messages) => {
    // Create a map to store the latest message for each FromProjectId
    const latestMessagesMap = new Map();
  
    messages.forEach((msg) => {
      const currentProjectId = msg.fromProjectId;
      console.log("currnet project ",currentProjectId)
      const existingMessage = latestMessagesMap.get(currentProjectId);
      console.log("existing msg project ",existingMessage)
      // If no message exists for this FromProjectId, or the current message is newer, update the map
      if (!existingMessage || new Date(msg.dateTime) > new Date(existingMessage.dateTime)) {
        latestMessagesMap.set(currentProjectId, msg);
      }
    });
  
    // Convert the map values back to an array
    return Array.from(latestMessagesMap.values());
  };
  
  // // Usage
  // const latestMessagess = filterLatestMessages(messages);
  // console.log("Latest messages for each FromProjectId:", latestMessagess);
 
  // fetchProjectNames(latestMessagess)
  

  // Effect to Fetch MyData
  useEffect(() => {
    fetchMyData();
  }, [user1]);

  // Effect to Fetch Messages When MyData Updates
  useEffect(() => {
    
    if (mydata)
   {fetchInboxMsgList();}
  
  }, [mydata ]);

  // Effect to Enrich Messages with Project Names
  useEffect(() => {
   
      if (messages.length > 0) {
        
        const latestMessages = filterLatestMessages(messages);
        fetchProjectNames(latestMessages);
      }
     
    

    // Cleanup function to clear the interval when component is unmounted or when dependencies change
    
  }, [messages]);

  

  const handleFilterOpen = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click happened outside the modal content
      if (
        isMobileFilterOpen &&
        !event.target.closest(".filter-mobile-design")
      ) {
        setIsMobileFilterOpen(false);
      }
    };

    // Add the event listener if the modal is open
    if (isMobileFilterOpen) {
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      // Remove the event listener when the modal closes or on cleanup
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobileFilterOpen]);

  document.body.style.background = "rgba(234, 239, 255, 1)";

  return (
    <div className="request-page-main-container">
      <FilterProvider>
        <div className="request-page-navbar">
          <Navbar />
        </div>
        <div className="req-navbar-m">
          <div className="req-nav-cntr">
            <img
              /* className="req-raisa-logo-img" */ src={RaisaLogo}
              alt="raisa"
              onClick={() => {
                navigate("/dashboard");
              }}
            />
            <ul className="req-header-ul">
              {/* <li
        className="headerli dis"
       
      >
          <Link to="/dashboard" style={{ textDecoration: 'none'}} >
        <img className="headerli-img " src={dis} alt="discover" />
        
       
          
          </Link>
        
      </li> */}
              <li
                className="req-headerli"
                onMouseEnter={() => handleMouseEnter("inbox")}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="/inbox"
                  style={{ textDecoration: "none", height: "18px" }}
                >
                  <img
                    className="headerli-img"
                    src={
                      hoveredItem === "inbox" || buttonActive === "inbox"
                        ? inbb
                        : inb
                    }
                    alt="inbox"
                  />
                </Link>
              </li>

              <li
                className="req-headerli"
                onMouseEnter={() => handleMouseEnter("requestpage")}
                onMouseLeave={handleMouseLeave}
              >
                <Link
                  to="/requestpage"
                  style={{ textDecoration: "none", height: "18px" }}
                >
                  <img
                    className="headerli-img"
                    src={
                      hoveredItem === "requestpage" ||
                      buttonActive === "requestpage"
                        ? reqb
                        : req
                    }
                    alt="request"
                  />
                </Link>
              </li>
              <li className="req-headerli" onClick={() => alert("Coming Soon")}>
                <Link style={{ textDecoration: "none", height: "18px" }}>
                  <img className="headerli-img" src={sav} alt="saved" />
                </Link>
              </li>
              <li className="req-headerli" onClick={() => alert("Coming Soon")}>
                {" "}
                <Link style={{ textDecoration: "none", height: "18px" }}>
                  <img
                    className="headerli-img"
                    src={noti}
                    alt="notifications"
                  />
                </Link>
              </li>
            </ul>

            <button className="req-logout-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
        <div className="requestpage-container">
          <div className="inbox-segmented-control">
            <div className="inbox-bread-container">
              <div className="bbcmb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <img src={breadcrumb} alt="home" />
                    <div className="a-div">
                      <a href="/dashboard">Dashboard</a>
                    </div>
                  </li>

                  <li class="breadcrumb-item active" aria-current="page">
                    Inbox
                  </li>
                </ol>
              </div>
            </div>
          </div>
          <div className="sidebar-req-cntainer">
            <div className="requestpage-sidebar">
              <Sidebar />
            </div>
            <div className="requesttable">
              <div className="search-filter">
                <div className="search-bar-req">
                  <input
                    className="input-req"
                    style={{
                      borderRadius: "11px",
                      border: "1px solid #B7B7B7",
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#282828",
                      backgroundImage: `url(${Magnify})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "17px center", // Increased horizontal position to move the icon right
                      backgroundSize: "24px 24px", // Set image size to 24x24
                      paddingLeft: "55px", // Adjusted padding to maintain space between the image and the text
                      height: "40px", // Adjust height to ensure proper alignment
                    }}
                    type="text"
                    placeholder="search project..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  {/* <div className="filter-mobile-design">
                 <p>hi</p>
               </div>  */}
                </div>
                <div className="filter-mobile-design">
                  <img
                    src={FilterMobile}
                    alt="filter-mobile"
                    onClick={handleFilterOpen}
                  />
                  {isMobileFilterOpen && (
                    <div className="requestmodal-content1">
                      <Sidebar />
                    </div>
                  )}
                </div>

                <div className="filterdiv">
                  <div className="requestfilter-icon">
                    <span
                      onClick={() => setShowModal(!showModal)}
                      style={{ cursor: "pointer", fontSize: "2rem" }}
                    >
                      <img src={SortIcon} alt="sort-list" />
                    </span>
                  </div>

                  {/* The modal appearing beside the image */}
                  {showModal && (
                    /* <div className="requestmodal-content">
                    <h4>Sort Options</h4>
                    <label>
                      <input
                        type="checkbox"
                        name="newest"
                        checked={sortOptions.newest}
                        onChange={handleSortChange}
                      />
                      Newest First
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="oldest"
                        checked={sortOptions.oldest}
                        onChange={handleSortChange}
                      />
                      Oldest First
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="az"
                        checked={sortOptions.az}
                        onChange={handleSortChange}
                      />
                      A-Z
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="za"
                        checked={sortOptions.za}
                        onChange={handleSortChange}
                      />
                      Z-A
                    </label>
                  </div>  */
                    <div className="requestmodal-content">
                      {/* <h4>Sort Options</h4> */}
                      <label className="checkbox-label">
                        <input
                          className="input-request-checkbox"
                          type="checkbox"
                          name="newest"
                          checked={sortOptions.newest}
                          onChange={handleSortChange}
                        />
                        Newest First
                      </label>
                      <label className="checkbox-label">
                        <input
                          className="input-request-checkbox"
                          type="checkbox"
                          name="oldest"
                          checked={sortOptions.oldest}
                          onChange={handleSortChange}
                        />
                        Oldest First
                      </label>
                      <label className="checkbox-label">
                        <input
                          className="input-request-checkbox"
                          type="checkbox"
                          name="az"
                          checked={sortOptions.az}
                          onChange={handleSortChange}
                        />
                        A-Z
                      </label>
                      <label className="checkbox-label">
                        <input
                          className="input-request-checkbox"
                          type="checkbox"
                          name="za"
                          checked={sortOptions.za}
                          onChange={handleSortChange}
                        />
                        Z-A
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <InboxSegmentControl
                sortOptions={sortOptions}
                searchQuery={searchQuery}
                // matchingRejectedRequests={matchingRejectedRequests}

                // sampleReject={sampleFilterRejectList}
                messages={messagesWithNames}
              />
              <div className="pagination-request">
                {/* <ReactPaginate
    containerClassName={"pagination-container-request"}
    activeClassName={"active-request"}
    pageClassName={"page-item-request"}
    breakLabel="..."
    onPageChange={(event) => setRejectPage(event.selected)}
    pageCount={Math.ceil(matchingRejectedRequests.length/10)}
    previousLabel={
      <div className="arrow-request-paginate">
        <IconContext.Provider value={{ color: "grey", size: "25px" }}>
          <img src={LeftArrow} alt="left-arrow"/>
        </IconContext.Provider>
      </div>
    }
    nextLabel={
      <div className="arrow-request-paginate">
        <IconContext.Provider value={{ color: "grey", size: "25px" }}>
         <img src={RightArrow} alt="right-arrow"/>
        </IconContext.Provider>
      </div>
    }
  /> */}
                <MobilePagination
                  currentPage={rejectPage + 1}
                  totalPages={Math.ceil(messagesWithNames.length / 10)}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </FilterProvider>
      <ToastContainer />
    </div>
  );
};

export default InboxPage;
