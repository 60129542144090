import React from 'react'
import './ProfileSidebar.css';
const ShimmerUiProjectSlider = () => {
  return (
    <div className="sidebar-container">
      <div className="profile-sidebar1">
      <div class="loading-dots">
    <span>.</span><span>.</span><span>.</span>
  </div>
       
      </div>
    </div>
  )
}

export default ShimmerUiProjectSlider
