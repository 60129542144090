import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../Firebase/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  CategoryList,
  EcosystemsList,
  FundingStageList,
  LocationList,
  PartnershipInterestList,
  RequestTypeList,
} from "./Filterlists";
import close from "../../Images/close.png";
import { Select, MenuItem,  FormControl, ListItemText, Checkbox, InputLabel, Box } from '@mui/material';
 
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from "./Dropdown";
import { CatchingPokemon } from "@mui/icons-material";
const EditProfile = ({ setIsEditProfile }) => {
  const [userProject, setUserProject] = useState([]);
  const [userDetail, setUserDetail] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
 
  // Form States
  const [projectName, setProjectName] = useState(userProject.name);
  const [website, setWebsite] = useState(userProject.website);
  const [category, setCategory] = useState(
    userProject?.category?.length > 0 ? userProject.category[0] : []
  );
  const [location, setLocation] = useState(userProject.country);
  const [fundingStage, setFundingStage] = useState(
    userProject?.fundingStatus?.length > 0 ? userProject.fundingStatus[0] : []
  );
  const [partnership, setPartnership] = useState(
    userProject?.partnershipInterest?.length > 0
      ? userProject.partnershipInterest[0]
      : []
  );
  const [ecosystem, setEcosystem] = useState(
    userProject?.blockchain?.length > 0 ? userProject.blockchain[0] : []
  );
  const [bioData, setBioData] = useState(userProject.biodata);
  const [requestType, setRequestType] = useState(
    userProject?.requestType?.length > 0 ? userProject.requestType[0] : []
  );
  const [whitepaper, setWhitepaper] = useState(userProject.whitepaper);
  const [githubLink, setGithubLink] = useState(userProject.githubLink || "");
  const [twitterLink,setTwitterLink]=useState(userProject.twitterLink || "");
  const [redditLink,setRedditLink]=useState(userProject.redditLink || "");
  const [mediumLink,setMediumLink]=useState(userProject.mediumLink || "");
  const [telegramLink,setTelegramLink]=useState(userProject.telegramLink || "");
  const [oneLiner,setOneLiner]=useState(userProject.oneLiner || "");
  const [projectStatement, setProjectStatement] = useState(userProject.descr);
 

  const [coverPicture, setCoverPicture] = useState(userProject?.coverPicture || null);
const [profilePicture, setProfilePicture] = useState(userProject?.profilePicture || null);

 
  const [isProfileEditing, setIsProfileEditing] = useState(false);

  //For multiple inputs create an array
  const [selectedFundingStageArray, setSelectedFundingStageArray] = useState( userProject?.fundingStatus?.length > 0 ? userProject.fundingStatus[0] : []);
  const [selectedLocationArray, setSelectedLocationArray] = useState([userProject.country || ""]);
  const [selectedPartnershipInterestArray, setSelectedPartnershipInterestArray] = useState(userProject?.partnershipInterest?.length > 0
    ? userProject.partnershipInterest[0]
    : []);
  const [selectedEcosystemArray, setSelectedEcosystemArray] = useState(userProject?.blockchain?.length > 0 ? userProject.blockchain[0] : []);
  const [selectedCategoryArray, setSelectedCategoryArray] = useState(
    userProject?.category?.length > 0 ? userProject.category[0] : []
  );
  const [selectedRequestTypeArray, setSelectedRequestTypeArray] = useState(userProject?.requestType?.length > 0 ? userProject.requestType[0] : []);

 
  const storage = getStorage();
 
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });
    return () => unsubscribe();
  }, []);
 
  const fetchData = async () => {
    if (!currentUser) return;
 
    try {
      // Fetch User Data
      const userQuery = query(
        collection(db, "User"),
        where("id", "==", currentUser?.uid)
      );
      const userQuerySnapshot = await getDocs(userQuery);
      if (userQuerySnapshot.empty) {
        console.error("No user found");
        return;
      }
      const userDetail = userQuerySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))[0]; // Get the first document
      setUserDetail(userDetail);
 
      // Fetch User Projects
      const userProjectQuery = query(
        collection(db, "UserProject"),
        where("userId", "==", currentUser?.uid)
      );
      const userProjectQuerySnapshot = await getDocs(userProjectQuery);
      const userProjectd = userProjectQuerySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setUserProject(userProjectd[0]);
    } catch (error) {
      console.error(error);
    }
  };
 
  useEffect(() => {
    fetchData();
  }, [currentUser]);

 
  //update array
  const handleFundingStageChange = (event) => {

    const { value } = event.target;
    if (value.length >3 && value.length>selectedFundingStageArray.length){
      toast.warn("You Can Select Upto 3 Options")
      return

    }
      setSelectedFundingStageArray(value);
     

  
  };
  const handleLocationChange = (event) => {
    const { value } = event.target;
    setSelectedLocationArray(value);
  };
  const handlePartnershipInterstChange = (event) => {
    const { value } = event.target;
    setSelectedPartnershipInterestArray(value);
  };
  const handleEcosystemChange = (event) => {
    const { value } = event.target;
    setSelectedEcosystemArray(value);
  };
  const handleCategoryChange = (event) => {
    const { value } = event.target;
    setSelectedCategoryArray(value);
  };
  const handleRequestTypeChange = (event) => {
    const { value } = event.target;
    setSelectedRequestTypeArray(value);
  };


 
  // File change handlers
  const handleCoverPictureChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      // Check if file size is within the 10MB limit
      setCoverPicture(file);
    } else {
      toast.error("File size exceeds 10MB limit.");
    }
  };
 
  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 10 * 1024 * 1024) {
      // Check if file size is within the 10MB limit
      setProfilePicture(file);
    } else {
      toast.error("File size exceeds 10MB limit.");
    }
  };
 
  const uploadFile = async (file, folder) => {
    if (!file) return null;
 
    const fileRef = ref(storage, `${folder}/${currentUser?.uid}/${file.name}`);
    await uploadBytes(fileRef, file);
    const fileURL = await getDownloadURL(fileRef);
    return fileURL;
  };
  const flattenArray = arr => Array.isArray(arr) ? arr.flat() : arr;
 /*  const handleSave = async () => {
    if (bioData.length < 200) {
      toast.error("Please enter at least 200 characters in Description.", {
        position: "top-center",
      });
      return;
    }

    if (projectStatement.length < 200) {
      toast.error("Please enter at least 200 characters for the project statement.", {
        position: "top-center",
      });
      return;
    }

    if(oneLiner.length> 50){
      
      return;
    }
   
    // Check file sizes before proceeding
    if (
      (coverPicture && coverPicture.size > 10 * 1024 * 1024) ||
      (profilePicture && profilePicture.size > 10 * 1024 * 1024)
    ) {
      toast.error("One or more files exceed the 10MB size limit.");
      return;
    }
 
    try {
       

      // Upload files if present
      const coverPictureURL = coverPicture
        ? await uploadFile(coverPicture, "coverPictures")
        : null;
      const profilePictureURL = profilePicture
        ? await uploadFile(profilePicture, "profilePictures")
        : null;
 
      const docRef = doc(db, "UserProject", userProject.id);
       if( !profilePictureURL){
          toast.error("Add Profile Picture")
          return
       }
       if(!coverPicture){
        toast.error("Add Cover Picture")
          return
       }
 
      // Prepare the fields to update, allowing githubLink to be null or empty
      const updatedData = {
        name: projectName,
        website: website,
        category:flattenArray(selectedCategoryArray) || null,
        country: flattenArray(selectedLocationArray) || null,
        fundingStatus: flattenArray(selectedFundingStageArray) || null,
        partnershipInterest: flattenArray(selectedPartnershipInterestArray) || null,
        blockchain: flattenArray(selectedEcosystemArray) || null,
        whitepaper: whitepaper || null,
        descr: projectStatement || null,
        biodata: bioData || null,
        requestType:flattenArray(selectedRequestTypeArray) || null,
        coverPicture: coverPictureURL,
        profilePicture: profilePictureURL,
        githubLink: githubLink || null, // Explicitly set githubLink to null if it's empty
        twitterLink:twitterLink || null,
        redditLink:redditLink || null,
        mediumLink :mediumLink || null,
        telegramLink:telegramLink || null,
        oneLiner:oneLiner || null,
      };
 
      await updateDoc(docRef, updatedData);
      
 
      const docRefUser = doc(db, "User", currentUser?.uid);
 
      // Prepare the fields to update, allowing githubLink to be null or empty
      const updatedUserData = {
        profilePicture: profilePictureURL || userDetail.profilePicture,
      };
      await updateDoc(docRefUser, updatedUserData);
      await fetchData()
      toast.success("Profile updated successfully!" , {
        position: "top-center",
      });
      setIsEditProfile(false);
    } catch (error) {
      console.log(error)
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
      
    }
  }; */
 
  /* const handleEdit = () => {
    setProjectName(userProject?.name);
    setWebsite(userProject?.website);
    setSelectedCategoryArray(userProject?.category?.length > 0 ? userProject?.category : []);
    setSelectedFundingStageArray(userProject?.fundingStatus?.length > 0 ? userProject?.fundingStatus : []);
    setSelectedPartnershipInterestArray(userProject?.partnershipInterest?.length > 0 ? userProject?.partnershipInterest : []);
    setSelectedEcosystemArray(userProject?.blockchain?.length > 0 ? userProject?.blockchain : []);
    setSelectedRequestTypeArray(userProject?.requestType?.length > 0 ? userProject?.requestType : []);
    setWhitepaper(userProject?.whitepaper);
    setProjectStatement(userProject?.descr);
    setBioData(userProject?.biodata);
   setProfilePicture(userProject?.profilePicture)
    setCoverPicture(userProject?.coverPicture) 
    
    setSelectedLocationArray(userProject?.country?[userProject?.country] :[]);
    setGithubLink(userProject?.githubLink || "");
    setTwitterLink(userProject?.twitterLink || "");
    setRedditLink(userProject?.redditLink || "");
    setMediumLink(userProject?.mediumLink || "");
    setTelegramLink(userProject?.telegramLink || "");
    setOneLiner(userProject?.oneLiner || "");
    setIsProfileEditing(true);
  }; */
 

  const handleSave = async () => {
    if (bioData.length < 200) {
      toast.error("Please enter at least 200 characters in Description.", {
        position: "top-center",
      });
      return;
    }
  
    if (projectStatement.length < 200) {
      toast.error("Please enter at least 200 characters for the project statement.", {
        position: "top-center",
      });
      return;
    }
  
    if (oneLiner.length > 50) {
      toast.error("One-liner cannot exceed 50 characters.", {
        position: "top-center",
      });
      return;
    }
  
    if (
      (coverPicture && coverPicture.size > 10 * 1024 * 1024) ||
      (profilePicture && profilePicture.size > 10 * 1024 * 1024)
    ) {
      toast.error("One or more files exceed the 10MB size limit.");
      return;
    }
  
    try {
      // Upload files if present
      const coverPictureURL = coverPicture instanceof File 
      ? await uploadFile(coverPicture, "coverPictures") 
      : coverPicture; // Preserve existing URL if no new file
    const profilePictureURL = profilePicture instanceof File 
      ? await uploadFile(profilePicture, "profilePictures") 
      : profilePicture; // Preserve existing URL if no new file
  
      if (!profilePictureURL) {
        toast.error("Add Profile Picture");
        return;
      }
      if (!coverPictureURL) {
        toast.error("Add Cover Picture");
        return;
      }
  
      const docRef = doc(db, "UserProject", userProject.id);
  
      // Prepare the fields to update
      const updatedData = {
        name: projectName,
        website: website,
        category: flattenArray(selectedCategoryArray) || null,
        country: flattenArray(selectedLocationArray) || null,
        fundingStatus: flattenArray(selectedFundingStageArray) || null,
        partnershipInterest: flattenArray(selectedPartnershipInterestArray) || null,
        blockchain: flattenArray(selectedEcosystemArray) || null,
        whitepaper: whitepaper || null,
        descr: projectStatement || null,
        biodata: bioData || null,
        requestType: flattenArray(selectedRequestTypeArray) || null,
        coverPicture: coverPictureURL,
        profilePicture: profilePictureURL,
        githubLink: githubLink || null,
        twitterLink: twitterLink || null,
        redditLink: redditLink || null,
        mediumLink: mediumLink || null,
        telegramLink: telegramLink || null,
        oneLiner: oneLiner || null,
      };
  
      await updateDoc(docRef, updatedData);
  
      // Update the local userProject state
      const updatedProject = { ...userProject, ...updatedData };
      setUserProject(updatedProject);
  
      // Update User document
      const docRefUser = doc(db, "User", currentUser?.uid);
      const updatedUserData = {
        profilePicture: profilePictureURL || userDetail.profilePicture,
      };
      await updateDoc(docRefUser, updatedUserData);
  
      toast.success("Profile updated successfully!", {
        position: "top-center",
      });
  
      setIsEditProfile(false);
    } catch (error) {
      console.error(error);
      toast.error("All fields are mandatory", {
        position: "top-center",
      });
    }
  };
  
  const handleEdit = () => {
    setProjectName(userProject?.name);
    setWebsite(userProject?.website);
    setSelectedCategoryArray(userProject?.category?.length > 0 ? userProject?.category : []);
    setSelectedFundingStageArray(userProject?.fundingStatus?.length > 0 ? userProject?.fundingStatus : []);
    setSelectedPartnershipInterestArray(userProject?.partnershipInterest?.length > 0 ? userProject?.partnershipInterest : []);
    setSelectedEcosystemArray(userProject?.blockchain?.length > 0 ? userProject?.blockchain : []);
    setSelectedRequestTypeArray(userProject?.requestType?.length > 0 ? userProject?.requestType : []);
    setWhitepaper(userProject?.whitepaper);
    setProjectStatement(userProject?.descr);
    setBioData(userProject?.biodata);
    setProfilePicture(userProject?.profilePicture || profilePicture); 
    setCoverPicture(userProject?.coverPicture || coverPicture);
    setSelectedLocationArray(userProject?.country ? [userProject?.country] : []);
    setGithubLink(userProject?.githubLink || "");
    setTwitterLink(userProject?.twitterLink || "");
    setRedditLink(userProject?.redditLink || "");
    setMediumLink(userProject?.mediumLink || "");
    setTelegramLink(userProject?.telegramLink || "");
    setOneLiner(userProject?.oneLiner || "");
    setIsProfileEditing(true);
  };
  
  if (!isProfileEditing) {
    return (
      <div className="edit-profile">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 5px",
          }}
        >
          <h2>Edit Profile</h2>
          <div>
            <img src={close} alt="close" style={{ cursor: "pointer" }} onClick={() => setIsEditProfile(false)}/>{" "}
           
          </div>
        </div>
 
        <div className="form-row">
          <div className="form-group">
            <label>Project Name *</label>
            <input
              type="text"
              name="projectName"
              value={userProject.name}
              readOnly
              //onChange={(e) => setProjectName(e.target.value)}
              required
            />
          </div>
 
          <div className="form-group">
            <label>Website *</label>
            <input
              type="url"
              name="website"
              value={userProject.website}
              readOnly
              //onChange={(e) => setWebsite(e.target.value)}
              required
            />
          </div>
        </div>
 
        <div className="form-row">
          <div className="form-group">
            <label>Location</label>
            <input
              name="location"
              value={userProject.country}
              readOnly
              //onChange={(e) => setLocation(e.target.value)}
            >
             {/*  {LocationList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })} */}
            </input>
          </div>
 
          <div className="form-group">
            <label>Funding Stage</label>
            <input
              name="fundingStage"
              value={userProject?.fundingStatus?.length > 0 ? userProject.fundingStatus[0] : ""}
              readOnly
              //onChange={(e) => setFundingStage(e.target.value)}
            >
              {/* {FundingStageList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })} */}
            </input>
          </div>
        </div>
 
        <div className="form-row">
          <div className="form-group">
            <label>Partnership Interests</label>
            <input
              name="partnership"
              value={userProject?.partnershipInterest?.length > 0 ? userProject.partnershipInterest[0] : ""}
              readOnly
              //onChange={(e) => setPartnership(e.target.value)}
            >
              {/* {PartnershipInterestList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })} */}
            </input>
          </div>
 
          <div className="form-group">
            <label>Ecosystem</label>
            <input
              name="ecosystem"
              value={userProject?.blockchain?.length > 0 ? userProject.blockchain[0] : ""}
              readOnly
              //onChange={(e) => setEcosystem(e.target.value)}
            >
              {/* {EcosystemsList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })} */}
            </input>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Category</label>
            <input
              name="category"
              value={userProject?.category?.length > 0 ? userProject.category[0] : ""}
              readOnly
              //onChange={(e) => setCategory(e.target.value)}
            >
             {/*  {CategoryList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })} */}
            </input>
          </div>
 
          <div className="form-group">
            <label>Request Type</label>
            <input
              name="request type"
              value={userProject?.requestType?.length > 0 ? userProject.requestType[0] : ""}
              readOnly
              //onChange={(e) => setEcosystem(e.target.value)}
            >
              {/* {RequestTypeList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })} */}
            </input>
          </div>
        </div>
 
        <div className="form-group">
          <label>Description *</label>
          <textarea
            name="bioData"
            value={userProject.biodata}
            readOnly
            //onChange={(e) => setBioData(e.target.value)}
            required
          />
        </div>
 
        <div className="form-group">
          <label>Whitepaper</label>
          <input
            type="text"
            name="whitepaper"
            value={userProject.whitepaper}
            readOnly
            //onChange={(e) => setWhitepaper(e.target.value)}
          />
        </div>
 
        <div className="form-group">
          <label>Github Link*</label>
          <input
            type="url"
            name="githubLink"
            value={userProject.githubLink || ""}
            readOnly
            //onChange={(e) => setGithubLink(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Twitter Link*</label>
          <input
            type="url"
            name="twitterLink"
            value={userProject.twitterLink || ""}
            readOnly
            //onChange={(e) => setGithubLink(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Reddit Link*</label>
          <input
            type="url"
            name="redditLink"
            value={userProject.redditLink || ""}
            readOnly
            //onChange={(e) => setGithubLink(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Medium Link*</label>
          <input
            type="url"
            name="mediumLink"
            value={userProject.mediumLink || ""}
            readOnly
            //onChange={(e) => setGithubLink(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Telegram Link*</label>
          <input
            type="url"
            name="telegramLink"
            value={userProject.telegramLink || ""}
            readOnly
            //onChange={(e) => setGithubLink(e.target.value)}
          />
        </div>

 
        <div className="form-group">
          <label>Statement for projects *</label>
          <textarea
            name="projectStatement"
            value={userProject.descr}
            //onChange={(e) => setProjectStatement(e.target.value)}
            required
            readOnly
          />
        </div>
        <div className="form-group">
          <label>One Liner *</label>
          <textarea
            name="projectStatement"
            value={userProject.oneLiner}
            
            required
            readOnly
          />
        </div>
 
        <div className="form-group file-input">
          <label>Cover picture<span style={{fontStyle:"10px"}}>(Dimensions 1064X191 Supported formats: PNG, JPG upto 10mb ) </span></label>
          <input
            type="file"
            name="coverPicture"
            placeholder="JPG, PNG or PDF, file size no more than 10MB"
            disabled
          />
        </div>
 
        <div className="form-group file-input">
          <label>Profile picture<span style={{fontStyle:"10px"}}>(Dimensions 108X108 Supported formats: PNG, JPG upto 10mb) </span></label>
          
          <input
            type="file"
            name="profilePicture"
            placeholder="JPG, PNG or PDF, file size no more than 10MB"
            disabled
          />
        </div>
 
        <div className="form-actions">
          <button
            type="button"
            className="cancel-btn"
            onClick={() => setIsEditProfile(false)}
          >
            Cancel
          </button>
          <button onClick={handleEdit} className="save-btn">
            Edit
          </button>
        </div>
        {/* <ToastContainer Style={{
          position:"absolute",
          margin:"auto",
          alignContent:"center",

           // Force position closer to the top
          // transform: "translateY(-50%)", // Adjust to vertically center at the very top
        }}/> */}
      </div>
    );
  } else {
    return (<>
      <div className="edit-profile">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 5px",
          }}
        >
          <h2>Edit Profile</h2>
          <div>
            <img src={close} alt="close" style={{ cursor: "pointer" }} onClick={() => setIsEditProfile(false)} />{" "}
           
          </div>
        </div>
 
        <div className="form-row">
          <div className="form-group">
            <label>Project Name *</label>
            <input
              type="text"
              name="projectName"
              value={userProject.name}
              onClick={()=>{
                
                  toast.error("Need to update your Project Name or Website? Connect with our team on Telegram.", {
                    position: "top-center", // Position the toast at the center top
                    autoClose: 3000, // Automatically close after 3 seconds
                    // hideProgressBar: true, // Hide the progress bar
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                   
                    
                    
                  })
                

              }}
              //onChange={(e) => setProjectName(e.target.value)}
              readOnly
            />
          </div>
 
          <div className="form-group">
            <label>Website *</label>
            <input
              type="url"
              name="website"
              value={userProject.website}
              onClick={()=>{
                toast("Need to update your Project Name or Website? Connect with our team on Telegram.", {
                  position: "top-center", // Position the toast at the center top
                  autoClose: 3000, // Automatically close after 3 seconds
                  // hideProgressBar: true, // Hide the progress bar
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                })}
              }
              //onChange={(e) => setWebsite(e.target.value)}
              readOnly
            />
          </div>
        </div>
 
        <div className="form-row">
          {/* <div className="form-group">
            <label>Location</label>
            <select
              name="location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
            >
              {LocationList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })}
            </select>
          </div> */}
          <div className="form-group">
  <label>Location</label>
  <FormControl fullWidth>
    <Select
      name="location"
      value={selectedLocationArray || ""}  // Bind to the first item of the array or an empty string
      onChange={handleLocationChange}  // Handle changes
      required
      renderValue={(selected) => (
        <Box sx={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {selected && selected} 
        </Box>
      )}
      displayEmpty
    >
      {LocationList?.map((ele) => (
        <MenuItem key={ele} value={ele}>
          <Checkbox checked={selectedLocationArray[0] === ele} />  {/* Check if the selected location matches the current item */}
          <ListItemText primary={ele} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
</div>



 
         {/*  <div className="form-group">
  
  <label>Funding Stage</label>
   <select
    name="fundingStage"
    value={fundingStage}
    onChange={(e) => setFundingStage(e.target.value)}
  
  >
    {FundingStageList.map((ele, i) => {
      return <option value={ele}>{ele}</option>;
    })}
  </select>  

  

        </div> */}
        
          
          {/* <Select  className="form-group">
          </Select>
          
          {FundingStageList.map((ele, i) => (
             <MenuItem>
             <ListItemText primary={ele}></ListItemText>
             </MenuItem>
    ))} */}
    <div className="form-group">
          <label>Funding Stage</label>
          <FormControl fullWidth>
      <Select
        name="fundingstage"
        multiple
        value={selectedFundingStageArray}  // Bind the selected values to the state
        onChange={handleFundingStageChange}  // Handle changes
        required
        renderValue={(selected) => (
          <Box sx={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
             {selected.join(', ')}
          </Box>
        )}
        displayEmpty
      >
        {FundingStageList?.map((ele) => (
          <MenuItem key={ele} value={ele}>
            <Checkbox checked={selectedFundingStageArray.indexOf(ele) > -1} />
            <ListItemText primary={ele} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        </div>
      
     
      
        </div>
        {console.log(selectedFundingStageArray)}
 
        <div className="form-row">
          {/* <div className="form-group">
            <label>Partnership Interests</label>
            <select
              name="partnership"
              value={partnership}
              onChange={(e) => setPartnership(e.target.value)}
            >
              {PartnershipInterestList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })}
            </select>
          </div> */}

<div className="form-group">
          <label>Partnership Interests</label>
          <FormControl fullWidth>
      <Select
        name="partnership interest"
        multiple
        value={selectedPartnershipInterestArray}  // Bind the selected values to the state
        onChange={handlePartnershipInterstChange}  // Handle changes
        required
        renderValue={(selected) => (
          <Box sx={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
             {selected.join(', ')}
          </Box>
        )}
        displayEmpty
      >
        {PartnershipInterestList?.map((ele) => (
          <MenuItem key={ele} value={ele}>
            <Checkbox checked={selectedPartnershipInterestArray.indexOf(ele) > -1} />
            <ListItemText primary={ele} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        </div>
 
          {/* <div className="form-group">
            <label>Ecosystem</label>
            <select 
              name="ecosystem"
              value={ecosystem}
              onChange={(e) => setEcosystem(e.target.value)}
            >
              {EcosystemsList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })}
            </select>
          </div> */}

<div className="form-group">
          <label>Ecosystem</label>
          <FormControl fullWidth>
      <Select
        name="ecosystem"
        multiple
        value={selectedEcosystemArray}  // Bind the selected values to the state
        onChange={handleEcosystemChange}  // Handle changes
        required
        renderValue={(selected) => (
          <Box sx={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {selected.join(', ')}
          </Box>
        )}
        displayEmpty
      >
        {EcosystemsList?.map((ele) => (
          <MenuItem key={ele} value={ele}>
            <Checkbox checked={selectedEcosystemArray.indexOf(ele) > -1} />
            <ListItemText primary={ele} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        </div>
        </div>

        <div className="form-row">
          {/* <div className="form-group">
            <label>Category</label>
            <select
              name="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              {PartnershipInterestList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })}
            </select>
          </div> */}
          <div className="form-group">
          <label>Category</label>
          <FormControl fullWidth>
      <Select
        name="category"
        multiple
        value={selectedCategoryArray}  // Bind the selected values to the state
        onChange={handleCategoryChange}  // Handle changes
        required
        renderValue={(selected) => (
          <Box sx={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
             {selected.join(', ')}
          </Box>
        )}
        displayEmpty
      >
        {CategoryList?.map((ele) => (
          <MenuItem key={ele} value={ele}>
            <Checkbox checked={selectedCategoryArray.indexOf(ele) > -1} />
            <ListItemText primary={ele} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        </div>
 
          {/* <div className="form-group">
            <label>Request Type</label>
            <select
              name="Request Type"
              value={requestType}
              onChange={(e) => setRequestType(e.target.value)}
            >
              {RequestTypeList.map((ele, i) => {
                return <option value={ele}>{ele}</option>;
              })}
            </select>
          </div> */}

<div className="form-group">
          <label>Request Types</label>
          <FormControl fullWidth>
      <Select
        name="request type"
        multiple
        value={selectedRequestTypeArray}  // Bind the selected values to the state
        onChange={handleRequestTypeChange}  // Handle changes
        required
        renderValue={(selected) => (
          <Box sx={{ maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {selected.join(', ')} 
          </Box>
        )}
        displayEmpty
      >
        {RequestTypeList?.map((ele) => (
          <MenuItem key={ele} value={ele}>
            <Checkbox checked={selectedRequestTypeArray.indexOf(ele) > -1} />
            <ListItemText primary={ele} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
        </div>
        </div>
 
        <div className="form-group">
          <label>Description *</label>
          <textarea style={{
          borderColor: bioData.length < 200   && "rgba(255, 57, 50, 1)", // Red border if invalid
        }}
            name="bioData"
            value={bioData}
            maxLength={520}
            onChange={(e) => {
              const input = e.target.value;
              setBioData(input); // Update the state
            }}
            required
          />
          {bioData.length < 200 && (
  <p style={{ color: "red"  , fontSize:"12px"}}>
    Minimum 200 characters required. Current count: {bioData.length}
  </p>
)}
        </div>
 
        <div className="form-group">
          <label>Whitepaper*</label>
          <input
            type="text"
            name="whitepaper"
            value={whitepaper}
            onChange={(e) => setWhitepaper(e.target.value)}
            required
          />
        </div>
 
        <div className="form-group">
          <label>Github Link*</label>
          <input
            type="url"
            name="githubLink"
            value={githubLink}
            onChange={(e) => setGithubLink(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Twitter Link*</label>
          <input
            type="url"
            name="twitterLink"
            value={twitterLink}
            onChange={(e) => setTwitterLink(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Reddit Link*</label>
          <input
            type="url"
            name="redditLink"
            value={redditLink}
            onChange={(e) => setRedditLink(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Medium Link*</label>
          <input
            type="url"
            name="mediumLink"
            value={mediumLink}
            onChange={(e) => setMediumLink(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Telegram Link*</label>
          <input
            type="url"
            name="telegramLink"
            value={telegramLink}
            onChange={(e) => setTelegramLink(e.target.value)}
            required
          />
        </div>
 
        <div className="form-group">
          <label>Statement for projects *</label>
          <textarea style={{
          borderColor: projectStatement?.length > 0 && projectStatement?.length < 200   && "rgba(255, 57, 50, 1)", // Red border if invalid
        }}
            name="projectStatement"
            value={projectStatement}
            maxLength={720}
            onChange={(e) => {
              const input = e.target.value;
              setProjectStatement(input); // Update the state with the input
            }}
            required
          />
          {projectStatement?.length > 0 && projectStatement?.length < 200 && (
  <p style={{ color: "red", fontSize:"12px" }}>
    Minimum 200 characters required. Current count: {projectStatement?.length}
  </p>
)}
        </div>
        <div className="form-group">
          <label>One Liner *</label>
          <textarea style={{
          borderColor: oneLiner.length > 50  && "rgba(255, 57, 50, 1)", // Red border if invalid
        }}
            name="one liner description"
            maxLength={51}
            value={oneLiner}
            onChange={(e) => setOneLiner(e.target.value)}
            required
          />
          {oneLiner.length > 50 && (
  <p style={{ color: "red"  , fontSize:"12px"}}>
    Maximun  50 characters . Current count: {oneLiner.length}
  </p>
)}
        </div>
 
        <div className="form-group file-input">
          <label>Cover picture<span style={{fontStyle:"10px"}}>(Dimensions 1064X191   Supported formats: PNG, JPG upto 10mb) </span></label>
          <input  style={{
          borderColor: !coverPicture && "rgba(255, 57, 50, 1)", // Red border if invalid
        }}
            type="file"
            name="coverPicture"
            placeholder="JPG, PNG or PDF, file size no more than 10MB"
            accept=".jpg,.png,.pdf"
            onChange={handleCoverPictureChange}
          />
         

        </div>
 
        <div className="form-group file-input">
          <label>Profile picture<span style={{fontStyle:"10px"}}>(Dimensions 108X108  Supported formats: PNG, JPG upto 10mb ) </span></label>
          <input style={{
          borderColor: !profilePicture && "rgba(255, 57, 50, 1)", // Red border if invalid
        }}
            type="file"
            name="profilePicture"
            placeholder="JPG, PNG or PDF, file size no more than 10MB"
            accept=".jpg,.png,.pdf"
            onChange={handleProfilePictureChange}
          />
        </div>
 
        <div className="form-actions">
          <button
            type="button"
            className="cancel-btn"
            onClick={() => setIsEditProfile(false)}
          >
            Cancel
          </button>
          <button onClick={handleSave} className="save-btn">
            Save
          </button>
        </div>
 
         
      </div>
      <ToastContainer toastStyle={{
        position:"absolute",
        margin:"auto",
        alignContent:"center",
        top:"50px",
        /* top: "10px",  */// Force position closer to the top
        /* transform: "translateY(-50%)", */ // Adjust to vertically center at the very top
      }}/> 
      </>
    );
  }
};
 
export default EditProfile;