import React from "react";

import './Contactsale.css';
const ConatctSale =()=>{

    return (

        <>
        <div className="contactsale-container">
        <div className='contactsale-subcontainer'>
        <div  className="contactsale-text" >
            <div className="contactsale-text1">Need More Send Requests ?</div>
            <div  className="contactsale-text2">Explore our <span>Plus</span> plan!</div>
        </div>

        <div className="contactsale-btn">
            <button className='sales-button' onClick={()=>window.open("https://t.me/salesraisa","_blank")}>Contact Sales</button>
        </div>
        </div>
        </div>
        </>
    )

}

export default ConatctSale;