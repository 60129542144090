import React, { useEffect, useState } from "react";
import {
  SignupContainer,
  Title,
  Subtitle,
  Form,
  InputGroup,
  Input,
  CheckboxWrapper,
  CheckboxLabel,
  Button,
  LoginLink,
  Logo1,
  Heading1,
  Dash1,
  CloseButton2,
  OutsideContainer,
  Input1,
  InputWrapper,
  Checkbox2,
  CheckboxLabel1,
  LoginLink1,
  Star1,
  Help,
  InputSelect,
  CloseButtonX,
  ImageHash1,
  IconWrapper,
  InputWrapperResponsive,
  Mobilenavm,
  Mobilenav,
  Signupback,
} from "./Signup.style"; // This imports the styled components
import aquirelab from "../Images/Aquirelabs.png";
import aqdash from "../Images/Aqdash.png";
import aqtext from "../Images/Aquire Labs.png";
import aqhash from "../Images/Hash.png";
import helpbutton from "../Images/Help.png";
import closebutton from "../Images/Closebutton.png";
import {
  Checkbox,
  CloseButton,
  ImageHash,
  Label,
  Logo,
  LogoContainer,
} from "./Login.style";
import {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { auth, db } from "./Firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate , useLocation } from "react-router-dom";
import { addDoc, collection, doc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import RaisaLogo from "../Images/RaisaN.svg";
import { CategoryList } from "./Dashboard/Filterlists";
import HelpSignup from "./HelpSignup";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import './Signup.css'
import menu from '../Images/hamburger.svg'
const Signup = () => {
  document.body.style.background = "rgba(242, 246, 255, 1)";
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [compWeb, setCompWeb] = useState("");
  const [password, setPassword] = useState("");
  const [reenterpassword, setReenterpassword] = useState("");
  const [category, setCategory] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [projDesc, setProjDesc] = useState("");
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showReenterPassword, setShowReenterPassword] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [passErrorMsg,setPassErrorMsg]=useState(null)
  const [showPassErrorMsg,setShowPassErrorMsg]=useState(null)
  const [isEqual,setIsEqual]=useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
 
  const [user, setUser] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [buttonActive,setButtonActive]=useState(null)
  const handleButtonClick = (button) => {
    setButtonActive(button);
    localStorage.setItem('buttonActive', button); 
  };
  const location = useLocation();
  useEffect(() => {
    
    const savedActiveButton = localStorage.getItem('buttonActive');
    if (savedActiveButton) {
      setButtonActive(savedActiveButton);
    }

   
    const currentPath = location.pathname.replace('/', ''); 
    setButtonActive(currentPath);
  }, [location.pathname]);

 
  const navigate = useNavigate();
  const actionCodeSettings = {
    // url: `${window.location.origin}/login`, // Your domain link
    url: `${window.location.origin}/dashboard`,
    handleCodeInApp: true, // To open it in your app
  };
 
  // Function to toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 
  const toggleReenterPasswordVisibility = () => {
    setShowReenterPassword(!showReenterPassword);
  };
 
  const handleHelpModal = () => {
    setIsHelpModalOpen(true);
  };
 
  const handleCategoryChange = (e) => {
    const value = e.target.value;
    if (value && !category.includes(value)) {
      setCategory([...category, value]); // Add selected category to the array
     
    }
  };
  const handlePasswordChange = (e) => {
    // Regex for password validation
    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/;
    const value = e.target.value;
    setPassword(value);
 
    // Check if password matches the regex
    if (passwordRegex.test(value)) {
      setIsValid(true); // Password is valid
      setPassErrorMsg(null)
     
    } else {
      setIsValid(false); // Password is invalid
      setPassErrorMsg("Requires at least 8 characters, including an uppercase letter, a lowercase letter, and a number.")
     
    }
  };
 
  // Function to check if email already exists
  const checkEmailExists = async (email) => {
    try {
      const userRef = collection(db, "User");
      const emailQuery = query(userRef, where("email", "==", email));
      const querySnapshot = await getDocs(emailQuery);
 
      // Check if any documents match the query
      if (!querySnapshot.empty) {
        setEmailError("This email is already in use.");
      } else {
        setEmailError(null); // Reset if email is not found
      }
    } catch (error) {
      console.error("Error checking email:", error);
    }
  };
   // Handle email input change and trigger the email existence check
   const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
 
    // Check if the email already exists
    if (newEmail) {
      checkEmailExists(newEmail);
    }
  }
 
  const handleReenterPasswordChange = (e) => {
   
    const value = e.target.value;
    setReenterpassword(value)
 
    // Check if password matches the regex
    if (value !== password) {
      setIsEqual(false);
      setShowPassErrorMsg("Re-entered password does not match the password.")
     
     
    } else {
      setIsEqual(true);
      setShowPassErrorMsg(null)
     
    }
  };
 

  const [error, setError] = useState("");
  const [isDiscription ,  setIsDiscription]= useState(true)

  const handleDiscriptionChange = (e) => {
    const value = e.target.value;
    setProjDesc(value);

    // Check for character length
    if (value.length < 200) {
      setIsDiscription(false)
     
      setError("Project description must be at least 200 characters.");
    } else {
      setIsDiscription(true)
      setError(""); // Clear error if the condition is met
    }
  };



  const handleFormSubmit = async (e) => {
   
 
    //Regex for email validation
    const emailRegex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/;
 
    e.preventDefault();
    if (
      !name ||
      !email ||
      !compWeb ||
      !password ||
      !reenterpassword ||
      !category ||
      !isChecked || !projDesc
    ) {
      toast.error("All fields are required!", { position: "top-center" });
      return;
    }
    if (!emailRegex.test(email)) {
      toast.error("Invalid Email Format!", { position: "top-center" });
      return;
    }
   /*  if (!passwordRegex.test(password)) {
      toast.error("Password must be at least 6 characters long, contain at least one uppercase letter, one lowercase letter, and one number", { position: "top-center" });
      return;
    } */
   if(passErrorMsg){
    toast.error("Invalid Password Format!", { position: "top-center" });
    return;
   }
 
    if (showPassErrorMsg) {
      toast.error("Matching Password Failed!", {
        position: "top-center",
      });
      return;
    }
 
    if(emailError){
      toast.error("Email already in use!", {
        position: "top-center",
      });
      return;
    }
    try {
      await createUserWithEmailAndPassword(auth, email, password).then(
        async (userCred) => {
          const user = userCred.user;
          setUser(user);
 
          console.log(userCred);
 
          await sendEmailVerification(user, actionCodeSettings);
          // Add user details to Firestore after sending verification email
          const docRef = doc(db, "User", user.uid);
 
          await setDoc(docRef, {
            email: user.email,
            firstname: "", // Update with actual data
            lastname: "", // Update with actual data
            mobile: "", // Update with actual data
            role: "admin",
            id: user.uid,
            verified: false, // Set verified to false by default
            createdAt: new Date(), // Store the current timestamp
            tgVerified: false,
          });
 
          await addDoc(collection(db, "UserProject"), {
            blockchain: [],
            category: category,
            city: "",
            country: "",
            createdAt: new Date(), // current timestamp
            biodata: projDesc,
            endorsements: 1,
            fundingStatus: [],
            logo: "",
            modifiedAt: new Date(),
            name: name,
            social: {
              facebook: "",
              insta: "", // Instagram URL
              linkedin: "", // LinkedIn URL
              tg: "", // Telegram URL
              twitter: "", // Twitter URL
            },
            status: "", // status of the project (empty string for now)
            userId: user.uid, // store the user ID
            views: 1, // number of views (1 by default)
            website: compWeb, // website URL (empty string for now)
            whitepaper: "", // whitepaper URL (empty string for now)
          });
 
          toast.success(
            "Verify the Link to the Given Email for the Successful registeration!!!",
            { position: "top-center" }
          );
 
          setCategory("");
          setCompWeb("");
          setEmail("");
          setPassword("");
          setProjDesc("");
          setIsChecked(null);
          setReenterpassword("");
          setName("");
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
 


  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Check if the email is verified
      await user.reload(); // Reload user to get the latest email verification status
     /*  if (user.emailVerified) {
        // If email is verified, update Firestore
        const userDocRef = doc(db, "User", user.uid);
        await updateDoc(userDocRef, {
          verified: true,
        });
      } */
    }
  });
 
 
 
  return (
    <>
      <Signupback>
        <Mobilenav>
      <Mobilenavm>
          
            <div class="logo-m">
              <img src={RaisaLogo} alt="raisa-logo" />
            </div>
            <div className="menu-icon" onClick={() => setShowModal(!showModal)}>
        <img src={menu} alt="menu" />
      </div>
      {showModal && (
        <div className="menumodal-content-l">
         <div className='menu-cntr'>
          <div  className="menu-options" ><Link to="/" style={{ color: buttonActive===null ? 'rgba(26, 13, 171, 1)' : 'black' ,textDecorationLine: 'none'}}>Home</Link></div>
        <div  className="menu-options"><Link to="/about" onClick={()=>handleButtonClick("about")} style={{ color: buttonActive === "about" ? 'rgba(26, 13, 171, 1)' : 'black' ,textDecorationLine: 'none'}}>About</Link></div>
        <div   className="menu-options"><Link to="/faq" onClick={()=>handleButtonClick("faq")} style={{ color: buttonActive === "faq" ? 'rgba(26, 13, 171, 1)' : 'black' ,textDecorationLine: 'none'}}>Help</Link></div>
        </div>
        </div>
      )}
     
          
        </Mobilenavm>
        </Mobilenav>
        <SignupContainer>
          <LogoContainer>
            <Logo src={RaisaLogo} alt="Aquire Labs" />
 
            <Link to="/">
              <CloseButtonX>
                <img src={closebutton} alt="Close button" />
              </CloseButtonX>
            </Link>
          </LogoContainer>
          <OutsideContainer>
            <Title style={{ marginTop: "30px" }}>Signup</Title>
            <Subtitle>Signup and get started with Raisa Network</Subtitle>
          </OutsideContainer>
           
          <Form onSubmit={handleFormSubmit}>
            <InputGroup>
              <InputWrapper>
                <Label>
                  Project Name<a style={{ color: "red" }}>*</a>
                </Label>
                <Input
                  value={name}
                  type="text"
                  placeholder="Project Name"
                  label="Project Name*"
                  maxLength={720}
                  onChange={(e) => setName(e.target.value)}
                  style={{ marginBottom: emailError && "30px"  }}
 
                />
              </InputWrapper>
              <InputWrapper>
                <Label>
                  Email<a style={{ color: "red" }}>*</a>
                </Label>
                <Input
                  value={email}
                  type="email"
                  placeholder="work email (Name@company.com)"
                  label="Email*"
                  /* onChange={(e) => setEmail(e.target.value)} */
                  onChange={handleEmailChange}
                  style={{
                    borderColor: emailError && "rgba(255, 57, 50, 1)",
                  }}
                />
                {emailError && (<p  style={{marginTop:"0px",color:"rgba(255, 57, 50, 1)",fontSize:"12px"}}>{emailError}</p>)}
              </InputWrapper>
            </InputGroup>
            <InputGroup>
              <InputWrapper>
                <Label>
                  Company Website<a style={{ color: "red" }}>*</a>
                </Label>
                {/*  <Input
                value={compWeb}
                type="url"
                placeholder="Company.com"
                label="Company Website*"
                onChange={(e) => setCompWeb(e.target.value)}
              /> */}
 
                <Input
                  value={compWeb}
                  type="url"
                  placeholder="Company.com"
                  label="Company Website*"
                  onChange={(e) => {
                    let inputValue = e.target.value;
 
                    // Check if input starts with "www" but doesn't have "https://" or "http://"
                    if (
                      inputValue.startsWith("www.") &&
                      !inputValue.startsWith("http://") &&
                      !inputValue.startsWith("https://")
                    ) {
                      inputValue = "https://" + inputValue; // Prepend 'https://'
                    }
 
                    // Update the state
                    setCompWeb(inputValue);
                  }}
                />
              </InputWrapper>
              <InputWrapper>
                <Label>
                  Category<a style={{ color: "red" }}>*</a>
                </Label>
               
                {/* <InputSelect
                  onChange={(e) => setCategory(e.target.value)}
                  defaultValue=""
                >
                  <option value="">Select a Category</option>
                  {CategoryList.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </InputSelect> */}
 
<InputSelect onChange={handleCategoryChange} defaultValue="">
  <option value="">Select a Category</option>
  {CategoryList.map((category, index) => (
    <option key={category} value={category}>
      {category}
    </option>
  ))}
</InputSelect>
              </InputWrapper>
            </InputGroup>
            <InputGroup>
              <InputWrapper>
                <Label>
                  Password <a style={{ color: "red" }}>*</a>
                </Label>
                <InputWrapperResponsive>
                  {/* <Input
                    value={password}
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    onChange={(e) => setPassword(e.target.value)}
                  /> */}
 
<Input
        value={password}
        type={showPassword ? "text" : "password"}
        placeholder="Enter your password"
        onChange={handlePasswordChange}
        style={{
          borderColor: !isValid && "rgba(255, 57, 50, 1)", // Red border if invalid
        }}
      />
                  <IconWrapper onClick={togglePasswordVisibility}>
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </IconWrapper>
                </InputWrapperResponsive>
                {passErrorMsg && (<p  style={{marginTop:"0px",color:"rgba(255, 57, 50, 1)",fontSize:"12px"}}>{passErrorMsg}</p>)}
              </InputWrapper>
 
              <InputWrapper>
                <Label>
                  Re-Enter Password <a style={{ color: "red" }}>*</a>
                </Label>
                <InputWrapperResponsive>
                  <Input
                    value={reenterpassword}
                    type={showReenterPassword ? "text" : "password"}
                    placeholder="Re-Enter your password"
                    onChange={handleReenterPasswordChange}
                    style={{
                      borderColor: !isEqual && "rgba(255, 57, 50, 1)", // Red border if not equal
                    }}
                  />
                  <IconWrapper onClick={toggleReenterPasswordVisibility}>
                    {showReenterPassword ? <FaEyeSlash /> : <FaEye />}
                  </IconWrapper>
                </InputWrapperResponsive>
                {showPassErrorMsg && (<p  style={{marginTop:"0px",color:"rgba(255, 57, 50, 1)",fontSize:"12px"}}>{showPassErrorMsg}</p>)}
              </InputWrapper>
            </InputGroup>
 
            <InputWrapper>
              <Label>Description <a style={{ color: "red" }}>*</a></Label>
              <Input1
                label="Project Description"
                onChange={ handleDiscriptionChange }
                value={projDesc}
                style={{
                      borderColor:  !isDiscription && "rgba(255, 57, 50, 1) ",
                      outline: !isDiscription && "none"
                    }}
              />
              {error && <p style={{marginTop:"0px",color:"rgba(255, 57, 50, 1)",fontSize:"12px"}}>{error}</p>}
            </InputWrapper>
 
            <CheckboxWrapper>
              <Checkbox2
                type="checkbox"
                checked={isChecked}
                onClick={() => setIsChecked(true)}
              />
              <InputWrapper>
                <CheckboxLabel>
                  By proceeding you agree to the Terms of Service and Privacy
                  Policy.
                </CheckboxLabel>
                <CheckboxLabel1 onClick={()=> window.open("/faq","blank")}>Do you have any questions?</CheckboxLabel1>
              </InputWrapper>
            </CheckboxWrapper>
 
            <Button type="submit">Create Account</Button>
 
            <LoginLink>
              I already have an account{" "}
              
              <span className="signup-span" onClick={()=> navigate("/login")}>Login</span>
              
            </LoginLink>
            
          </Form>
          
          <Help src={helpbutton} onClick={handleHelpModal} />
          
          <ImageHash1 src={aqhash} alt="Logo-Hash" />
        </SignupContainer>
       
        {/* <Help src={helpbutton} onClick={handleHelpModal} /> */}
        {isHelpModalOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 50,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <HelpSignup setIsHelpModalOpen={setIsHelpModalOpen} />
          </div>
        )}
       
        {/* <ImageHash1 src={aqhash} alt="Logo-Hash" /> */}
      </Signupback>
      <ToastContainer />
      
        

    </>
  );
};
export default Signup;
 