/* import React from 'react';
import './Navbar.css';
import RaisaLogo from "../../Images/RaisaLogo.png"
import {Link} from "react-router-dom"
const Navbar = () => {
  return (
    <header className="navbar">
      <div className="logo"><img src={RaisaLogo} alt='raisa-logo'/></div>
      <nav>
        <ul>
          <img src=''/>
          <Link to="/dashboard"><li><a>Discover</a></li> </Link>
          <li><a href="#">Inbox</a></li>
          <li><a href="#">Requests</a></li>
          <li><a href="#">Saved</a></li>
          <li><a href="#">Notifications</a></li>
        </ul>
      </nav>
      <button className="logout-btn">Logout</button>
    </header>
 
  );
 
};
 
export default Navbar;
 */
import React, { useEffect, useState } from 'react';
import './Navbar.css';
import RaisaLogo from "../../Images/RaisaN.svg"
import dis from '../../Images/discoverblack.svg'
import inb from '../../Images/inboxnew.svg'
import req from '../../Images/requestnew.svg'
import sav from '../../Images/savenew.svg'
import noti from '../../Images/notificationnew.svg'
import disb from '../../Images/discoverblue.svg'
import inbb from '../../Images/inboxblue.svg'
import reqb from '../../Images/requestblue.svg'
import savb from '../../Images/saveblue.svg'
import notib from '../../Images/notifiyblue.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
 
const Navbar = () => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const [buttonActive,setButtonActive]=useState(null)
  const location = useLocation();

  

  const handleButtonClick = (button) => {
    localStorage.removeItem('sentrequest')
    setButtonActive(button);
    localStorage.setItem('buttonActive', button); 
    
  };


  useEffect(() => {
    
    const savedActiveButton = localStorage.getItem('buttonActive');
    if (savedActiveButton) {
      setButtonActive(savedActiveButton);
    }

   
    const currentPath = location.pathname.replace('/', ''); 
    setButtonActive(currentPath);
  }, [location.pathname]);

  // Handle mouse enter and leave for hover state
  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };


  const handleMouseLeave = () => {
    setHoveredItem(null);
  };
  const navigate = useNavigate(); // To programmatically navigate after logout
 
  const handleLogout = () => {
    
    const auth = getAuth(); // Get the current authentication instance
    signOut(auth)
      .then(() => {
        // Sign-out successful, redirect user to login page
        navigate('/login'); // Redirecting to login page after logout
      })
      .catch((error) => {
        // An error happened during logout
        console.error('Logout Error:', error);
      });
  };
  return (
   
    <nav className="container">
      <div className="headercontainer">
      <div className="frame1">
     
            <img  className="RaisaLogoimg"src={RaisaLogo} alt="logo" onClick={()=>navigate('/dashboard')} />
            <div className="headermain">
            {/* <ul className="header-ul">
              <li className="headerli">
               
                <img className="headerli-img" src={dis} alt="discover" /><Link to="/dashboard" style={{textDecoration:"none"}}> <span className="headerli-span">Discover</span></Link>
              </li>
              <li className="headerli">
               
                <img className="headerli-img"src={inb} alt="inbox" />
                <Link style={{textDecoration:"none"}}>
                
                <span className="headerli-span">Inbox</span>
                </Link>
              </li>
              <li className="headerli">
               
               <img className="headerli-img"src={req} alt="request" />
                <Link to="/requestpage" style={{textDecoration:"none"}}>
                  <span className="headerli-span">Request</span>
                </Link>
              </li>
              <li className="headerli">
               
                <img className="headerli-img" src={sav} alt="saved" />
                <Link style={{textDecoration:"none"}}>
                <span className="headerli-span">Saved</span>
                </Link>
              </li>
              <li className="headerli">
               
                <img className="headerli-img" src={noti} alt="notification" />
                <Link style={{textDecoration:"none"}}>
                <span className="headerli-span">Notifications</span>
                </Link>
              </li>                
            </ul> */}
             <ul className="header-ul">
      <li
        className="headerli dis"
        onMouseEnter={() => handleMouseEnter('dashboard')}
        onMouseLeave={handleMouseLeave}
      >
         <Link to="/dashboard" style={{ textDecoration: 'none',display:"flex",gap:'8px'}} >
        <img className="headerli-img " src={hoveredItem === 'dashboard' || buttonActive === 'dashboard' ? disb : dis} alt="discover" />
        
        
          <span onClick={() => handleButtonClick("dashboard")}
            className="headerli-span"
            style={{ color: hoveredItem === 'dashboard' || buttonActive==="dashboard" ? 'rgba(26, 13, 171, 1)' : 'black' }}
          >
            Discover
          </span>
          </Link>
        
      </li>
      <li
        className="headerli"
        onMouseEnter={() => handleMouseEnter('inbox')}
        onMouseLeave={handleMouseLeave}
      >
        <Link to="/inbox" style={{ textDecoration: 'none' ,display:"flex",gap:'8px'}} >
        <img className="headerli-img"  src={hoveredItem === 'inbox' || buttonActive === 'inbox' ? inbb : inb} alt="inbox"  style={{ color: hoveredItem === 'inbox' || buttonActive==="inbox" ? 'rgba(26, 13, 171, 1)' : 'black' }} / >
        
       
          <span onClick={() => handleButtonClick("inbox")}
            className="headerli-span"
            style={{ color: hoveredItem === 'inbox' || buttonActive==="inbox" ? 'rgba(26, 13, 171, 1)' : 'black' }}
          >
            Inbox
          </span>
        </Link>
      </li>
      

      <li
        className="headerli"
        onMouseEnter={() => handleMouseEnter('requestpage')}
        onMouseLeave={handleMouseLeave}
      >
         <Link to="/requestpage" style={{ textDecoration: 'none',display:"flex",gap:'8px' }} >
        <img className="headerli-img" src={hoveredItem === 'requestpage' || buttonActive === 'requestpage' ? reqb : req} alt="request" style={{ color: hoveredItem === 'inbox' || buttonActive==="request" ? 'rgba(26, 13, 171, 1)' : 'black' }}/>
       
          <span onClick={() => handleButtonClick("requestpage")}
            className="headerli-span"
            style={{ color: hoveredItem === 'requestpage' || buttonActive==="requestpage" ? 'rgba(26, 13, 171, 1)' : 'black' }}
          >
            Requests
          </span>
        </Link>
      </li>
      <li
        className="headerli"
        onMouseEnter={() => handleMouseEnter('saved')}
        onMouseLeave={handleMouseLeave}
      >
        <Link  style={{ textDecoration: 'none',display:"flex",gap:'8px' }} >
        <img  onClick={()=> alert(" Coming Soon")} className="headerli-img" src={sav} alt="saved" />
        
          <span title='coming soon'  onClick={()=> alert(" Coming Soon")}/* onClick={() => handleButtonClick('saved')} */
            className="headerli-span"
            style={{ color: hoveredItem === 'saved' /* || buttonActive === "saved" */ ? 'rgba(26, 13, 171, 1)' : 'black' }}
          >
            Saved
          </span>
        </Link>
      </li>
      <li
        className="headerli"
        onMouseEnter={() => handleMouseEnter('notifications')}
        onMouseLeave={handleMouseLeave}
      >
         <Link  style={{ textDecoration: 'none',display:"flex",gap:'8px' }} >
        <img  onClick={()=> alert(" Coming Soon")} className="headerli-img" src={noti} alt="notifications" />
       
          <span title='coming soon' onClick={()=> alert(" Coming Soon")} /* onClick={() => handleButtonClick('notifications')} */
            className="headerli-span"
            style={{ color: hoveredItem === 'notifications' /* || buttonActive==="notifications" */ ? 'rgba(26, 13, 171, 1)' : 'black' }}
          >
            Notifications
          </span>
        </Link>
      </li>
    </ul>
          </div>
 
      </div>
      <div className="frame2">
          <div className="header-logout">
            <button className="header-logout-btn" onClick={handleLogout}>Logout</button>
          </div>
        </div>
      </div>
 
    </nav>
  );
 
};
 
export default Navbar;