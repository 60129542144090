import React from "react";
import LinkedIn from "../../Images/LinkedIn.jpeg"
import Xicon from "../../Images/Xicon.jpeg"
import Discord from "../../Images/Discord.png"
import Telegram from "../../Images/Telegram-Company.png"
import { Link } from "react-router-dom";
import "./Footer.css"; // Import the CSS file for styling
 
const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-left">
          <h3 className="footer-company-name">Raisa Network</h3>
          <p className="footer-description">
            we simplify the process of finding and connecting with the perfect partners for your project
          </p>
        </div>
 
        <div className="footer-right">
          <div className="footer-section">
            <h4>Social</h4>
            <ul className="footer-links">
            <li className="s-li" onClick={() => window.open("https://www.linkedin.com/company/raisa-network/", "_blank")}>
  <img className="footer-icons" src={LinkedIn} alt="LinkedIn" />
  <a>LinkedIn</a>
</li>
<li className="s-li" onClick={() => window.open("https://t.me/Raisanetwork", "_blank")}>
  <img className="footer-icons" src={Telegram} alt="Telegram" />
  <a>Telegram</a>
</li>
<li className="s-li" onClick={() => window.open("https://x.com/RaisaNetwork", "_blank")}>
  <img className="footer-icons" src={Xicon} alt="Twitter" />
  <a>Twitter</a>
</li>
<li className="s-li" onClick={() => window.open("https://t.me/Raisanetwork", "_blank")}>
  <img className="footer-icons" src={Discord} alt="Discord" />
  <a>Discord</a>
</li>


            </ul>
          </div>
 
          <div className="footer-section">
            <h4>Company</h4>
            <ul className="footer-links">
            <li className="s-li" onClick={() => window.open("https://drive.google.com/drive/folders/1JsPbnYa8Zk8-LR-9yEVrpzOYlQY6-3OI?usp=sharing", "_blank")}>
  <a>Branding</a>
</li>
              <li className="s-li " onClick={() => window.location.href = "/about"}><a>About Us</a></li>
              <li className="s-li" onClick={() => window.open("https://x.com/RaisaNetwork", "_blank")}><a>News</a></li>
              <li className="s-li" onClick={() => window.location.href = "/price"}><a>Pricing</a></li>
            </ul>
          </div>
 
          <div className="footer-section">
            <h4 onClick={() => window.location.href = "/faq"}>Help</h4>
           
          </div>
        </div>
      </div>
     
      <div className="footer-bottom">
        <p>&copy; 2024 Raisa Network </p>
        <ul className="footer-links-bottom">
              <li><a href="#">Terms of Service</a></li>
              <li> <a href="#">Privacy Policy</a></li>
              <li><a href="#">Cookies</a></li>
            </ul>
      </div>
    </footer>
  );
};
 
export default Footer;