import React,{ useRef, useState, useEffect } from 'react';
import './ForgotPassword.css';
import  hashlogo from '../Images/Hash.png';
import {
    
    sendPasswordResetEmail
  } from "firebase/auth";

  import RaisaLogo from "../Images/RaisaLogo.png";
  import { auth, db } from "./Firebase/firebase"; // make sure db is initialized in Firebase config
  import { toast, ToastContainer } from "react-toastify";
  import { collection, query, where, getDocs } from "firebase/firestore";
  import "react-toastify/dist/ReactToastify.css";
  import tick from '../Images/IconBase.svg'
const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [isToastOpen, setIsToastOpen] = useState(false);

    const handleForgetPassword = async () => {
        if (!email) {
          toast.error("Please enter your email address.", {
            position: "top-center",
          });
          return;
        }
    
        try {
        //   // Step 1: Check if email exists in Firestore database
          const usersRef = collection(db, "User"); // 'users' is the collection where you store user info
          const q = query(usersRef, where("email", "==", email));
          const querySnapshot = await getDocs(q);
          console.log("checking ", querySnapshot)
          if (querySnapshot.empty === true) {
            // No matching email found in Firestore
            toast.error("No account found with this email. Please sign up.", {
              position: "top-center",
            });
            return;
          } else{
    
          // Step 2: If email exists, send password reset email
          await sendPasswordResetEmail(auth, email);
          setIsToastOpen(true);
          // toast.success("A Password Reset Link has been sent to your Email", {
          //   position: "top-center",
          // });

          setTimeout(()=>{
            setIsToastOpen(false);
          }, 15000)
        }
        } catch (error) {
          console.log(error)
          toast.error("Reset Link failed to send. Please try again.", {
            position: "top-center",
          });
        }
      };
    
    document.body.style.background = "rgba(234, 239, 255, 1)";

    

  return (<>
    <div className="container-fp">
      {isToastOpen && (<div className="form-modal-tost">

        <div className='tost-cntr'>
          <img  className='tost-tick'src={tick} alt='trick'/>
          <div className='tost-txt'> 
          Thanks ! An email was sent that will ask you to click
on a link to verify that you own this account. If you
don’t get the email, please contact
<a href="mailto:team@raisa.network" style={{ color: 'blue', textDecoration: 'underline' }}>
    team@raisa.network
  </a>.

          </div>

        </div>
      </div>)}

        <div className="form-modal">
            <div className='form-modal-cnt'>
        <div className='form-title-subtitle'>
        <div className='form-modal-title'>Forgot Password?</div>
        <div className='form-modal-sub-tittle' >Enter your Email and instructions will be sent to you!</div>
        </div>
        <div className='form-model-email-submit'>
        <div className='form-modal-email-input'>
        <label htmlFor="email">Email<span className='required-star'>*</span></label>
        <input 
        type="email"
         id="email" 
         placeholder="name@company.com" 
         required 
          onChange={(e) => setEmail(e.target.value)}
        value={email} />
        </div>
        <button type="button" className="reset-button" onClick={handleForgetPassword}>Reset</button>
        </div>
        </div>
        <p className="remember-me">Remember It? <a href="/login">Sign in here</a></p>
      </div>
      <ToastContainer />
      <img src={hashlogo} alt="Bottom Left Corner" className="bottom-left-image" />
    </div>
    {/*Mobile design */}
    <div className="mobile-screen-fp">
        <div className="mobile-navbar">
          <div class="navbar-m">
            <div class="logo-m">
              <img src={RaisaLogo} alt="raisa-logo" />
            </div>
            <div class="menu-icon-">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className='form-modal-m'>
        {isToastOpen && (<div className="form-modal-tost">

<div className='tost-cntr'>
  <img  className='tost-tick' src={tick} alt='trick'/>
  <div className='tost-txt'> 
  Thanks ! An email was sent that will ask you to click
on a link to verify that you own this account. If you
don’t get the email, please contact
<a href="mailto:team@raisa.network" style={{ color: 'blue', textDecoration: 'underline' }}>
    team@raisa.network
  </a>.

  </div>

</div>
</div>)}
        <div className="form-modal">
            <div className='form-modal-cnt'>
        <div className='form-title-subtitle'>
        <div className='form-modal-title'>Forgot Password?</div>
        <div className='form-modal-sub-tittle' >Enter your Email and instructions will be sent to you!</div>
        </div>
        <div className='form-model-email-submit'>
        <div className='form-modal-email-input'>
        <label htmlFor="email">Email<span className='required-star'>*</span></label>
        <input type="email" id="email" placeholder="name@company.com" required  onChange={(e) => setEmail(e.target.value)}
                value={email} />
        </div>
        <button type="submit" className="reset-button" onClick={handleForgetPassword}>Reset</button>
        </div>
        </div>
        <p className="remember-me">Remember It? <a href="/login">Sign in here</a></p>
      </div>
      </div>

       
        
      </div>
      
      <ToastContainer />
    </>
  );
};

export default ForgotPassword;
