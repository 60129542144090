import React, { useState, useEffect, useContext } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

import "./InboxSegmentControl.css";
import { db } from "../Firebase/firebase";
import { FilterContext } from "../Dashboard/FilterContext";
import InboxMessage from "./InboxMessage"
import Modal from "../Dashboard/ModalCategory";


import RequestMessage from "../Request/RequestMessage";
import { Diversity2, DoorBack } from "@mui/icons-material";
import InboxMessageTime from "./InboxMessageTime";


const InboxSegmentControl = ({
 
  sortOptions,
  searchQuery,
 
  sampleReject,
  messages
}) => {
  // const [activeSegment, setActiveSegment] = useState("pending");

  const {
    selectedCategories,
    selectedEcosystems,
    selectedFundingStages,
    selectedRequestTypes,
    selectedPartenerShipInterests,
    selectedLocation,
    selectedProfileStatus,
  } = useContext(FilterContext);
  const [users, setUsers] = useState([]);
  const [isInboxMessageOpen,setIsInboxMessageOpen]=useState(null)
  const [currentUser, setCurrentUser] = useState(null);
  const [fromProjectId,setFromProjectId]=useState(null)
  const [getProjId,setGetProjId]=useState(null)
  const [toProjectId,setToProjectId]=useState(null);
  const [toUserId,setToUserId]=useState(null);
  const [toProject,setToProject]=useState(null);
  const [showAll, setShowAll] = useState(false);
  const [storeName,setStoreName]=useState(null);
 

  
  
  

  useEffect(() => {
    const auth = getAuth();
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });
    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);


  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, "User"));
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);
    };

    fetchUsers();
  }, []);


  //  useEffect(()=>{
  //   async function fetchUserProjects() {
  //     if (!currentUser?.uid) {
  //       console.error("User is not logged in");
  //       return [];
  //     }
    
  //     try {
  //       const userProjectRef = collection(db, "UserProject");
  //       const q = query(userProjectRef, where("userId", "==", currentUser.uid));
  //       const querySnapshot = await getDocs(q);
    
  //       const userProjects = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));
    
  //       setFromProjectId(userProjects[0].id)
  //     } catch (error) {
  //       console.error("Error fetching user projects:", error);
  //       return [];
  //     }
  //   }
  //   fetchUserProjects()
  //  },[currentUser])

  

  
   const [latestMessages, setLatestMessages] = useState({});
  const handleSetLatestMessage = (index, message) => {
    // setLatestMessages(message)
    setLatestMessages((prev) => ({
      ...prev,
      [index]: message,
    }));
  };





   

  // Sort the filtered requests
  const sortRequests = (requests) => {
    let sortedRequests = [...requests];

    if (sortOptions.newest) {
      sortedRequests.sort(
        (a, b) => b.dateTime?.seconds - a.dateTime?.seconds
      );
    }

    if (sortOptions.oldest) {
      sortedRequests.sort(
        (a, b) => a.dateTime?.seconds - b.dateTime?.seconds
      );
    }

    if (sortOptions.az) {
      sortedRequests.sort((a, b) => {
        const nameA = a.projectName || ""; // Default to an empty string if undefined
        const nameB = b.projectName || ""; // Default to an empty string if undefined
        return nameA.localeCompare(nameB);
      });
    }

    if (sortOptions.za) {
      sortedRequests.sort((a, b) => {
        const nameA = a.projectName || ""; // Default to an empty string if undefined
        const nameB = b.projectName|| ""; // Default to an empty string if undefined
        return nameB.localeCompare(nameA);
      });
    }

    return sortedRequests;
  };

  // Filter requests based on search query
  const filterRequests = (requests) => {
    return requests.filter(
      (request) =>
        (request?.name?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        ) ||
        (request?.message?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        )
    );
  };

  

  /* const filterByRequestTypes = (requests) => {
    return requests.filter((request) => {
      // Find the user with matching toUserId in the User collection
      const user = users.find((u) => u.id === request.toUserId);
      const isVerifiedUser = user && user.verified === true;
      const isTgVerified = user && user.tgVerified === true;

      // Filter by Request Types
      const matchesRequestTypes =
        selectedRequestTypes.length === 0 ||
        (request?.requestTypes?.length > 0 &&
          request.requestTypes.some((type) =>
            selectedRequestTypes.includes(type)
          ));

      // Filter by Categories
      const matchesCategories =
        selectedCategories.length === 0 ||
        (request?.tocategory?.length > 0 &&
          request.tocategory.some((category) =>
            selectedCategories.includes(category)
          ));

      // Filter by Ecosystems
      const matchesEcosystems =
        selectedEcosystems.length === 0 ||
        (request?.toecosystem?.length > 0 &&
          request.toecosystem.some((ecosystem) =>
            selectedEcosystems.includes(ecosystem)
          ));

      // Filter by Funding Stages
      const matchesFundingStages =
        selectedFundingStages.length === 0 ||
        (request?.tofundingstage?.length > 0 &&
          request.tofundingstage.some((fs) =>
            selectedFundingStages.includes(fs)
          ));

      // Filter by Partnership Interests
      const matchesPartnershipInterests =
        selectedPartenerShipInterests.length === 0 ||
        (request?.topartnershipinterest?.length > 0 &&
          request.topartnershipinterest.some((interest) =>
            selectedPartenerShipInterests.includes(interest)
          ));

      // Filter by Location
      const matchesLocation =
        selectedLocation.length === 0 ||
        (request?.tolocation && selectedLocation.includes(request.tolocation));

      // Filter by Profile Status
      const matchesProfileStatus =
        selectedProfileStatus.length === 0 ||
        (selectedProfileStatus.includes("Email Verified") && isVerifiedUser) ||
        (selectedProfileStatus.includes("TG Verified") && isTgVerified);

      // Return true if all filters match
      return (
        matchesRequestTypes &&
        matchesCategories &&
        matchesEcosystems &&
        matchesFundingStages &&
        matchesPartnershipInterests &&
        matchesLocation &&
        matchesProfileStatus
      );
    });
  }; */


  const filterByRequestTypes = (requests) => {
    return requests.filter((request) => {
      // Find the user with matching toUserId in the User collection
      const user = users.find((u) => u.id === request.toUserId);
      const isVerifiedUser = user && user.verified === true;
      const isTgVerified = user && user.tgVerified === true;
  
      // Filter by Request Types (handle missing requestTypes or empty array)
      const matchesRequestTypes =
        selectedRequestTypes.length === 0 || 
        (Array.isArray(request?.requestTypes) && 
          request.requestTypes.some((type) =>
            selectedRequestTypes.includes(type)
          ));
  
      // Filter by Categories (handle missing tocategory)
      const matchesCategories =
        selectedCategories.length === 0 ||
        (Array.isArray(request?.tocategory) && 
          request.tocategory.some((category) =>
            selectedCategories.includes(category)
          ));
  
      // Filter by Ecosystems (handle missing toecosystem)
      const matchesEcosystems =
        selectedEcosystems.length === 0 ||
        (Array.isArray(request?.toecosystem) && 
          request.toecosystem.some((ecosystem) =>
            selectedEcosystems.includes(ecosystem)
          ));
  
      // Filter by Funding Stages (handle missing tofundingstage)
      const matchesFundingStages =
        selectedFundingStages.length === 0 ||
        (Array.isArray(request?.tofundingstage) && 
          request.tofundingstage.some((fs) =>
            selectedFundingStages.includes(fs)
          ));
  
      // Filter by Partnership Interests (handle missing topartnershipinterest)
      const matchesPartnershipInterests =
        selectedPartenerShipInterests.length === 0 ||
        (Array.isArray(request?.topartnershipinterest) && 
          request.topartnershipinterest.some((interest) =>
            selectedPartenerShipInterests.includes(interest)
          ));
  
      // Filter by Location (handle missing tolocation, and ensure it's case-insensitive)
      const matchesLocation =
        selectedLocation.length === 0 ||
        (request?.tolocation &&
          selectedLocation.some((location) =>
            request.tolocation.includes(location)
          ));
  
      // Filter by Profile Status
      const matchesProfileStatus =
        selectedProfileStatus.length === 0 ||
        (selectedProfileStatus.includes("Email Verified") && isVerifiedUser) ||
        (selectedProfileStatus.includes("TG Verified") && isTgVerified);
  
      // Return true if all filters match
      return (
        matchesRequestTypes &&
        matchesCategories &&
        matchesEcosystems &&
        matchesFundingStages &&
        matchesPartnershipInterests &&
        matchesLocation &&
        matchesProfileStatus
      );
    });
  };

  const [nameState, setNameState] = useState("");


  const handleNameClick = (request) => {
    const name =
      request?.toUserId === currentUser?.uid
        ? request?.name
        : request?.userId === currentUser?.uid
        ? request?.toname
        : null;

    setNameState(name)
  };
  
  // useEffect(()=>{
  //   async function getToProjectId(nameState) {
      
  
  //     try {
  //       const userProjectRef = collection(db, "UserProject");
  //       const q = query(userProjectRef, where("name", "==",nameState));
  //       const querySnapshot = await getDocs(q);
    
  //       const userProjects = querySnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //       setToProject(userProjects[0])
        
  //       setToProjectId(userProjects[0].id)
        
  //       setToUserId(userProjects[0].userId)
  //     } catch (error) {
  //       console.error("Error fetching user projects:", error);
  //       return [];
  //     }
  //   } 
  //   getToProjectId(nameState)
    
  // },[nameState])

  
//   const [messages, setMessages] = useState([

//   ]);

  
//   async function fetchMessages() {
//     if (!fromProjectId || !toProjectId) {
//       console.error("Missing project IDs");
//       return;
//     }

//     try {
//       const inboxRef = collection(db, "inbox");
//       console.log("ToProjectId query", toProjectId);
//       console.log("FROMPROJECTID query", fromProjectId);
//       const projectIds = [fromProjectId, toProjectId];
//       // First query: toProjectId matches either fromProjectId or toProjectId
//       const q1 = query(
//         inboxRef,
//         where("toProjectId", "in", projectIds),
//         where("fromProjectId", "in", projectIds)
//       );
//       // Execute the query
//       const querySnapshot = await getDocs(q1);
//       const results = [];
//       querySnapshot.forEach((doc) => {
//         results.push({
//           id: doc.id,
//           ...doc.data(),
//         });
//       });
//       // Display the results
//       console.log(results);

//       // Execute both queries
//       const snapshot1 = await getDocs(q1);

//       // Combine the results
//       const fetchedMessages = [
//         ...snapshot1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
//       ];

//       // Remove duplicates (optional)
//       const uniqueMessages = Array.from(
//         new Map(fetchedMessages.map((msg) => [msg.id, msg])).values()
//       );

//       // Sort messages by dateTime (assuming dateTime is a Firestore timestamp)
//       const sortedMessages = uniqueMessages.sort(
//         (a, b) => a.dateTime.seconds - b.dateTime.seconds
//       );

//       // Set state with the sorted messages
//      setMessages(sortedMessages)
//       //  setMessages((prevMessages) => [...prevMessages, ...sortedMessages]);
//       // localStorage.setItem("latestmsg", JSON.stringify(messages))
//     } catch (error) {
//       console.error("Error fetching messages:", error);
//     }
//   }
 
//   const [userConnectsList, setUserConnectsList] = useState([]);

//   const fetchLatestMessage = async (fromProjectId, toProjectId) => {
//     try {
//         const inboxRef = collection(db, "inbox");
//         const q = query(
//             inboxRef,
//             where("fromProjectId", "in", [fromProjectId, toProjectId]),
//             where("toProjectId", "in", [fromProjectId, toProjectId]),
//             orderBy("dateTime", "desc"),
//             limit(1) // Fetch the latest message
//         );

//         const querySnapshot = await getDocs(q);
//         if (!querySnapshot.empty) {
//             const latestMessage = querySnapshot.docs[0].data();
//             console.log("Latest Message:", latestMessage); // Log latest message
//             return latestMessage;
//         } else {
//             console.log("No messages found for this conversation.");
//             return null; // No messages found
//         }
//     } catch (error) {
//         console.error("Error fetching latest message:", error);
//         return null;
//     }
// };



// const fetchUserConnects = async (setUserConnectsList) => {
//   try {
//       const userConnectsCollection = collection(db, "UserConnects");
//       const querySnapshot = await getDocs(userConnectsCollection);

//       const userConnectsList = await Promise.all(
//           querySnapshot.docs.map(async (doc) => {
//               const data = doc.data();
              
//               // Only fetch the latest message for accepted projects
//               if (data.status === "Accepted") {
//                   const latestMessage = await fetchLatestMessage(data.fromProjectId, data.toProjectId);
//                   return { ...data, latestMessage }; // Attach the latest message for accepted requests
//               }
//               return null; // Skip if not accepted
//           })
//       );

//       // Filter out null entries (non-accepted projects)
//       const filteredUserConnectsList = userConnectsList.filter(item => item !== null);
//       console.log("Fetched User Connects List with latest messages for accepted projects:", filteredUserConnectsList);

//       setUserConnectsList(filteredUserConnectsList); // Update the state with the filtered data
//   } catch (error) {
//       console.error("Error fetching user connects:", error);
//   }
// };


// useEffect(() => {
//   fetchUserConnects(setUserConnectsList);
// }, [setUserConnectsList]);






// const requests = sortRequests(filterByRequestTypes(filterRequests(sampleReject)));
// const fetchLatestMessage1 = async (request) => {
//   try {
//     if (!fromProjectId || !toProjectId) {
//       console.error("Missing project IDs");
//       return;
//     }

//     const inboxRef = collection(db, "inbox");

//     // Query to get messages for the current request
//     const q = query(
//       inboxRef,
//       where("fromProjectId", "in", [fromProjectId, toProjectId]),
//       where("toProjectId", "in", [fromProjectId, toProjectId]),
//       orderBy("dateTime", "desc"),
//       limit(1) // Fetch the latest message only
//     );

//     const querySnapshot = await getDocs(q);
//     const results = [];

//     querySnapshot.forEach((doc) => {
//       results.push({
//         id: doc.id,
//         ...doc.data(),
//       });
//     });
//     console.log("result", results)
//     // If there are results, set the latest message
//     if (results.length > 0) {
//       const latestMessage = results[0].message;
//       setLatestMessages((prevMessages) => ({
//         ...prevMessages,
//         [request.id]: latestMessage,
//       }));
//     } else {
//       setLatestMessages((prevMessages) => ({
//         ...prevMessages,
//         [request.id]: "No recent message",
//       }));
//     }
//   } catch (error) {
//     console.error("Error fetching latest message:", error);
//   }
// };

// // useEffect to fetch messages when the component mounts or the project IDs change
// useEffect(() => {
//   // Fetch the latest message for each request
//   sampleReject.forEach((request) => {
//     fetchLatestMessage1(request);
//   });
// }, [requests, fromProjectId, toProjectId,requests]);




//  const [mydata, setMyData]= useState([])
//  const fetchMyData = async () => {
//   try {
//     const userProjectsCollection = collection(db, "UserProject");
//     const userProjectsQuery = query(
//       userProjectsCollection,
//       where("userId", "==", currentUser?.uid)
//     );
//     console.log("current user id", currentUser?.uid)
//     const querySnapshot = await getDocs(userProjectsQuery);

//     // Map through the documents to create a list
//     const userProject = querySnapshot.docs.map((doc) => ({
//       id: doc.id, // Document ID
//       ...doc.data(), // Document data
//     }));
//     console.log("fetching my data up ",userProject[0])
//     setMyData(userProject[0]);

//     console.log("fetching my data ",mydata)
//   } catch (e) {
//     console.error("Error fetching User Connects: ", e);
//   }
// };

// useEffect( ()=>{
//   fetchMyData()
// },[])


// const fetchinboxmsglist = async () => {
//   try {
//     // Reference to the "inbox" collection in Firestore
//     const inboxRef = collection(db, "inbox");

//     // Assuming `mydata?.id` contains the current project ID
//     const q1 = query(
//       inboxRef,
//       where("toProjectId", "==", mydata?.id),
//       where("fromProjectId", "==", mydata?.id)
//     );

//     // Fetch all the messages based on the query
//     const querySnapshot = await getDocs(q1);

//     // Process the fetched messages
//     const messages = [];
//     querySnapshot.forEach((doc) => {
//       messages.push({ id: doc.id, ...doc.data() }); // Include the document ID and data
//     });

//     console.log("Fetched Messages:", messages);

//     // Return or handle the messages as needed
//     return messages;
//   } catch (error) {
//     console.error("Error fetching messages:", error);
//     throw error; // Re-throw if you want to handle it in the calling function
//   }
// };

// useEffect(()=>{
//   fetchinboxmsglist()
// })
// display all the list 


const[messagesToCurrentUser, setMessagesToCurrentUser] = useState([]);

//   const fetchMyData = async () => {
//     try {
//       const userProjectsCollection = collection(db, "UserProject");
//       const userProjectsQuery = query(
//         userProjectsCollection,
//         where("userId", "==", currentUser?.uid)
//       );
//       console.log("Current user ID:", currentUser?.uid);

//       const querySnapshot = await getDocs(userProjectsQuery);
//       const userProject = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));

//       console.log("Fetched my data:", userProject[0]);
//       setMyData(userProject[0]); // Set the first project as `mydata`
//     } catch (e) {
//       console.error("Error fetching User Connects:", e);
//     }
//   };

//   const fetchInboxMsgList = async () => {
//     if (!mydata?.id) {
//       console.warn("Cannot fetch messages, mydata is not set");
//       return;
//     }

//     try {
//       const inboxRef = collection(db, "inbox");
//       // const q1 = query(
//       //   inboxRef,
//       //   where("toProjectId", "==", mydata?.id),
//       //   where("fromProjectId", "==", mydata?.id)
//       // );

//       const q1 = query(
//         inboxRef,
        
//           where("toProjectId", "==", mydata?.id),
         
        
//         orderBy("dateTime","desc") // Ensure `timestamp` field exists
     
//       );

//       const querySnapshot = await getDocs(q1);
//       const fetchedMessages = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));


//      /*  const uniqueMessages = [
//         ...new Map(
//           fetchedMessages.map((msg) => [
//             `${msg.toProjectId}-${msg.fromProjectId}`, 
//             // Create a unique key
//             msg,
//           ])
//         ).values(),
//       ];
    
//       console.log("Unique messages:", uniqueMessages); */
//       /*setMessages(uniqueMessages); */ // Store unique messages in state





//        console.log("Fetched messages:", fetchedMessages);
//        setMessages(fetchedMessages); // Store messages in state








// /* 
//       const currentUserId = mydata.id; // Replace this with the actual current user ID

// // Separate the messages where `toProjectId` matches the current user ID
// const messagesToCurrentUser = uniqueMessages.filter(
//   (msg) => msg.toProjectId === currentUserId
// );
// setMessagesToCurrentUser( messagesToCurrentUser)
// // Separate the messages where `fromProjectId` matches the current user ID
// const messagesFromCurrentUser = uniqueMessages.filter(
//   (msg) => msg.fromProjectId === currentUserId
// );

// console.log("Messages to current user:", messagesToCurrentUser);
// console.log("Messages from current user:", messagesFromCurrentUser);
//  */
      
//     } catch (error) {
//       console.error("Error fetching messages:", error);
//     }
//   };

//   // Fetch `mydata` on component mount
//    useEffect(() => {
//     fetchMyData();
//   }, []);  // Empty dependency array ensures this runs only once

//   // Fetch inbox messages when `mydata` is updated
//   useEffect(() => {
    
//     if (mydata) {
//       fetchInboxMsgList();
//     }
//   }, [mydata ]); // Dependency on `mydata`


  const filterLatestMessages = (messages) => {
    // Create a map to store the latest message for each FromProjectId
    const latestMessagesMap = new Map();
  
    messages.forEach((msg) => {
      const currentProjectId = msg.fromProjectId;
      const existingMessage = latestMessagesMap.get(currentProjectId);
  
      // If no message exists for this FromProjectId, or the current message is newer, update the map
      if (!existingMessage || new Date(msg.dateTime) > new Date(existingMessage.dateTime)) {
        latestMessagesMap.set(currentProjectId, msg);
      }
    });
  
    // Convert the map values back to an array
    return Array.from(latestMessagesMap.values());
  };
  
  // Usage
  // const latestMessagess = filterLatestMessages(messages);
  // console.log("Latest messages for each FromProjectId:", latestMessagess);
 
  

//   const fetchUniqueInboxRecords = async () => {
//     try {
//       const inboxRef = collection(db, "inbox"); // Get reference to the "inbox" collection
  
//       const q1 = query(
//         inboxRef,
//         where("toProjectId", "==", mydata?.id), // Filter by `toProjectId`
//         orderBy("dateTime") // Order by `dateTime`
//       );
  
//       const querySnapshot = await getDocs(q1);
  
//       const uniqueMessages = querySnapshot.docs.map((doc) => ({
//         id: doc.id,
//         ...doc.data(),
//       }));
  
//       console.log("Unique Inbox Records:", uniqueMessages);
//       setMessages(uniqueMessages); // Update state or process further
//     } catch (error) {
//       console.error("Error fetching inbox records:", error);
//     }
//   };
//  useEffect(()=>{

//   fetchUniqueInboxRecords()
//  },[mydata?.id])
  
 /* return(
<div>{latestMessagess.map((ele)=>
{
  return(<>
    <div>{ele.id} </div>
    <div>{ele.message} </div>
    </>
  )
}

)}</div>


 ) */



const [messagesWithNames, setMessagesWithNames] = useState([]);

  // useEffect(() => {
  //   // const fetchProjectNames = async (messages) => {
     
  //   //   const updatedMessages = await Promise.all(
  //   //     messages.map(async (msg) => {
  //   //       try {
  //   //         const projectDoc = await getDoc(doc(db, "Userproject", msg.fromUserId));
  //   //         const projectName = projectDoc.exists() ? projectDoc.data().name : "Unknown Project";
  //   //         return { ...msg, projectName };
  //   //       } catch (error) {
  //   //         console.error(`Error fetching project name for ID ${msg.fromProjectId}:`, error);
  //   //         return { ...msg, projectName: "Error fetching name" };
  //   //       }
  //   //     })
  //   //   );
  //   //   console.log("updated",updatedMessages)
  //   //   setMessagesWithNames(updatedMessages); // Update the state
  //   // };
  //   // fetchProjectNamesWithWhere()
  //   fetchProjectNames(latestMessagess);
  //   setLoading(false)
  // }, [latestMessagess]);






  // const fetchProjectName = async (fromProjectId) => {
  //   try {
  //     const projectDoc = await getDoc(doc(db, "UserProject", fromProjectId));
  //     if (projectDoc.exists()) {
  //       console.log("name of the project ",  projectDoc.data().name)
  //       return projectDoc.data().name; // Return the project name
  //     } else {
  //       console.warn(`No project found for ID: ${fromProjectId}`);
  //       return "Unknown Project";
  //     }
  //   } catch (error) {
  //     console.error(`Error fetching project for ID ${fromProjectId}:`, error);
  //     return "Error fetching name"; // Fallback for fetch errors
  //   }
  // };
  const fetchProjectName = async (fromProjectId) => {
    try {
      const projectDoc = await getDoc(doc(db, "UserProject", fromProjectId));
      if (projectDoc.exists()) {
        const projectData = projectDoc.data();
        console.log("Project Name:", projectData.name);
        console.log("Request Type:", projectData.requestType);
        console.log("Location:", projectData.country);
        
  
        return {
          name: projectData.name,
          requestType: projectData.requestType || [],  // Default to empty array if not present
          country: projectData.country || "Unknown Location", // Default to "Unknown Location" if not present
          profilePicture:projectData?.profilePicture,
        };
      } else {
        console.warn(`No project found for ID: ${fromProjectId}`);
        return {
          name: "Unknown Project",
          requestType: [],
          country: "Unknown Location",
          profilePicture:"no",
        };
      }
    } catch (error) {
      console.error(`Error fetching project for ID ${fromProjectId}:`, error);
      return {
        name: "Error fetching name",
        requestType: [],
        country: "Error fetching location",
        profilePicture:"no"
      };
    }
  };
  
  // const fetchProjectNames = async (messages) => {
  //   try {
  //     const updatedMessages = await Promise.all(
  //       messages.map(async (msg) => {
  //         const projectName = await fetchProjectName(msg.fromProjectId); // Fetch project name
  //         return { ...msg, projectName }; // Attach the project name to the message
  //       })
  //     );
  
  //     console.log("Updated Messages:", updatedMessages);
  //     setMessagesWithNames(updatedMessages); // Update state with the enriched messages
  //   } catch (error) {
  //     console.error("Error updating messages with project names:", error);
  //     // Fallback in case of a major error
  //     const fallbackMessages = messages.map((msg) => ({
  //       ...msg,
  //       projectName: "Error fetching name",
  //     }));
  //     setMessagesWithNames(fallbackMessages);
  //   }
  // };
    
  const fetchProjectNames = async (messages) => {
    try {
      const updatedMessages = await Promise.all(
        messages.map(async (msg) => {
          const projectData = await fetchProjectName(msg.fromProjectId); // Fetch project data
  
          return {
            ...msg,
            projectName: projectData.name,
            requestType: projectData.requestType, // Add the requestType array
            country: projectData.country, // Add the location
            profilePicture:projectData.profilePicture
          };
        })
      );
  
      console.log("Updated Messages:", updatedMessages);
      setMessagesWithNames(updatedMessages); // Update state with the enriched messages
    } catch (error) {
      console.error("Error updating messages with project names:", error);
      // Fallback in case of a major error
      const fallbackMessages = messages.map((msg) => ({
        ...msg,
        projectName: "Error fetching name",
        requestType: [],
        country: "Error fetching location",
        profilePicture:"no"
      }));
      setMessagesWithNames(fallbackMessages);
    }
  };
  

const fetchProjectNamesWithWhere = async (messages) => {


  try {
    // Extract all unique fromUserId values from messages
    const userIds = [...new Set(messages.map((msg) => msg.fromUserId))];

    // Query Firestore to fetch all matching projects
    const q = query(collection(db, "Userproject"), where("__name__", "in", userIds));
    const querySnapshot = await getDocs(q);

    // Map the results to a dictionary for quick lookup
    const projectMap = {};
    querySnapshot.forEach((doc) => {
      projectMap[doc.id] = doc.data().name;
    });

    // Enrich the messages with project names
    const updatedMessages = messages.map((msg) => ({
      ...msg,
      projectName: projectMap[msg.fromUserId] || "Unknown Project",
    }));

    console.log("Updated Messages:", updatedMessages);
    setMessagesWithNames(updatedMessages); // Update the state
  } catch (error) {
    console.error("Error fetching project names with where query:", error);

    // Fallback: Assign an error message for all messages
    const fallbackMessages = messages.map((msg) => ({
      ...msg,
      projectName: "Error fetching name",
    }));
    setMessagesWithNames(fallbackMessages);
  }
};


// if (loading) {
//   return (
//     <div class="loading-dots">
//       Loading<span>.</span>
//       <span>.</span>
//       <span>.</span>
//     </div>
//   ); // Display a loading state or spinner
// }

return (
  <div className="Requestcontainer">
    
    <div className="table-container">
        
     
      

     
       <table className="reject-table">
    <thead className="tablehead">
      <tr className="tablerow" >
        <th className="table-heading id-inbox">ID</th>
        
        <th className="table-heading noreject">Name of Project</th>
        <th className="table-heading lstupdtreject-i">Last Updated</th>
        <th className="table-heading msgreject-i">Message</th>
        <th className="table-heading locreject">Location</th>      
        <th className="table-heading reqtypreject">Request Type</th>
        
      </tr>
    </thead>
    <tbody>
      
      { sortRequests(messages).map((msg,i) => (
        <tr  style={{borderBottom:"1px solid rgba(245, 245, 245, 1)"}}  key={msg.id}>
          <td
           className="id-request"
           style={{ textAlign: "center", verticalAlign: "middle" }}
          > {String(i + 1).padStart(3, "0")}</td>
          
         
          <td  style={{ textAlign: "center", verticalAlign: "middle" }}>

                <div
                    className="name-request-pending"
                   /*  onClick={() => handleNameClick(request)} */
                  >
                    {/* <img
                      src={request?.toprofilePicture}
                      alt="profile-pic"
                      
                    /> */}
                    <img
src={msg?.profilePicture}
alt="profile-pic" className="inbox-profile-pic"
/>

                    <div>
                      <div className="name-request-text-i" title={msg?.projectName}>
                      
                     {/*  {request.toname.length >10
                          ? `${request.toname.slice(0, 10)}...`
                          : request.toname} */}
                          {msg?.projectName}

                      </div>
                      
                    </div>
                    
                  </div>
                </td>
          <td
          className="date-request-pending"
          style={{ textAlign: "center", verticalAlign: "middle" }}>
          {new Date(msg.dateTime.seconds * 1000)
                          .toLocaleDateString()
                          .replace(/\//g, "-")}
          </td>
          {/* <td>{msg.message || "No message content"}</td> */}

          <td style={{ border: "none",backgroundColor:"rgba(237, 243, 255, 1)" }}>
                  <div className="message-request"
                    
                  >
                    
                    
                    <div className="message-date">
                     
                      <div className="message-request-text" title={msg?.message} onClick={()=>{
                         
                        setIsInboxMessageOpen(msg.id)}}>

                        {/* {latestmsg[latestmsg.length -1]?.message.length >70
                          ? `${latestmsg[latestmsg.length -1]?.message.slice(0, 70)}...`
                          : latestmsg[latestmsg.length -1]?.message} */}

{/* {messages[messages.length -1]?.message.length >70
                          ? `${messages[messages.length -1]?.message.slice(0, 70)}...`
                          : messages[messages.length -1]?.message}  */}
                      
                      {/* {request.latestMessage?.message
                  ? request.latestMessage.message.length > 70
                      ? `${request.latestMessage.message.slice(0, 70)}...`
                      : request.latestMessage.message
                  : "No messages yet"} */}
{/* {request.message.length >70
                          ? `${request.message.slice(0, 70)}...`
                          : request.message}  */}
                         {/*  {latestMessages[i] || "No recent message"} */}
                           {latestMessages[i] ? (
                            
latestMessages[i]

) : (
msg?.message.length > 70 ? `${msg?.message.slice(0, 70)}...` : msg?.message
)} 


                      </div>
                      <p style={{ margin: 0 }} className="below-message">
                        {<InboxMessageTime request={msg.dateTime} />} at{" "}
                        {new Date(msg.dateTime.seconds * 1000)
                          .toLocaleDateString()
                          .replace(/\//g, "-")}
                      </p>
                     
                    </div>
                  </div>
                  {isInboxMessageOpen===msg.id && (
                        
                        <div className="inbox-popup-overlay">
      
        <InboxMessage setIsInboxMessageOpen={setIsInboxMessageOpen}  fromProjectId={msg?.fromProjectId} fromUserId={currentUser?.uid} 
        /* toProjectId={ (request?.projectId===fromProjectId)? ( getToProjectId(request?.userId)): (request?.projectId)} */
        toProjectId={msg?.toProjectId} 
         toUserId={msg?.toUserId}
         nameState={msg?.projectName}
         fromName={msg?.name} toName={msg?.name}
         
         firstmsg={msg}
         setLatestMessage={(message) => handleSetLatestMessage(i, message)}
         />
      
    </div>



                        )}

                       
                </td>
          <td
                  className="location-request-pending" title={msg?.country}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  
                  {/* {request?.tolocation.join(", ").length >10
                          ? `${request?.tolocation.join(", ").slice(0, 10)}...`
                          : request?.tolocation.join(", ")} */}
                          {/* {Array.isArray(request?.tolocation)
? (request.tolocation.join(", ").length > 10
  ? `${request.tolocation.join(", ").slice(0, 10)}...`
  : request.tolocation.join(", "))
: request?.tolocation } */}
{Array.isArray(msg?.country)
  ? (msg.country.join(", ").length > 10
      ? `${msg.country.join(", ").slice(0, 10)}...`
      : msg.country.join(", "))
  : msg?.country}
                </td>
          <td
    className="requestType-request"
    title={msg?.requestType.join(", ")} // Tooltip with all types
    style={{ textAlign: "center", verticalAlign: "middle" }}
  >
    <div className="requestType-request-name"> {msg?.requestType[0]}</div>
    
   <div className="requestType-request-name">  {msg?.requestType.length > 1 && (
      <>
        <span
          className="category-more"
          onClick={()=> setShowAll(msg.id)}
          style={{ cursor: "pointer", color: "blue" }}
        >
          +{msg?.requestType.length - 1} More
        </span>

        {showAll  === msg.id && (
          <Modal
            maplist={msg?.requestType.slice(1)} // Pass the remaining items to the modal
            onClose={()=>setShowAll(false)}
          />
        )}
      </>
    )}
    </div>


  </td>
          
        </tr>
      ))}
    </tbody>
  </table>
      
    </div>
  </div>
);
};

export default InboxSegmentControl;
