// src/Pagination.js
import React  from 'react';
import './CPaginantion.css'
import RightArrow from "../Images/RightArrow.png";
import { useState, useEffect } from 'react';
const Pcagination = ({ currentPage, totalPages, onPageChange }) => {
    // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 450);
    const [isExpanded, setIsExpanded] = useState(false);

   
    const getPageNumbers = () => {
        const pages = [];
        
        if ( !isExpanded) {
             pages.push(1);
            if (currentPage === 1) {
               
                if (totalPages > 2) pages.push("...");
                pages.push(totalPages);
            } else {
                pages.push(currentPage);
                if (currentPage < totalPages - 1) pages.push("...");
               
            }
        } else {
          
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        }

        return pages;
    };

    const handleEllipsisClick = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className='pagination-container-all'>
            <ul className="pagination">
                <li>
                    <button onClick={() => onPageChange(1)} disabled={currentPage === 1}>
                        &laquo;
                    </button>
                </li>

                <div className={`scrollable-pages-all ${isExpanded ? 'expanded' : ''}`}>
                    {getPageNumbers().map((page, index) => (
                        <li key={index} className={currentPage === page ? 'active' : ''}>
                            {page === '...' ? (
                                <span className="dots" onClick={handleEllipsisClick}>...</span>
                            ) : (
                                <button onClick={() => onPageChange(page)}>{page}</button>
                            )}
                        </li>
                    ))}
                </div>

                <li>

                    
                    <button onClick={() =>{
                        if (currentPage == 0){
                            alert("no data found")
                            return;
                            
                        }
                        else{
                            onPageChange(currentPage + 1)
                        }
                    }
                        
                            
                           } disabled={currentPage === totalPages}>
                        <img src={RightArrow} alt='>' />
                    </button>
                </li>

                <li>
                    <button onClick={() => { 
                        if (totalPages <= 0)
                         {const newval = 1;
                        onPageChange(newval)}
                        else {
                            onPageChange(totalPages)
                        }
                        
                        
                        }} disabled={currentPage === totalPages}>
                        &raquo;
                    </button>
                </li>
            </ul>
        </div>
    );
};


export default Pcagination;
