import React, { useState, useEffect } from 'react';
import './mobilepagination.css';
import RightArrow from "../Images/RightArrow.png";

const MobilePagination = ({ currentPage, totalPages, onPageChange }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 450);

    useEffect(() => {
        const handleResize = () => setIsSmallScreen(window.innerWidth < 450);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getPageNumbers = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        return pages;
    };

    return (
        <div className='pagination-container'>
            <ul className="pagination">
            <li>
                    <button onClick={() => onPageChange(1)} disabled={currentPage === 1}>
                        &laquo;
                    </button>
                </li>

                <div className={`scrollable-pages ${isSmallScreen ? 'expanded' : ''}`}>
                    {getPageNumbers().map((page) => (
                        <li key={page} className={currentPage === page ? 'active' : ''}>
                            <button onClick={() => onPageChange(page)}>{page}</button>
                        </li>
                    ))}
                </div>

                

                
                    <li>
                        <button onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                            <img src={RightArrow} alt='>' />
                        </button>
                    </li>
                
                   <li>
                    <button onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages}>
                        &raquo;
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default MobilePagination;
