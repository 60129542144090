import React from 'react'
import "./Card2.css";
const ShimmerUiCard2 = () => {
  return (
    
      <div className="card-container">
        <h4>Loading Data...</h4>
      </div>
    
  )
}

export default ShimmerUiCard2
