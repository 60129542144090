import { useEffect, useState } from "react";

export const InboxMessageTime = ({ request }) => {
    const [timeAgo, setTimeAgo] = useState("");

    // Function to calculate the time difference
    // const calculateTimeAgo = () => {
    //     const now = new Date(); // Current date
    //     const messageTime = new Date(request.createdAt.seconds * 1000); // Convert Firestore Timestamp to Date

    //     const difference = now - messageTime; // difference in milliseconds

    //     // Convert difference into hours, minutes, or seconds
    //     const hoursAgo = Math.floor(difference / (1000 * 60 * 60));
    //     const minutesAgo = Math.floor((difference / (1000 * 60)) % 60);
    //     const secondsAgo = Math.floor((difference / 1000) % 60);

    //     // Customize display for hours, minutes, or seconds
    //     if (hoursAgo > 0) {
    //         return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
    //     } else if (minutesAgo > 0) {
    //         return `${minutesAgo} min${minutesAgo > 1 ? "s" : ""} ago`;
    //     } else {
    //         return `${secondsAgo}  sec${secondsAgo > 1 ? "s" : ""} ago`;
    //     }
    // };
    const calculateTimeAgo = () => {
        const now = new Date(); // Current date
        const messageTime = new Date(request.seconds * 1000); // Convert Firestore Timestamp to Date
    
        const difference = now - messageTime; // Difference in milliseconds
    
        // Convert difference into time units
        const secondsAgo = Math.floor((difference / 1000) % 60);
        const minutesAgo = Math.floor((difference / (1000 * 60)) % 60);
        const hoursAgo = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const daysAgo = Math.floor((difference / (1000 * 60 * 60 * 24)) % 30);
        const monthsAgo = Math.floor((difference / (1000 * 60 * 60 * 24 * 30)) % 12);
        const yearsAgo = Math.floor(difference / (1000 * 60 * 60 * 24 * 365));
    
        // Customize display for years, months, days, hours, minutes, or seconds
        if (yearsAgo > 0) {
            return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;
        } else if (monthsAgo > 0) {
            return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
        } else if (daysAgo > 0) {
            return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
        } else if (hoursAgo > 0) {
            return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
        } else if (minutesAgo > 0) {
            return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
        } else {
            return `${secondsAgo} second${secondsAgo > 1 ? "s" : ""} ago`;
        }
    };
    
    

    // Update the "time ago" every second
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(calculateTimeAgo());
        }, 1000); // Updates every second

        // Clean up the interval when component unmounts
        return () => clearInterval(interval);
    }, [request]);

    // Return a JSX element containing the timeAgo value
    return <span>{timeAgo}</span>; // Use a span or another element to wrap the timeAgo
};

export default InboxMessageTime;
