import React from 'react'
import './Card.css';
const ShimmerUiCard = () => {
  return (
   
      <div className="card">
      <div class="loading-dots">
    <span>.</span><span>.</span><span>.</span>
  </div>
    </div>
   
  )
}

export default ShimmerUiCard
