import React, { useState, useEffect, useContext } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
  where,
  query,
} from "firebase/firestore";
import CategoryClose from "../../Images/CategoryClose.png"

import "./Segmentcontrol.css";
import { db } from "../Firebase/firebase";
import { FilterContext } from "../Dashboard/FilterContext";
import Message from "./Message";

import Raisa from "../../Images/1.png";
import RequestMessage from "./RequestMessage";
import Modal from "../Dashboard/ModalCategory";
import RequestInboxMessage from "./RequestInboxMessage";
import { toast, ToastContainer } from "react-toastify";

const Segmentcontrol = ({
  activeSegment,
  sortOptions,
  searchQuery,
  fetchUserConnects,
  matchingPendingRequests,
  matchingRejectedRequests,
  matchingSendRequests,
  sample,
  sampleSend,
  sampleReject,
}) => {
  // const [activeSegment, setActiveSegment] = useState("pending");
  const [currentUser, setCurrentUser] = useState(null);
  const [nameState, setNameState] = useState("");
  const [fromProjectId,setFromProjectId]=useState(null)
  const [getProjId,setGetProjId]=useState(null)
  const [toProjectId,setToProjectId]=useState(null);
  const [toUserId,setToUserId]=useState(null);
  const [toProject,setToProject]=useState(null);

  const [showAll, setShowAll] =useState(null);

  const handleToggle = () => {
    setShowAll(!showAll);
  };


  

  const handleNameClick = (request) => {
    const name =
      request?.toUserId === currentUser?.uid
        ? request?.name
        : request?.userId === currentUser?.uid
        ? request?.toname
        : null;

    setNameState(name)
    console.log("name checking",name);
    console.log("name checking",nameState);
  };
  


  const {
    selectedCategories,
    selectedEcosystems,
    selectedFundingStages,
    selectedRequestTypes,
    selectedPartenerShipInterests,
    selectedLocation,
    selectedProfileStatus,
  } = useContext(FilterContext);
  const [users, setUsers] = useState([]);

  const [isInboxMessageOpen,setIsInboxMessageOpen]=useState(null)


  const closeModal = () => {
    setIsInboxMessageOpen(null)
  };
  // Fetch users from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      const usersSnapshot = await getDocs(collection(db, "User"));
      const usersList = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersList);
    };

    fetchUsers();
  }, []);

  // Sort the filtered requests
  const sortRequests = (requests) => {
    let sortedRequests = [...requests];

    if (sortOptions.newest) {
      sortedRequests.sort(
        (a, b) => b.createdAt?.seconds - a.createdAt?.seconds
      );
    }

    else if (sortOptions.oldest) {
      sortedRequests.sort(
        (a, b) => a.createdAt?.seconds - b.createdAt?.seconds
      );
    }

    else  if (sortOptions.az) {
      sortedRequests.sort((a, b) => {
        const nameA = a.name || ""; // Default to an empty string if undefined
        const nameB = b.name || ""; // Default to an empty string if undefined
        return nameA.localeCompare(nameB);
      });
    }

     else if (sortOptions.za) {
      sortedRequests.sort((a, b) => {
        const nameA = a.name || ""; // Default to an empty string if undefined
        const nameB = b.name || ""; // Default to an empty string if undefined
        return nameB.localeCompare(nameA);
      });
    }
      else {
        sortedRequests.sort(
          (a, b) => b.createdAt?.seconds - a.createdAt?.seconds
        );
      }
    return sortedRequests;
  };

  // Filter requests based on search query
  const filterRequests = (requests) => {
    return requests.filter(
      (request) =>
        (request?.name?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        ) ||
        (request?.message?.toLowerCase() || "").includes(
          searchQuery.toLowerCase()
        )
    );
  };

  // Filter requests based on selected request types
  /* const filterByRequestTypes = (requests) => {
    if (selectedRequestTypes.length > 0) {
      return requests.filter(
        (request) =>
          request?.requestTypes?.length > 0 && // Check if requestTypes exists and is not empty
          request.requestTypes.some((type) =>
            selectedRequestTypes.includes(type)
          )
      );
    } else {
      return requests;
    }
  }; */

  /*  const filterByRequestTypes = (requests) => {
    return requests.filter((request) => {
      // Find the user with matching toUserId in the User collection
      const user = users.find((u) => u.id === request.toUserId);
      const isVerifiedUser = user && user.verified === true;
      // Filter by Request Types
      const matchesRequestTypes =
        selectedRequestTypes.length === 0 ||
        (request?.requestTypes?.length > 0 &&
          request.requestTypes.some((type) =>
            selectedRequestTypes.includes(type)
          ));
  
      // Filter by Categories
      const matchesCategories =
        selectedCategories.length === 0 ||
        (request?.tocategory?.length > 0 &&
          request.tocategory.some((category) =>
            selectedCategories.includes(category)
          ));
  
      // Filter by Ecosystems
      const matchesEcosystems =
        selectedEcosystems.length === 0 ||
        (request?.toecosystem?.length > 0 &&
          request.toecosystem.some((ecosystem) =>
            selectedEcosystems.includes(ecosystem)
          ));
  
      // Filter by Funding Stages
      const matchesFundingStages =
        selectedFundingStages.length === 0 ||
        (request?.tofundingstage?.length >0  &&
          request.tofundingstage.some((fs)=>selectedFundingStages.includes(fs))
          );
  
      // Filter by Partnership Interests
      const matchesPartnershipInterests =
        selectedPartenerShipInterests.length === 0 ||
        (request?.topartnershipinterest?.length > 0 &&
          request.topartnershipinterest.some((interest) =>
            selectedPartenerShipInterests.includes(interest)
          ));
  
      // Filter by Location
      const matchesLocation =
        selectedLocation.length === 0 ||
        (request?.location && selectedLocation.includes(request.location));
  
      // Filter by Profile Status
      const matchesProfileStatus =
       
  
      // Return true if all filters match
      return (
        matchesRequestTypes &&
        matchesCategories &&
        matchesEcosystems &&
        matchesFundingStages &&
        matchesPartnershipInterests &&
        matchesLocation &&
        matchesProfileStatus
      );
    });
  }; */

  /* const filterByRequestTypes = (requests) => {
    return requests.filter((request) => {
      // Find the user with matching toUserId in the User collection
      const user = users.find((u) => u.id === request.toUserId);
      const isVerifiedUser = user && user.verified === true;
      const isTgVerified = user && user.tgVerified === true;

      // Filter by Request Types
      const matchesRequestTypes =
        selectedRequestTypes.length === 0 ||
        (request?.requestTypes?.length > 0 &&
          request.requestTypes.some((type) =>
            selectedRequestTypes.includes(type)
          ));

      // Filter by Categories
      const matchesCategories =
        selectedCategories.length === 0 ||
        (request?.tocategory?.length > 0 &&
          request.tocategory.some((category) =>
            selectedCategories.includes(category)
          ));

      // Filter by Ecosystems
      const matchesEcosystems =
        selectedEcosystems.length === 0 ||
        (request?.toecosystem?.length > 0 &&
          request.toecosystem.some((ecosystem) =>
            selectedEcosystems.includes(ecosystem)
          ));

      // Filter by Funding Stages
      const matchesFundingStages =
        selectedFundingStages.length === 0 ||
        (request?.tofundingstage?.length > 0 &&
          request.tofundingstage.some((fs) =>
            selectedFundingStages.includes(fs)
          ));

      // Filter by Partnership Interests
      const matchesPartnershipInterests =
        selectedPartenerShipInterests.length === 0 ||
        (request?.topartnershipinterest?.length > 0 &&
          request.topartnershipinterest.some((interest) =>
            selectedPartenerShipInterests.includes(interest)
          ));

      // Filter by Location
      const matchesLocation =
        selectedLocation.length === 0 ||
        (request?.tolocation && selectedLocation.includes(request.tolocation));

      // Filter by Profile Status
      const matchesProfileStatus =
        selectedProfileStatus.length === 0 ||
        (selectedProfileStatus.includes("Email Verified") && isVerifiedUser) ||
        (selectedProfileStatus.includes("TG Verified") && isTgVerified);

      // Return true if all filters match
      return (
        matchesRequestTypes &&
        matchesCategories &&
        matchesEcosystems &&
        matchesFundingStages &&
        matchesPartnershipInterests &&
        matchesLocation &&
        matchesProfileStatus
      );
    });
  }; */


  const filterByRequestTypes = (requests) => {
    return requests.filter((request) => {
      // Find the user with matching toUserId in the User collection
      const user = users.find((u) => u.id === request.toUserId);
      const isVerifiedUser = user && user.verified === true;
      const isTgVerified = user && user.tgVerified === true;
  
      // Filter by Request Types (handle missing requestTypes or empty array)
      const matchesRequestTypes =
        selectedRequestTypes.length === 0 || 
        (Array.isArray(request?.requestTypes) && 
          request.requestTypes.some((type) =>
            selectedRequestTypes.includes(type)
          ));
  
      // Filter by Categories (handle missing tocategory)
      const matchesCategories =
        selectedCategories.length === 0 ||
        (Array.isArray(request?.tocategory) && 
          request.tocategory.some((category) =>
            selectedCategories.includes(category)
          ));
  
      // Filter by Ecosystems (handle missing toecosystem)
      const matchesEcosystems =
        selectedEcosystems.length === 0 ||
        (Array.isArray(request?.toecosystem) && 
          request.toecosystem.some((ecosystem) =>
            selectedEcosystems.includes(ecosystem)
          ));
  
      // Filter by Funding Stages (handle missing tofundingstage)
      const matchesFundingStages =
        selectedFundingStages.length === 0 ||
        (Array.isArray(request?.tofundingstage) && 
          request.tofundingstage.some((fs) =>
            selectedFundingStages.includes(fs)
          ));
  
      // Filter by Partnership Interests (handle missing topartnershipinterest)
      const matchesPartnershipInterests =
        selectedPartenerShipInterests.length === 0 ||
        (Array.isArray(request?.topartnershipinterest) && 
          request.topartnershipinterest.some((interest) =>
            selectedPartenerShipInterests.includes(interest)
          ));
  
      // Filter by Location (handle missing tolocation, and ensure it's case-insensitive)
      const matchesLocation =
        selectedLocation.length === 0 ||
        (request?.tolocation &&
          selectedLocation.some((location) =>
            request.tolocation.includes(location)
          )) || (request?.location &&
            selectedLocation.some((location) =>
              request.location.includes(location)
            ));
  
      // Filter by Profile Status
      const matchesProfileStatus =
        selectedProfileStatus.length === 0 ||
        (selectedProfileStatus.includes("Email Verified") && isVerifiedUser) ||
        (selectedProfileStatus.includes("TG Verified") && isTgVerified);
  
      // Return true if all filters match
      return (
        matchesRequestTypes &&
        matchesCategories &&
        matchesEcosystems &&
        matchesFundingStages &&
        matchesPartnershipInterests &&
        matchesLocation &&
        matchesProfileStatus
      );
    });
  };
  //handling accept and deny



  const handleAccept = async (docid) => {
    await updateDoc(doc(db, "UserConnects", docid), {
      status: "Accepted",
      lastCreatedAt: serverTimestamp(),
    });
    await fetchUserConnects();
    toast("Request has been accepted!", {
      position: "top-center",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
   
  };

  const handleDeny = async (docid) => {
    await updateDoc(doc(db, "UserConnects", docid), {
      status: "Denied",
      lastCreatedAt: serverTimestamp(),
    });
    await fetchUserConnects();
    toast("Request has been rejected!", {
      position: "top-center",
      autoClose: 3000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
   
  };

  // Function to handle cancel request (delete operation)
  const handleCancelRequest = async (docid) => {
    try {
      // Reference to the document in UserConnects collection
      const docRef = doc(db, "UserConnects", docid);

      // Delete the document
      await deleteDoc(docRef);
      toast("Request is cancelled!!.", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      

      await fetchUserConnects();
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
  };

  const RejectDummyRequests = [
    {
      id: 1,
      name: "testdemo",
      location: "India",
      type: "Listing",
      date: "10-12-2024",
      message: "Hi I am test demo",
    },
    {
      id: 2,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "This is test demo2",
    },
    // Add more rows as necessary
  ];

 
  return (
    <div className="Requestcontainer">
      {/* Segmented Control */}

      {/* Conditional Rendering of Tables */}
      <div className="table-container">
        {/* Pending request */}
        {activeSegment === "pending" && (
          <table className="pending-table">
            <thead className="tablehead">
              <tr className="tablerow">
                <th className="table-heading id">ID</th>
                <th className="table-heading nop">Name of Project</th>
                <th className="table-heading lstupdt">Last Updated</th>
                <th className="table-heading msg" style={{ border: "none" }}>Message</th>
                <th className="table-heading loc">Location</th>
                <th className="table-heading reqtyp">Request Type</th>
                <th className="table-heading  act">Actions</th>
              </tr>
            </thead>
            <tbody className="table-body">
              {sortRequests(
                filterByRequestTypes(
                  filterRequests(/* matchingPendingRequests */ sample)
                )
              ).map((request, i) => (
                <tr  className="table-row" key={request.id}>
                  <td
                    className="id-request"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    {String(i + 1).padStart(3, "0")}
                  </td>

                  <td>
                    
                    <div
                      className="name-request-pending"
                      
                    >
                      <img
                      className="requestpage-profile-img"
                        src={request.profilePicture}
                        alt="profile-pic"
                        
                      />
                      <div>
                        <span className="name-request-text" title={request.name}>
                        
                        {request.name.length >10
                            ? `${request.name.slice(0, 10)}...`
                            : request.name}
                        </span>
                        
                      </div>
                      
                    </div>
                  </td>

                  <td
                    className="date-request-pending"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    {new Date(request.lastCreatedAt.seconds * 1000)
                      .toLocaleDateString()
                      .replace(/\//g, "-")}
                  </td>

              

                  <td style={{ border: "none",backgroundColor:"rgba(237, 243, 255, 1)" }} >
                    <div className="message-request"
                      
                    >
                       <img
                       className="requestpage-profile-img"
                        src={request?.profilePicture}
                        alt="profile-pic"
                      /> 
                      <div className="message-date">
                        <span className="message-request-text" title={request.message}  onClick={()=>{
                            handleNameClick(request)
                          setIsInboxMessageOpen(request.id)}}>
                          {request.message.length > 70
                            ? `${request.message.slice(0, 70)}...`
                            : request.message}
                        </span>
                        <p style={{ margin: 0 }} className="below-message">
                          {<RequestMessage request={request} />} at{" "}
                          {new Date(request.createdAt.seconds * 1000)
                            .toLocaleDateString()
                            .replace(/\//g, "-")}
                        </p>
                      </div>
                    </div>
                    {isInboxMessageOpen===request.id && (
                          
                          <div className="inbox-popup-overlay">
        
        <RequestInboxMessage setIsInboxMessageOpen={setIsInboxMessageOpen} 
         fromProjectId={fromProjectId} 
         fromUserId={currentUser?.uid} 
          /* toProjectId={ (request?.projectId===fromProjectId)? ( getToProjectId(request?.userId)): (request?.projectId)} */
          toProjectId={toProjectId} 
           toUserId={toUserId}
           nameState={nameState}
           fromName={request?.name} toName={request?.toname}
           fromMessage={request.message}
           messageDate={request.createdAt}
           profilepic={request?.profilePicture}

           />
        
      </div>


  
                          )}
                  </td>

                  <td
                    className="location-request-pending"  title={request?.location[0]}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    
                    {/* {request?.location.join(", ").length >10
                            ? `${request?.location.join(", ").slice(0, 10)}...`
                            : request?.location.join(", ")} */}
                             {Array.isArray(request?.location)
  ? (request.location.join(", ").length > 10
    ? `${request.location.join(", ").slice(0, 10)}...`
    : request.location.join(", "))
  : request?.location }
                  </td>

                  <td
      className="requestType-request"
      title={request?.requestTypes.join(", ")} // Tooltip with all types
      style={{ textAlign: "center", verticalAlign: "middle" }}
    >
      <div className="requestType-request-name"> {request?.requestTypes[0]}</div>
      
     <div className="requestType-request-name">  {request?.requestTypes.length > 1 && (
        <>
          <span
            className="category-more"
            onClick={()=> setShowAll(request.id)}
            style={{ cursor: "pointer", color: "blue" }}
          >
            +{request?.requestTypes.length - 1} More
          </span>

          {showAll ===request.id && (
            <Modal
              maplist={request?.requestTypes.slice(1)} // Pass the remaining items to the modal
              onClose={()=>setShowAll(false)}
            />
          )}
        </>
      )}
      </div>

 
    </td>

                  {/* <td className="action-button-request-pending" style={{ textAlign: "center", verticalAlign: "middle", padding: "0" }}>
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
    <button className="request-page-cancel-button-pending">
      Cancel
    </button>
  </div>
</td> */}
                  <td
                    className="action-button-request-pending"
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        gap: "5px",
                      }}
                    >
                      {request.status === "Pending" ? (
                        <>
                          <button
                            className="request-page-accept-button-pending"
                            onClick={() => handleAccept(request.id)}
                          >
                            Accept
                          </button>
                          <button
                            className="request-page-decline-button-pending"
                            onClick={() => handleDeny(request.id)}
                          >
                            Decline
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="request-page-accept-button-pending"
                            disabled
                            style={{
                              opacity: 0.5, // Optional: Visual effect when disabled
                              cursor: "not-allowed",
                            }}
                          >
                            Accept
                          </button>
                          <button
                            className="request-page-decline-button-pending"
                            disabled
                            style={{
                              opacity: 0.5, // Optional: Visual effect when disabled
                              cursor: "not-allowed",
                            }}
                          >
                            Decline
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* sending request */}

        {activeSegment === "send" && (
          <table className="request-table">
            <thead className="tablehead">
              <tr className="tablerow">
                <th className="table-heading id">ID</th>
                <th className="table-heading nop">Name of Project</th>
                <th className="table-heading lstupdt">Last Updated</th>
                <th className="table-heading msg">Message</th>
                <th className="table-heading loc">Location</th>
                <th className="table-heading reqtyp">Request Type</th>
                <th className="table-heading reqtyp">Status</th>
                <th className="table-heading  act">Actions</th>

                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {sortRequests(
                filterByRequestTypes(filterRequests(sampleSend))
              )?.map((request, i) => (
                <tr style={{borderBottom:"1px solid rgba(245, 245, 245, 1)"}} key={request.id}>
                  <td
                    className="id-request"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    {String(i + 1).padStart(3, "0")}
                  </td>

                  <td>
                    <div
                      className="name-request-pending"
                      
                    >
                      <img className="requestpage-profile-img"
                        src={request?.toprofilePicture}
                        alt="profile-pic"
                        
                      />
                      <div>
                        <span className="name-request-text" title={request.toname}>
                       
                        {request.toname.length >10
                            ? `${request.toname.slice(0, 10)}...`
                            : request.toname}
                        </span>
                        
                      </div>
                      
                    </div>
                  </td>

                  <td
                    className="date-request-pending"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    {new Date(request.lastCreatedAt.seconds * 1000)
                      .toLocaleDateString()
                      .replace(/\//g, "-")}
                  </td>


                  <td style={{ border: "none",backgroundColor:"rgba(237, 243, 255, 1)" }}>
                    <div className="message-request"
                      
                    >
                       <img className="requestpage-profile-img"
                        src={request?.toprofilePicture}
                        alt="profile-pic"
                      /> 
                      <div className="message-date">
                        <div className="message-request-text" title={request.message} onClick={()=> setIsInboxMessageOpen(request.id)}>
                          {request.message.length > 70
                            ? `${request.message.slice(0, 70)}..`
                            : request.message}
                        </div>
                        <p style={{margin:0}} className="below-message">
                          {<RequestMessage request={request} />} at{" "}
                          {new Date(request.createdAt.seconds * 1000)
                            .toLocaleDateString()
                            .replace(/\//g, "-")}
                        </p>
                      </div>
                    </div>
                    {isInboxMessageOpen===request.id && (
                          
                          <div className="inbox-popup-overlay">
        
        <RequestInboxMessage setIsInboxMessageOpen={setIsInboxMessageOpen} 
         fromProjectId={fromProjectId} 
         fromUserId={currentUser?.uid} 
          /* toProjectId={ (request?.projectId===fromProjectId)? ( getToProjectId(request?.userId)): (request?.projectId)} */
          toProjectId={toProjectId} 
           toUserId={toUserId}
           nameState={nameState}
           fromName={request?.name} toName={request?.toname}
           fromMessage={request.message}
           messageDate={request.createdAt}
           profilepic={request?.profilePicture}

           />
      </div>


  
                          )}
                  </td>

                  <td
                    className="location-request-pending" title={request?.tolocation[0]}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                     {/* {request?.tolocation.join(", ").length >10
                            ? `${request?.tolocation.join(", ").slice(0, 10)}...`
                            : request?.tolocation.join(", ")} */}
                             {Array.isArray(request?.tolocation)
  ? (request.tolocation.join(", ").length > 10
    ? `${request.tolocation.join(", ").slice(0, 10)}...`
    : request.tolocation.join(", "))
  : request?.tolocation }
                  </td>

                  <td
      className="requestType-request"
      title={request?.requestTypes.join(", ")} // Tooltip with all types
      style={{ textAlign: "center", verticalAlign: "middle" }}
    >
      <div className="requestType-request-name"> {request?.requestTypes[0]}</div>
      
     <div className="requestType-request-name">  {request?.requestTypes.length > 1 && (
        <>
          <span
            className="category-more"
            onClick={()=> setShowAll(request.id)}
            style={{ cursor: "pointer", color: "blue" }}
          >
            +{request?.requestTypes.length - 1} More
          </span>

          {showAll === request.id && (
            <Modal
              maplist={request?.requestTypes.slice(1)} // Pass the remaining items to the modal
              onClose={()=>setShowAll(false)}
            />
          )}
        </>
      )}
      </div>

 
    </td>
                  <td
                    className="status-request"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    {request?.status}
                  </td>

                  <td
                    className="action-button-request"
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      padding: "0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <button
                        className="request-page-cancel-button"
                        onClick={request?.status === "Pending"?() => handleCancelRequest(request.id):undefined}
                        style={{ opacity: request?.status !== "Pending"?"0.5":undefined }}
                      >
                        Cancel
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* rejected request */}
        {activeSegment === "rejected" && (
          <table className="reject-table">
            <thead className="tablehead">
              <tr className="tablerow">
                <th className="table-heading id">ID</th>
                <th className="table-heading nop">Name of Project</th>
                <th className="table-heading lstupdt">Last Updated</th>
                <th className="table-heading msg">Message</th>
                <th className="table-heading loc">Location</th>
                <th className="table-heading reqtyp">Request Type</th>
               
                

                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {sortRequests(
                filterByRequestTypes(filterRequests(sampleReject))
              )?.map((request, i) => (
                <tr style={{borderBottom:"1px solid rgba(245, 245, 245, 1)"}} key={request.id}>
                  <td
                    className="id-request"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    {String(i + 1).padStart(3, "0")}
                  </td>

                  <td>
                  <div
                      className="name-request-pending"
                      
                    >
                      <img className="requestpage-profile-img"
                        src={request?.toprofilePicture}
                        alt="profile-pic"
                        
                      />
                      <div>
                        <span className="name-request-text" title={request.toname}>
                        
                        {request.toname.length >10
                            ? `${request.toname.slice(0, 10)}...`
                            : request.toname}
                        </span>
                        
                      </div>
                      
                    </div>
                  </td>

                  <td
                    className="date-request-pending"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    {new Date(request.lastCreatedAt.seconds * 1000)
                      .toLocaleDateString()
                      .replace(/\//g, "-")}
                  </td>


                  <td style={{ border: "none",backgroundColor:"rgba(237, 243, 255, 1)" }}>
                    <div className="message-request"
                      
                    >
                      <img
                        className="requestpage-profile-img"
                        src={request?.toprofilePicture}
                        alt="profile-pic"
                      />
                      
                      <div className="message-date">
                        <span className="message-request-text" title={request.message} onClick={()=>setIsInboxMessageOpen(request.id)}>
                          {request.message.length >70
                            ? `${request.message.slice(0, 70)}...`
                            : request.message}
                        </span>
                        <p style={{ margin: 0 }} className="below-message">
                          {<RequestMessage request={request} />} at{" "}
                          {new Date(request.createdAt.seconds * 1000)
                            .toLocaleDateString()
                            .replace(/\//g, "-")}
                        </p>
                      </div>
                    </div>

                    {isInboxMessageOpen===request.id && (
                          
                          <div className="inbox-popup-overlay">
        
        <RequestInboxMessage setIsInboxMessageOpen={setIsInboxMessageOpen} 
         fromProjectId={fromProjectId} 
         fromUserId={currentUser?.uid} 
          /* toProjectId={ (request?.projectId===fromProjectId)? ( getToProjectId(request?.userId)): (request?.projectId)} */
          toProjectId={toProjectId} 
           toUserId={toUserId}
           nameState={nameState}
           fromName={request?.name} toName={request?.toname}
           fromMessage={request.message}
           messageDate={request.createdAt}
           profilepic={request?.profilePicture}

           />
        
      </div>


  
                          )}
                  </td>

                  <td
                    className="location-request-pending" title={request?.tolocation[0]}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    
                    {/* {request?.tolocation.join(", ").length >10
                            ? `${request?.tolocation.join(", ").slice(0, 10)}...`
                            : request?.tolocation.join(", ")} */}
                            {Array.isArray(request?.tolocation)
  ? (request.tolocation.join(", ").length > 10
    ? `${request.tolocation.join(", ").slice(0, 10)}...`
    : request.tolocation.join(", "))
  : request?.tolocation }
                  </td>

                  <td
      className="requestType-request"
      title={request?.requestTypes.join(", ")} // Tooltip with all types
      style={{ textAlign: "center", verticalAlign: "middle" }}
    >
      <div className="requestType-request-name"> {request?.requestTypes[0]}</div>
      
     <div className="requestType-request-name">  {request?.requestTypes.length > 1 && (
        <>
          <span
            className="category-more"
            onClick={()=> setShowAll(request.id)}
            style={{ cursor: "pointer", color: "blue" }}
          >
            +{request?.requestTypes.length - 1} More
          </span>

          {showAll === request.id && (
            <Modal
              maplist={request?.requestTypes.slice(1)} // Pass the remaining items to the modal
              onClose={()=>setShowAll(false)}
            />
          )}
        </>
      )}
      </div>

 
    </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Follow request */}

        {activeSegment === "followup" && (
          <table className="request-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name of Project</th>
                <th>Date</th>
                <th>Message</th>
                <th>Location</th>
                <th>Request Type</th>
                <th>Follow-up</th>
              </tr>
            </thead>
            <tbody>
              {sortRequests(RejectDummyRequests)?.map((request) => (
                <tr key={request.id}>
                  <td>{request.id}</td>
                  <td>{request.name}</td>
                  <td>{request.date}</td>
                  <td>{request.message}</td>
                  <td>{request.location}</td>
                  <td>{request.type}</td>
                  {/* <td>
                    <button className="action-btn">Request Sent</button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <ToastContainer toastStyle={{
          position:"absolute",
          margin:"auto",
          alignContent:"center",
          top: "10px", // Force position closer to the top
          transform: "translateY(-50%)", // Adjust to vertically center at the very top
        }}/>
    </div>
  );
};

export default Segmentcontrol;
