import React, { useEffect, useState } from "react";
import Navbar from "../Dashboard/Navbar";
import SortIcon from "../../Images/FilterIcon.png";
import plus from "../../Images/Symbol.png";
import Segmentcontrol from "./Segmentcontrol";
import Sidebar from "../Dashboard/Sidebar";
import { FilterProvider } from "../Dashboard/FilterContext";
import "./RequestPage.css";
import Magnify from '../../Images/Magnify.png';
import ReactPaginate from "react-paginate";
import { IconContext } from "react-icons/lib";
import { AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import RightArrow from "../../Images/RightArrow.png";
import LeftArrow from "../../Images/LeftArrow.png";
import { getAuth, onAuthStateChanged ,signOut} from "firebase/auth";
import { db } from "../Firebase/firebase";
import FilterMobile from "../../Images/Filtermobile.svg";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { FaBullseye } from "react-icons/fa6";
import MainNavbar from "../MainNavbar";
import Raisalogo from '../../Images/RaisaN.svg'
import dis from '../../Images/discovernew.svg'
import inb from '../../Images/inboxnew.svg'
import req from '../../Images/requestnew.svg'
import sav from '../../Images/savenew.svg'
import noti from '../../Images/notificationnew.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Pcagination from "../Paginantion";
import MobilePagination from "../mobilepagination";
import disb from '../../Images/discoverblue.svg'
import inbb from '../../Images/inboxblue.svg'
import reqb from '../../Images/requestblue.svg'
import savb from '../../Images/saveblue.svg'
import notib from '../../Images/notifiyblue.svg'
import { toast, ToastContainer } from "react-toastify";
const RequestPage = ({ selectedFilters }) => {
   // Sample data for the tables
   const pendingRequests = [
    {
      id: 1,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 2,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 3,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 4,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 5,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 6,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 7,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 8,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 9,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 10,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 11,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 12,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 13,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 14,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 15,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    // Add more rows as necessary
  ];


  const sendRequests = [
    {
      id: 1,
      name: "send",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 2,
      name: "PreludeSys Sen2",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 3,
      name: "udeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 4,
      name: "PrelSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 5,
      name: "send",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 6,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 7,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 8,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 9,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 10,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 11,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 12,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 13,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 14,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 15,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    // Add more rows as necessary
  ];

  const rejectRequests = [
    {
      id: 1,
      name: "Reject",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 2,
      name: "Rejectall",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 3,
      name: "udeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 4,
      name: "PrelSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 5,
      name: "send",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 6,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 7,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 8,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 9,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 10,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 11,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 12,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 13,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 14,
      name: "PreludeSys",
      location: "Hungary",
      type: "Listing",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    {
      id: 15,
      name: "PreludeSys",
      location: "India",
      type: "Funding",
      date: "09-12-2024",
      message: "Last message: 23-10-2024",
    },
    // Add more rows as necessary
  ];
  

  


  const [currentUser, setCurrentUser] = useState(null);
  const [userConnectsList, setUserConnectsList] = useState([]);
  const [matchingPendingRequests, setMatchingPendingRequests] = useState([]);
  const [matchingSendRequests, setMatchingSendRequests] = useState([]);
  const [matchingRejectedRequests, setMatchingRejectedRequests] = useState([]);
  const [samplePendingList,setSamplePendingList]=useState(pendingRequests)
  const [sampleFilterPendingList,setSampleFilterPendingList]=useState([])
  const [sampleSendList,setSampleSendList]=useState(sendRequests)
  const [sampleFilterSendList,setSampleFilterSendList]=useState([])
  const [sampleRejectList,setSampleRejectList]=useState(rejectRequests)
  const [sampleFilterRejectList,setSampleFilterRejectList]=useState([])
  const defaultsegment = localStorage.getItem("sentrequest")
  const [activeSegment, setActiveSegment] = useState(defaultsegment?defaultsegment:"pending");

  const [searchQuery, setSearchQuery] = useState("");
  //page
  const[pendingPage,setPendingPage]=useState(0)
  const [sendPage,setSendPage]=useState(0)
  const [rejectPage,setRejectPage]=useState(0)
 
  const [showModal, setShowModal] = useState(false);
  const [sortOptions, setSortOptions] = useState({
    newest: false,
    oldest: false,
    az: false,
    za: false,
  });

  const [isMobileFilterOpen,setIsMobileFilterOpen]=useState(false)
  const [hoveredItem, setHoveredItem] = useState(null);
  const [buttonActive,setButtonActive]=useState(null)
  const location = useLocation();
  const handleButtonClick = (button) => {
    setButtonActive(button);
    localStorage.setItem('buttonActive', button); 
  };


  useEffect(() => {
    
    const savedActiveButton = localStorage.getItem('buttonActive');
    if (savedActiveButton) {
      setButtonActive(savedActiveButton);
    }

   
    const currentPath = location.pathname.replace('/', ''); 
    setButtonActive(currentPath);
  }, [location.pathname]);

  // Handle mouse enter and leave for hover state
  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };


  const handleMouseLeave = () => {
    setHoveredItem(null);
  };


  const navigate = useNavigate(); 
  const handleLogout = () => {
    localStorage.removeItem("selectedProject");
    const auth = getAuth(); // Get the current authentication instance
    signOut(auth)
      .then(() => {
        // Sign-out successful, redirect user to login page
        navigate('/login'); // Redirecting to login page after logout
      })
      .catch((error) => {
        // An error happened during logout
        console.error('Logout Error:', error);
      });
  };
  useEffect(() => {
    const auth = getAuth();
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });
    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

   // Fetch userConnects data
   const fetchUserConnects = async () => {
    try {
      // Reference to the UserConnects collection
      const userConnectsCollection = collection(db, "UserConnects");

      // Fetch all documents from the collection
      const querySnapshot = await getDocs(userConnectsCollection);

      // Map through the documents to create a list
      const userConnectsList = querySnapshot.docs.map((doc) => ({
        id: doc.id, // Document ID
        ...doc.data(), // Document data
      }));

      console.log("Fetched User Connects: ", userConnectsList);
      setUserConnectsList(userConnectsList);

      // Filter to find all matches where toUserId equals currentUser.uid and status==="pending"
      const matchingPendingRequests = userConnectsList.filter(
        (ele) => ele.toUserId === currentUser?.uid && ele.status === "Pending"
      );

      const matchingRejectedRequests = userConnectsList.filter(
        (ele) =>
          (/* ele.toUserId === currentUser?.uid || */
            ele.userId === currentUser?.uid) &&
          ele.status === "Denied"
      );

      // Filter to find all matches where userId equals currentUser.uid
      const matchingSendRequests = userConnectsList.filter(
        (ele) => ele.userId === currentUser?.uid
      );

      setMatchingPendingRequests(matchingPendingRequests);
      setMatchingSendRequests(matchingSendRequests);
      setMatchingRejectedRequests(matchingRejectedRequests);
    } catch (e) {
      toast.error("Error fetching Data");
      return [];
    }
  };

  useEffect(() => {
    fetchUserConnects();
  }, [db, currentUser?.uid]);
 
  const handleSortChange = (e) => {
    const { name, checked } = e.target;
    setSortOptions({ ...sortOptions, [name]: checked });
  };

  useEffect(()=>{
    setSampleFilterPendingList(
      matchingPendingRequests.filter((item, index) => {
        return (index >= pendingPage * 10) & (index < (pendingPage + 1) * 10);
      })
    );
  },[matchingPendingRequests,pendingPage])

  useEffect(()=>{
    setSampleFilterSendList(
      matchingSendRequests.filter((item, index) => {
        return (index >= sendPage * 10) & (index < (sendPage + 1) * 10);
      })
    );
  },[matchingSendRequests,sendPage])

  useEffect(()=>{
    setSampleFilterRejectList(
      matchingRejectedRequests.filter((item, index) => {
        return (index >= rejectPage * 10) & (index < (rejectPage + 1) * 10);
      })
    );
  },[matchingRejectedRequests,rejectPage])

  const handleFilterOpen = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
    
  };

  

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click happened outside the modal content
      if (isMobileFilterOpen && !event.target.closest('.filter-mobile-design')) {
        setIsMobileFilterOpen(false);
      }




    };


    

    // Add the event listener if the modal is open
    if (isMobileFilterOpen) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      // Remove the event listener when the modal closes or on cleanup
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isMobileFilterOpen]);

  const handlePendingPageChange = (pendingPage)=>{
    setPendingPage(pendingPage - 1)
  }
  const handlesendingPageChange = (sendPage)=>{
    setSendPage(sendPage - 1)
  }
  const handleRejectingPageChange = (rejectPage)=>{
    setRejectPage(rejectPage - 1)
  }
 
  document.body.style.background = "rgba(234, 239, 255, 1)";
 
  return (
    <div className="request-page-main-container">
      <FilterProvider>
        <div className="request-page-navbar"><Navbar /></div>
        <div className="request-page-mobile-navbar">
        <div className="req-navbar-m">
<div className="req-nav-cntr">
  <img className="req-raisa-logo-img" src={Raisalogo} alt="raisa" onClick={()=>{navigate('/dashboard')}}/>
  <ul className="req-header-ul">
      {/* <li
        className="headerli dis"
       
      >
          <Link to="/dashboard" style={{ textDecoration: 'none'}} >
        <img className="headerli-img " src={dis} alt="discover" />
        
       
          
          </Link>
        
      </li> */}
      <li
        className="req-headerli"
        onMouseEnter={() => handleMouseEnter('inbox')}
        onMouseLeave={handleMouseLeave}
      >
       <Link to="/inbox" style={{ textDecoration: 'none' ,height:'18px'  }} >
        <img className="headerli-img"  src={hoveredItem === 'inbox' || buttonActive === 'inbox' ? inbb : inb} alt="inbox"  / >
        
        
          
        </Link>
      </li>
      

      <li
        className="req-headerli"
        onMouseEnter={() => handleMouseEnter('requestpage')}
        onMouseLeave={handleMouseLeave}
      >
         <Link to="/requestpage" style={{ textDecoration: 'none',height:'18px' }} >
        <img className="headerli-img" src={hoveredItem === 'requestpage' || buttonActive === 'requestpage' ? reqb : req} alt="request" />
       
          
        </Link>
      </li>
      <li
        className="req-headerli"
        onClick={()=> alert("Coming Soon")}
      >
        <Link  style={{ textDecoration: 'none' ,height:'18px'  }} >
        <img className="headerli-img" src={sav} alt="saved" />
        
         
        </Link>
      </li>
      <li
        className="req-headerli"
        onClick={()=> alert("Coming Soon")}
      > <Link  style={{ textDecoration: 'none' ,height:'18px'  }} >
        <img className="headerli-img" src={noti} alt="notifications" />
       
          
        </Link>
      </li>
    </ul>

    
            <button className="req-logout-btn"  onClick={handleLogout}>Logout</button>
         

</div>

      




</div>

        </div>
        
        <div className="requestpage-container">
        <div className="segmented-control">
          <button
            className={`segment ${activeSegment === "pending" ? "active" : ""}`}
            onClick={() => setActiveSegment("pending")}
          >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="segment-icon"
            >
              <path d="M7 0.46875C10.7461 0.46875 13.7812 3.50391 13.7812 7.25C13.7812 10.9961 10.7461 14.0312 7 14.0312C3.25391 14.0312 0.21875 10.9961 0.21875 7.25C0.21875 3.50391 3.25391 0.46875 7 0.46875ZM10.9375 8.01562V6.48438C10.9375 6.32031 10.7734 6.15625 10.6094 6.15625H8.09375V3.64062C8.09375 3.47656 7.92969 3.3125 7.76562 3.3125H6.23438C6.04297 3.3125 5.90625 3.47656 5.90625 3.64062V6.15625H3.39062C3.19922 6.15625 3.0625 6.32031 3.0625 6.48438V8.01562C3.0625 8.20703 3.19922 8.34375 3.39062 8.34375H5.90625V10.8594C5.90625 11.0508 6.04297 11.1875 6.23438 11.1875H7.76562C7.92969 11.1875 8.09375 11.0508 8.09375 10.8594V8.34375H10.6094C10.7734 8.34375 10.9375 8.20703 10.9375 8.01562Z" />
            </svg>
            Pending Requests
          </button>
 
          <button
            className={`segment ${activeSegment === "send" ? "active" : ""}`}
            onClick={() => setActiveSegment("send")}
          >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="segment-icon"
            >
              <path d="M7 0.46875C10.7461 0.46875 13.7812 3.50391 13.7812 7.25C13.7812 10.9961 10.7461 14.0312 7 14.0312C3.25391 14.0312 0.21875 10.9961 0.21875 7.25C0.21875 3.50391 3.25391 0.46875 7 0.46875ZM10.9375 8.01562V6.48438C10.9375 6.32031 10.7734 6.15625 10.6094 6.15625H8.09375V3.64062C8.09375 3.47656 7.92969 3.3125 7.76562 3.3125H6.23438C6.04297 3.3125 5.90625 3.47656 5.90625 3.64062V6.15625H3.39062C3.19922 6.15625 3.0625 6.32031 3.0625 6.48438V8.01562C3.0625 8.20703 3.19922 8.34375 3.39062 8.34375H5.90625V10.8594C5.90625 11.0508 6.04297 11.1875 6.23438 11.1875H7.76562C7.92969 11.1875 8.09375 11.0508 8.09375 10.8594V8.34375H10.6094C10.7734 8.34375 10.9375 8.20703 10.9375 8.01562Z" />
            </svg>{" "}
            Sent Requests
          </button>
          <button
            className={`segment ${
              activeSegment === "rejected" ? "active" : ""
            }`}
            onClick={() => setActiveSegment("rejected")}
          >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="segment-icon"
            >
              <path d="M7 0.46875C10.7461 0.46875 13.7812 3.50391 13.7812 7.25C13.7812 10.9961 10.7461 14.0312 7 14.0312C3.25391 14.0312 0.21875 10.9961 0.21875 7.25C0.21875 3.50391 3.25391 0.46875 7 0.46875ZM10.9375 8.01562V6.48438C10.9375 6.32031 10.7734 6.15625 10.6094 6.15625H8.09375V3.64062C8.09375 3.47656 7.92969 3.3125 7.76562 3.3125H6.23438C6.04297 3.3125 5.90625 3.47656 5.90625 3.64062V6.15625H3.39062C3.19922 6.15625 3.0625 6.32031 3.0625 6.48438V8.01562C3.0625 8.20703 3.19922 8.34375 3.39062 8.34375H5.90625V10.8594C5.90625 11.0508 6.04297 11.1875 6.23438 11.1875H7.76562C7.92969 11.1875 8.09375 11.0508 8.09375 10.8594V8.34375H10.6094C10.7734 8.34375 10.9375 8.20703 10.9375 8.01562Z" />
            </svg>{" "}
            Rejected Requests
          </button>
           <button title="Coming Soon"
            className={`segment ${
              activeSegment === "followup" ? "active" : ""
            }`}
            /* onClick={() => setActiveSegment("followup")} */
          >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="segment-icon"
            >
              <path d="M7 0.46875C10.7461 0.46875 13.7812 3.50391 13.7812 7.25C13.7812 10.9961 10.7461 14.0312 7 14.0312C3.25391 14.0312 0.21875 10.9961 0.21875 7.25C0.21875 3.50391 3.25391 0.46875 7 0.46875ZM10.9375 8.01562V6.48438C10.9375 6.32031 10.7734 6.15625 10.6094 6.15625H8.09375V3.64062C8.09375 3.47656 7.92969 3.3125 7.76562 3.3125H6.23438C6.04297 3.3125 5.90625 3.47656 5.90625 3.64062V6.15625H3.39062C3.19922 6.15625 3.0625 6.32031 3.0625 6.48438V8.01562C3.0625 8.20703 3.19922 8.34375 3.39062 8.34375H5.90625V10.8594C5.90625 11.0508 6.04297 11.1875 6.23438 11.1875H7.76562C7.92969 11.1875 8.09375 11.0508 8.09375 10.8594V8.34375H10.6094C10.7734 8.34375 10.9375 8.20703 10.9375 8.01562Z" />
            </svg>
            Follow up Requests
          </button> 
        </div>
        <div className="sidebar-req-cntainer">
          <div className="requestpage-sidebar"><Sidebar /></div>
          <div className="requesttable">
            <div className="search-filter">
              <div className="search-bar-req">
              <input className="input-req"
  style={{
    borderRadius: '11px',
    border: "1px solid #B7B7B7",
    fontSize: "14px",
    fontWeight: "400",
    color: "#282828",
    backgroundImage: `url(${Magnify})`, 
    backgroundRepeat: "no-repeat",
    backgroundPosition: "17px center", // Increased horizontal position to move the icon right
    backgroundSize: "24px 24px", // Set image size to 24x24
    paddingLeft: "55px", // Adjusted padding to maintain space between the image and the text
    height: "40px", // Adjust height to ensure proper alignment
  }} 
  type="text" 
  placeholder="search project..." 
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                 {/* <div className="filter-mobile-design">
                 <p>hi</p>
               </div>  */}
              </div>
                 <div className="filter-mobile-design">
                <img src={FilterMobile} alt="filter-mobile" onClick={handleFilterOpen}/>
                {isMobileFilterOpen && (<div className="requestmodal-content1">
                <Sidebar/>
               </div>)}
               </div>
               
     
              <div  className="filterdiv">
                
                <div className="requestfilter-icon">
                  <span
                    onClick={() => setShowModal(!showModal)}
                    style={{ cursor: "pointer", fontSize: "2rem" }}
                  >
                    <img src={SortIcon} alt="sort-list" />
                  </span>
                </div>
 
                {/* The modal appearing beside the image */}
                {showModal && (
                   /* <div className="requestmodal-content">
                    <h4>Sort Options</h4>
                    <label>
                      <input
                        type="checkbox"
                        name="newest"
                        checked={sortOptions.newest}
                        onChange={handleSortChange}
                      />
                      Newest First
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="oldest"
                        checked={sortOptions.oldest}
                        onChange={handleSortChange}
                      />
                      Oldest First
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="az"
                        checked={sortOptions.az}
                        onChange={handleSortChange}
                      />
                      A-Z
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="za"
                        checked={sortOptions.za}
                        onChange={handleSortChange}
                      />
                      Z-A
                    </label>
                  </div>  */
                  <div className="requestmodal-content">
  {/* <h4>Sort Options</h4> */}
  <label className="checkbox-label">
    <input className="input-request-checkbox"
      type="checkbox"
      name="newest"
      checked={sortOptions.newest}
      onChange={handleSortChange}
    />
    Newest First
  </label>
  <label className="checkbox-label">
    <input className="input-request-checkbox"
      type="checkbox"
      name="oldest"
      checked={sortOptions.oldest}
      onChange={handleSortChange}
    />
    Oldest First
  </label>
  <label className="checkbox-label">
    <input className="input-request-checkbox"
      type="checkbox"
      name="az"
      checked={sortOptions.az}
      onChange={handleSortChange}
    />
    A-Z
  </label>
  <label className="checkbox-label">
    <input className="input-request-checkbox"
      type="checkbox"
      name="za"
      checked={sortOptions.za}
      onChange={handleSortChange}
    />
    Z-A
  </label>
</div>

                  
                )}
              </div>
            </div>
            <Segmentcontrol
              activeSegment={activeSegment}
              sortOptions={sortOptions}
              searchQuery={searchQuery}
              fetchUserConnects={fetchUserConnects}
              matchingPendingRequests={matchingPendingRequests}
              matchingSendRequests={matchingSendRequests}
              matchingRejectedRequests={matchingRejectedRequests}
              sample={sampleFilterPendingList}
              sampleSend={sampleFilterSendList}
              sampleReject={sampleFilterRejectList}

            />
            <div className="pagination-request">
            {activeSegment==="pending" && (
  <MobilePagination
  currentPage={pendingPage + 1}
 
  totalPages={Math.ceil(matchingPendingRequests.length/10)}
  onPageChange={handlePendingPageChange}
/>
  
  )}
  {activeSegment==="send" && (
 

  <MobilePagination
  currentPage={sendPage + 1}
 
   totalPages={ Math.ceil(matchingSendRequests.length/10)}
  onPageChange={handlesendingPageChange}
/>
  )}

  {activeSegment==="rejected" && (


<MobilePagination
currentPage={rejectPage + 1}

 totalPages={Math.ceil(matchingRejectedRequests.length/10)}
onPageChange={handleRejectingPageChange}
/>
  )}
</div>

          </div>
          </div>
        </div>
      </FilterProvider>
      <ToastContainer/>
    </div>
  );
};
 
export default RequestPage;