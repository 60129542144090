// import React, { useEffect, useState } from 'react';
// import { Navigate } from 'react-router-dom';
// import { getAuth, onAuthStateChanged } from 'firebase/auth';

// const PrivateRoute = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const auth = getAuth();
//     const unsubscribe = onAuthStateChanged(auth, (user) => {
//       setUser(user);
//       setLoading(false); // Set loading to false once auth state is known
//     });

//     return () => unsubscribe(); // Cleanup the listener
//   }, []);

//   if (loading) {
//     return <div>Loading...</div>; // Show loading state while authentication status is being checked
//   }

//   return user ? children : <Navigate to="/login" />;
// };

// export default PrivateRoute;

import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "./Firebase/firebase";
import "./PrivateRoute.css";
import HelpSignup from "./HelpSignup";
import { Help } from "./Signup.style";
import helpbutton from "../Images/Help.png";
import closebutton from "../Images/Closebutton.png";
import { ImageHash } from "./Login.style";

const PrivateRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(null); // Track verification status
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleHelpModal = () => {
    setIsHelpModalOpen(true);
  };
  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUser(user);
        await user.reload(); // Reload to get the latest email verification status

        // Real-time listener for user's Firestore document
        const userDocRef = doc(db, "User", user.uid);
        const unsubscribeFirestore = onSnapshot(userDocRef, (docSnap) => {
          if (docSnap.exists()) {
            const userData = docSnap.data();
            // Check if 'verified' field is true and update state
            setIsVerified(userData.verified || false);
          } else {
            setIsVerified(false); // No user document means not verified
          }
        });

        setLoading(false); // Stop loading once document is fetched

        // Clean up Firestore listener on unmount
        return () => unsubscribeFirestore();
      } else {
        setLoading(false); // Stop loading if no user is logged in
      }
    });

    // Cleanup auth listener on unmount
    return () => unsubscribeAuth();
  }, []);

  if (loading) {
    return (
      <div class="loading-dots">
        Loading<span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    ); // Show loading state while checking
  }

  // Redirect to login if no user or under review if not verified
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (isVerified === false) {
    return (
      <>
        {/* <div className="account-review-container">
      <div className="account-review-card">
        <div className="loading-spinner"></div>
        <h2>Your Account is Under Review</h2>
        <p>Thank you for signing up with Raisa.Network.</p>
       <p>To maintain a secure and trusted environment, all accounts undergo a review process designed to ensure authenticity, prevent spam, and uphold the platform's integrity.  
       </p>
       <h2>Verification Methods:</h2>
       <ul style={{ listStyle:"none"}}>
        <li> <h4>Domain Email Verification:</h4> <p>If you are registering with an official domain email, please verify the email.</p>
        </li>
        <li><h4>Telegram Chat Verification:</h4> <p>If you are using an alternate email address to register. A brief verification will take place in the projects telegram group. One of our team members will write in your official TG group to confirm the admin tag before granting access.  
        </p>
        </li>
       </ul>

       <h2>Eligibility Criteria: 

       </h2>
       <p>To maintain quality, agencies, AMA groups, communities and service providers are not granted access. Raisa.Network is reserved for projects pursuing meaningful partnerships.  
       </p>
       <h2>
       What to Expect:  </h2>
       <ul style={{ listStyle:"none"}}>
        <li><p>The review process typically takes up to 2 hours.</p></li>
        <li><p>Once approved, a confirmation email will be sent, granting full access to the platform.</p></li>
        <li> <p> Accounts receive free requests each month to explore partnership opportunities without cost.  </p>
        </li>
       </ul>
       <p>
       Thank you for your patience as we ensure a trusted and professional experience. For support, please contact our TG group or email here.</p>
      </div>
      
    </div>
     */}
        <div className="account-review-container">
          <div className="account-review-card">
            <div className="loading-spinner"></div>
            <h2>Your Account is Under Review</h2>
            <p>Thank you for signing up with Raisa.Network.</p>
            <p>
              To maintain a secure and trusted environment, all accounts undergo
              a review process designed to ensure authenticity, prevent spam,
              and uphold the platform's integrity.
            </p>
            <h3>Verification Methods</h3>
            <ul style={{ listStyle: "none", padding: "0 35px" }}>
              <li>
                <p>
                  1. <strong>Domain Email Verification :</strong> If you are
                  registering with an official domain email, please verify the
                  email.
                </p>
              </li>
              <li>
                <p>
                  {" "}
                  2. <strong>Telegram Chat Verification :</strong> If you are
                  using an alternate email address to register, a brief
                  verification will take place in the project's Telegram group.
                  One of our team members will write in your official TG group
                  to confirm the admin tag before granting access.
                </p>
              </li>
            </ul>
            <h3>Eligibility Criteria:</h3>
            <p>
              To maintain quality, agencies, AMA groups, communities, and
              service providers are not granted access. Raisa.Network is
              reserved for projects pursuing meaningful partnerships.
            </p>
            <h3>What to Expect:</h3>
            <ul style={{ listStyle: "none", padding: "0" }}>
              <li>
                <p>The review process typically takes up to 2 hours.</p>
              </li>
              <li>
                <p>
                  Once approved, a confirmation email will be sent, granting
                  full access to the platform.
                </p>
              </li>
              <li>
                <p>
                  Accounts receive free requests each month to explore
                  partnership opportunities without cost.
                </p>
              </li>
            </ul>
            <strong>
              Thank you for your patience as we ensure a trusted and
              professional experience. For support, please contact our TG group
              or email here.<a href="mailto:team@raisa.network" style={{ color: 'blue', textDecoration: 'underline' }}>
    team@raisa.network
  </a>.
            </strong>
          </div>
        </div>

        {/* <div>Your Account is under review. Please wait for approval.</div> */}
      </>
    );
  }
    return React.isValidElement(children)
  ? React.cloneElement(children, { user })
  : children;
  // return children; // Render children if authenticated and verified
};

export default PrivateRoute;
