import React, { useEffect, useState } from "react";
import "../Inbox/InboxMessage.css";
import "./RequestInboxMessage.css";
import CloseButton from "../../Images/icon.png";
import { db } from "../Firebase/firebase";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Navigate } from "react-router-dom";

const RequestInboxMessage = ({
  setIsInboxMessageOpen,
  fromProjectId,
  fromUserId,
  toProjectId,
  toUserId,
  fromName,
  toName,
  nameState,
  fromMessage,
  messageDate,
  profilepic
}) => {
  const [inboxMsg, setInboxMsg] = useState("");
  const [messages, setMessages] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [toProject,setToProject]=useState(null);
 


  useEffect(() => {
    const auth = getAuth();
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
      }
    });
    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);
  /* async function fetchMessages() {
    if (!fromProjectId || !toProjectId) {
      console.error("Missing project IDs");
      return;
    }

    try {
      const inboxRef = collection(db, "inbox");

      // First query: toProjectId matches either fromProjectId or toProjectId
      const q1 = query(
        inboxRef,
        where("toProjectId", "===", [fromProjectId, toProjectId])
      );

      // Second query: fromProjectId matches either fromProjectId or toProjectId
      const q2 = query(
        inboxRef,
        where("fromProjectId", "in", [fromProjectId, toProjectId])
      );

      // Execute both queries
      const [snapshot1, snapshot2] = await Promise.all([getDocs(q1), getDocs(q2)]);

      // Combine the results
      const fetchedMessages = [
        ...snapshot1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
        ...snapshot2.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      // Remove duplicates (optional)
      const uniqueMessages = Array.from(
        new Map(fetchedMessages.map((msg) => [msg.id, msg])).values()
      );

      // Sort messages by dateTime (assuming dateTime is a Firestore timestamp)
      const sortedMessages = uniqueMessages.sort(
        (a, b) => a.dateTime.seconds - b.dateTime.seconds
      );

      // Set state with the sorted messages
      setMessages(sortedMessages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  } */

  async function fetchMessages() {
    if (!fromProjectId || !toProjectId) {
      console.error("Missing project IDs");
      return;
    }

    try {
      const inboxRef = collection(db, "inbox");
      console.log("ToProjectId query", toProjectId);
      console.log("FROMPROJECTID query", fromProjectId);
      const projectIds = [fromProjectId, toProjectId];
      // First query: toProjectId matches either fromProjectId or toProjectId
      const q1 = query(
        inboxRef,
        where("toProjectId", "in", projectIds),
        where("fromProjectId", "in", projectIds)
      );
      // Execute the query
      const querySnapshot = await getDocs(q1);
      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      // Display the results
      console.log(results);

      // Execute both queries
      const snapshot1 = await getDocs(q1);

      // Combine the results
      const fetchedMessages = [
        ...snapshot1.docs.map((doc) => ({ id: doc.id, ...doc.data() })),
      ];

      // Remove duplicates (optional)
      const uniqueMessages = Array.from(
        new Map(fetchedMessages.map((msg) => [msg.id, msg])).values()
      );

      // Sort messages by dateTime (assuming dateTime is a Firestore timestamp)
      const sortedMessages = uniqueMessages.sort(
        (a, b) => a.dateTime.seconds - b.dateTime.seconds
      );

      // Set state with the sorted messages
      setMessages(sortedMessages);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }
  useEffect(() => {
    fetchMessages(); // Call the fetch function when component mounts or project IDs change
  }, [fromProjectId, toProjectId,db]);

  /* const handleInboxSend = async () => {
    try {
      const inboxRef = collection(db, "inbox");
  
      await addDoc(inboxRef, {
        dateTime: new Date(),
        fromProjectId: fromProjectId,   // Add the actual value
        fromUserId: currentUser?.uid,      // Add the actual value
        message: inboxMsg || "",         // Add the actual message
        toProjectId: toProjectId,     // Add the actual value
        toUserId: toUserId,        // Add the actual value
      });
      
      //alert("Message successfully sent!");
      await fetchMessages()
      setInboxMsg("")
    } catch (error) {
      console.error("Error sending inbox message:", error);
    }
  }; */

  const handleInboxSend = async () => {
    try {
      const inboxRef = collection(db, "inbox");

      /* const isSender = currentUser?.uid === fromUserId;
  
      
      const fromProject = isSender ? fromProjectId : toProjectId;
      const toProject = isSender ? toProjectId : fromProjectId;
      const fromUser = isSender ? currentUser?.uid : toUserId;
      const toUser = isSender ? toUserId : currentUser?.uid; */

      await addDoc(inboxRef, {
        dateTime: new Date(),
        fromProjectId: fromProjectId,
        fromUserId: fromUserId || null,
        message: inboxMsg || "",
        toProjectId: toProjectId,
        toUserId: toUserId || null,
      });

      await fetchMessages();
      console.log("currentUser:", currentUser?.uid);
      console.log("fromUserId:", fromUserId);
      console.log("toUserId:", toUserId);
      setInboxMsg(""); // Reset message input field
    } catch (error) {
      console.error("Error sending inbox message:", error);
    }
  };


 

  useEffect(()=>{
    async function getToProjectId(fromName) {
      
  
      try {
        const userProjectRef = collection(db, "UserProject");
        const q = query(userProjectRef, where("name", "==",fromName));
        const querySnapshot = await getDocs(q);
    
        const userProjects = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        
        setToProject(userProjects[0])
        
      } catch (error) {
        console.error("Error fetching user projects:", error);
        return [];
      }
    } 
    getToProjectId(fromName)
  },[fromName])


  const handleopenprofile =()=>{
    

    localStorage.setItem('selectedProject', JSON.stringify(toProject));
    
    
    // Open the new page in a new tab
    window.open('/companydetails', '_blank'); 

  }
  return (
    <div className="inbox-container">
      {/* Close Button outside the header */}
      <div className="inbox-close-btn">
        <img
          src={CloseButton}
          alt="close-button"
          onClick={() => setIsInboxMessageOpen(null)}
        />
      </div>
      {console.log("ToProjectId", toProjectId)}
      {console.log("FROMPROJECTID", fromProjectId)}
      {console.log("TONAME", toName)}
      {console.log("FROMNAME", fromName)}
      {console.log("FromUserId", fromUserId)}
      {console.log("ToUserId", toUserId)}

      {/* Header Section */}
      <div className="inbox-header">
        <div className="inbox-message-title">Message from {fromName}</div>
        <button className="inbox-view-profile-btn" onClick={handleopenprofile} >View Profile</button>
      </div>


     
     {/* <div>{fromMessage}</div> */}
       {/* <div className="inbox-message-list"> */}
        
          
            <div className="req-message"
              
            //   className={`inbox-message-item ${
            //     message.fromUserId === currentUser?.uid
            //       ? "inbox-your-message"
            //       : "inbox-polkadot-message"
            //   }`}
            >
              {/* <div className="inbox-message-header">
                <span className="inbox-message-sender">
                  {message.fromUserId === currentUser?.uid ? "You" : nameState}
                </span>
                <p className="inbox-message-time">
                  {new Date(
                    message.dateTime.seconds * 1000
                  ).toLocaleDateString()}{" "}
                  <a className="inbox-clock">
                    {new Date(
                      message.dateTime.seconds * 1000
                    ).toLocaleTimeString()}
                  </a>
                </p>
              </div> */}
              <div className="msg-header">
              <img src={profilepic} alt="profile-pic" className="req-profile-pic"/>
              <div className="msg-name-time">
                <div className="msg-toname">{fromName}</div>
              <div className="inbox-message-time">
                  {new Date(
                    messageDate.seconds * 1000
                  ).toLocaleDateString()}{" "}
                  <a className="inbox-clock">
                    {new Date(
                      messageDate.seconds * 1000
                    ).toLocaleTimeString()}
                  </a>
                </div>
              </div>
              </div>
             
              <div className="request-message-content"> {fromMessage}</div>
             
              
              
            </div>
        
      {/* </div>  */}

      {/* Message Input Section */}
      {/* <div className="inbox-message-input-section">
        <p className="inbox-message-heading">Message</p>
        <textarea
          className="inbox-message-input"
          value={inboxMsg}
          placeholder="Write a message..."
          onChange={(e) => setInboxMsg(e.target.value)}
        ></textarea>
        <div className="inbox-send-actions">
          <button className="inbox-attach-btn"></button>
          <button className="inbox-send-btn" onClick={handleInboxSend}>
            Send
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default RequestInboxMessage;
