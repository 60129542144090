import React, { useRef, useState, useEffect } from "react";
import {
  Checkbox,
  Checkbox1,
  CheckboxContainer,
  CheckboxLabel,
  CloseButton,
  Cont2,
  Dash,
  Footer,
  ForgotPassword,
  Form,
  FormContainer,
  Heading,
  ImageHash,
  Input,
  InsideContainer,
  Label,
  LoginButton,
  LoginContainer,
  Logo,
  LogoContainer,
  OutsideForm,
  ReCaptchaContainer,
  ReCaptchaImage,
  ReCaptchaLabel,
  SignupContainer,
  SignupLink,
  SignupText,
  Subtitle,
  Subtitle1,
  Title,
} from "./Login.style";
import closebutton from "../Images/Closebutton.png";
import aquirelab from "../Images/Aquirelabs.png";
import aqdash from "../Images/Aqdash.png";
import aqtext from "../Images/Aquire Labs.png";
import menu from '../Images/hamburger.svg'
import recaptcha from "../Images/recaptcha.png";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "./Firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Star1 } from "./Signup.style";
import { Link, Navigate, useNavigate, useNavigation , useLocation } from "react-router-dom";
import RaisaLogo from "../Images/RaisaN.svg";
import Hash from "../Images/Hash.png";
import Xclose from "../Images/X.svg";
import "./Login.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Navbar from "./Dashboard/Navbar";
import MainNavbar from "./MainNavbar";
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios'; // Import axios here
const Login = () => {
  document.body.style.background = "rgba(242, 246, 255, 1)";

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // New state for Remember Me
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaVerify , setCaptchaVerify] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [buttonActive,setButtonActive]=useState(null)
  const [errMsg , setErrMsg] = useState(false);
  const handleButtonClick = (button) => {
    setButtonActive(button);
    localStorage.setItem('buttonActive', button); 
  };
  const location = useLocation();
  useEffect(() => {
    
    const savedActiveButton = localStorage.getItem('buttonActive');
    if (savedActiveButton) {
      setButtonActive(savedActiveButton);
    }

   
    const currentPath = location.pathname.replace('/', ''); 
    setButtonActive(currentPath);
  }, [location.pathname]);

  const handleCaptchaChange = (token) => {
    // console.log("token rechap",token)
    setCaptchaToken(token);
  };

  /* const [emailError,setEmailError]=useState(null)*/
  /*  const [passwordError,setPasswordError]=useState(null) */

  // Load email from localStorage if Remember Me was checked
  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true); // Mark checkbox as checked
    }
  }, []);

  const handleForgetPassword = async () => {
    navigate("/forgot");
    // await sendPasswordResetEmail(auth, email)
    //   .then(() => {
    //     toast.success("A Password Reset Link has been sent to your Email", {
    //       position: "top-center",
    //     });
    //   })
    //   .catch((error) => {
    //     toast.error("Reset Link failed", { position: "top-center" });
    //   });
  };

  async function verifyRecaptchaFrontend(token) {
    const secretKey = /* "6LcU1IIqAAAAADRvGI-5hpLH2cBvhufSTHc1zV2I"; // Avoid hardcoding this in production */ "6LdQ-JgqAAAAAM3_J43BpFGOB1qsVMC_V4IBlgk2"
  
    try {
      const response = await fetch(
        `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${token}`,
        {
          method: "POST",
        }
      );
      const data = await response.json();
      if (data.success) {
        console.log("reCAPTCHA verified successfully:", data);
      } else {

        console.error("reCAPTCHA verification failed:", data["error-codes"]);

        setCaptchaVerify(false);
      }
    } catch (error) {
      console.error("Error verifying reCAPTCHA:", error);
    }
  }
  



  const handleFormSubmit = async (e) => {
     
    e.preventDefault();
    if (!email) {
      toast.error("Email is required.", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if (!password) {
      toast.error("Password is required.", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    if (!captchaToken) {
      toast.error("Please complete the reCAPTCHA", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }
    try {
      await verifyRecaptchaFrontend(captchaToken);
      if(!captchaVerify){return}
      // // Call Firebase Cloud Function
      // const response = await axios.post(
      //   "http://localhost:5000/verify-recaptcha",
      //   { token: captchaToken }
      // );

      // if (response.data.success) {
      //   alert("Login successful!");
      //   // Add login logic here
      // } else {
      //   alert("reCAPTCHA verification failed.");
      // }
    } catch (error) {

      console.error("Error verifying reCAPTCHA:", error);
      toast.error("An error occurred while verifying reCAPTCHA.", {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }

    try {
      const userCred = await signInWithEmailAndPassword(auth, email, password);
      const user = userCred.user;

      // Check if the email is verified
      if (user.emailVerified) {
        toast.success("Welcome back! You're now logged in!", {
          position: "top-center",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        navigate("/dashboard");

        // Handle Remember Me functionality
        if (rememberMe) {
          localStorage.setItem("email", email); // Save email if Remember Me is checked
        } else {
          localStorage.removeItem("email"); // Clear email if Remember Me is unchecked
        }
      } else {
        toast.error(
          "Email is not verified. Please verify your email before logging in.", {
            position: "top-center",
            autoClose: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
      }
    } catch (error) {
      console.log(error.code);
      
     
      setErrMsg(true);

      console.log("err",errMsg)

      toast.error(error.code , {
        position: "top-center",
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // if (error.code === "auth/wrong-password") {
      //   toast.error("Incorrect password. Please try again.", {
      //     position: "top-center",
      //   });
      //   //setPasswordError("Wrong Password")
      // } else if (error.code === "auth/user-not-found") {
      //   toast.error("Email not found. Please sign up first.", {
      //     position: "top-center",
      //   });
      // } else {
      //   toast.error("Login failed. Please check your credentials.", {
      //     position: "top-center",
      //   });
      // }
    }
  };
  // Toggle between showing and hiding the password
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);

    if (!e.target.checked) {
      localStorage.removeItem("email"); // Remove email from localStorage when unchecked
    }
  };

 

  return (
    <>
      <div className="app-l">
        <div className="a">
          <div className="login-container">
            <div className="login-modal">
              <button
                className="close"
                onClick={() => {
                  navigate("/");
                }}
              >
                <svg
                  width="15"
                  height="23"
                  viewBox="0 0 23 23"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="2"
                    y1="2"
                    x2="21"
                    y2="21"
                    stroke="black"
                    stroke-width="2"
                  />
                  <line
                    x1="2"
                    y1="21"
                    x2="21"
                    y2="2"
                    stroke="black"
                    stroke-width="2"
                  />
                </svg>
              </button>
              <div className="logo-close">
                <img className="logo-R" src={RaisaLogo} alt="raisa-logo" />
              </div>

              <div className="total-form-container">
                {/* <img src={RaisaLogo} alt="raisa-logo"/>
                 */}
                <div className="login-welcome-cntr">
                  <div className="login-txt">Login</div>
                  <div className="welcome-title-sub-titile">
                    <div className="welcm-txt">Welcome Back!</div>
                    <div className="welcm-sub-txt">
                      {" "}
                      Start managing your partnership faster & better
                    </div>
                  </div>
                </div>

                <div className="full-container">
                  <div className="email-password-container-me">
                    <div className="email-password-container">
                      <div className="email-cntr">
                        <Label>
                          Email<a style={{ color: "red" }}>*</a>
                        </Label>
                        <Input
                        style={{borderColor: errMsg && "rgba(255, 57, 50, 1)",}}
                          type="email"
                          placeholder="name@company.com"
                          onChange={(e) =>{ setEmail(e.target.value)
                            setErrMsg(false)
                          }}
                          value={email} // Bind email to state
                        />
                      </div>
                      <div className="email-cntr">
                        <Label>
                          Password<a style={{ color: "red" }}>*</a>
                        </Label>
                        <div style={{ position: "relative", width: "100%" }}>
                          <Input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) =>{ setPassword(e.target.value)
                              setErrMsg(false)
                            }}
                            style={{
                              borderColor: errMsg && "rgba(255, 57, 50, 1)",
                              paddingRight: "40px", // Adjust padding to give space for the eye icon
                              width: "100%",
                              boxSizing: "border-box", // Ensure padding is considered in width
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "55%",
                              right: "10px",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              color: "rgba(120, 139, 165, 1)",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        </div>

                        <ForgotPassword onClick={handleForgetPassword}>
                          Forget Password?
                        </ForgotPassword>
                      </div>
                    </div>
                    <CheckboxContainer>
                      <Checkbox1
                        type="checkbox"
                        checked={rememberMe} // Bind checkbox to state
                        onChange={handleRememberMe} // Update state and localStorage on change
                      />
                      <CheckboxLabel>Remember Me</CheckboxLabel>
                    </CheckboxContainer>
                    <ReCAPTCHA
                      sitekey="6LcU1IIqAAAAAHDCvq4xR5Sx-I_HpZKgfmWPVQYO" // Replace with your actual site key
                      onChange={handleCaptchaChange}
        /> 
                    {/* <ReCaptchaContainer>
                      <Checkbox type="checkbox" />
                      <ReCaptchaLabel>I’m not a robot</ReCaptchaLabel>
                      <ReCaptchaImage src={recaptcha} alt="reCAPTCHA" />
                    </ReCaptchaContainer> */}
                  </div>
                  <div className="full-sub-container">
                    <LoginButton type="button" onClick={handleFormSubmit}>
                      Login
                    </LoginButton>
                    {(console.log("err",errMsg))}
                    <div className="link-footer">
                      <p className="signup-link">
                        Don't have an account?
                        <br /> <a href="/signup">Signup</a>
                      </p>
                      <footer className="rights">
                        © 2024 ALL RIGHTS RESERVED
                      </footer>
                    </div>
                  </div>
                </div>
              </div>
              <img src={Hash} alt="hash" className="imghash" />
            </div>
          </div>
          {/* <img src={Hash} alt="hash" className="imghash" /> */}
        </div>
      </div>

      {/*Mobile design */}
      <div className="mobile-screen">
        <div className="mobile-navbar">
          <div class="navbar-m">
            <div class="logo-m">
              <img src={RaisaLogo} alt="raisa-logo" />
            </div>
            <div className="menu-icon" onClick={() => setShowModal(!showModal)}>
        <img src={menu} alt="menu" />
      </div>
      {showModal && (
        <div className="menumodal-content-l">
         <div className='menu-cntr'>
          <div  className="menu-options" ><Link to="/" style={{ color: buttonActive===null ? 'rgba(26, 13, 171, 1)' : 'black' ,textDecorationLine: 'none'}}>Home</Link></div>
        <div  className="menu-options"><Link to="/about" onClick={()=>handleButtonClick("about")} style={{ color: buttonActive === "about" ? 'rgba(26, 13, 171, 1)' : 'black' ,textDecorationLine: 'none'}}>About</Link></div>
        <div   className="menu-options"><Link to="/faq" onClick={()=>handleButtonClick("faq")} style={{ color: buttonActive === "faq" ? 'rgba(26, 13, 171, 1)' : 'black' ,textDecorationLine: 'none'}}>Help</Link></div>
        </div>
        </div>
      )}

      {/* Slide-in Menu */}
      {/* <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
        <button onClick={() => setMenuOpen(false)} className="close-btn">&times;</button>
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/faq">Help</a>
      </div> */}
          </div>
        </div>

        <div className="login-container-m">
          <div className="login-welcome-cntr">
            <div className="login-txt">Login</div>
            <div className="welcome-title-sub-titile">
              <div className="welcm-txt">Welcome Back!</div>
              <div className="welcm-sub-txt">
                {" "}
                Start managing your partnership faster & better
              </div>
            </div>
          </div>
          <form onSubmit={handleFormSubmit}>
          <div className="full-container">
                  <div className="email-password-container-me">
                    <div className="email-password-container">
                      <div className="email-cntr">
                        <Label>
                          Email<a style={{ color: "red" }}>*</a>
                        </Label>
                        <Input
                          type="email"
                          placeholder="name@company.com"
                          onChange={(e) => {setEmail(e.target.value)
                            setErrMsg(false)
                          }}
                          value={email} // Bind email to state
                          style={{
                            borderColor: errMsg && "rgba(255, 57, 50, 1)",
                          }}
                        />
                      </div>
                      <div className="email-cntr">
                        <Label>
                          Password<a style={{ color: "red" }}>*</a>
                        </Label>
                        <div style={{ position: "relative", width: "100%" }}>
                          <Input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) =>{ setPassword(e.target.value)
                              setErrMsg(false)
                            }}
                            style={{
                              borderColor: errMsg && "rgba(255, 57, 50, 1)",
                              paddingRight: "40px", // Adjust padding to give space for the eye icon
                              width: "100%",
                              boxSizing: "border-box", // Ensure padding is considered in width
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "55%",
                              right: "10px",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                              color: "rgba(120, 139, 165, 1)",
                            }}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </div>
                        </div>

                        <ForgotPassword onClick={handleForgetPassword}>
                          Forget Password?
                        </ForgotPassword>
                      </div>
                    </div>
                    <CheckboxContainer>
                      <Checkbox1
                        type="checkbox"
                        checked={rememberMe} // Bind checkbox to state
                        onChange={handleRememberMe} // Update state and localStorage on change
                      />
                      <CheckboxLabel>Remember Me</CheckboxLabel>
                    </CheckboxContainer>
                    
                    <ReCaptchaContainer>
                    <ReCAPTCHA
                      sitekey=/* "6LcU1IIqAAAAAHDCvq4xR5Sx-I_HpZKgfmWPVQYO" Replace with your actual site key*/  "6LdQ-JgqAAAAALsMKE0fuVb2cowOEJrTBNeITDR0"
                      onChange={handleCaptchaChange}
        /> 
                    </ReCaptchaContainer>
                  </div>
                  <div className="full-sub-container">
                    <LoginButton type="submit" >
                      Login
                    </LoginButton>
                    <div className="link-footer">
                      <p className="signup-link">
                        Don't have an account?
                        <br /> <span className="signup-span" onClick={()=> navigate("/signup")}>Signup</span>
                      </p>
                      <footer className="rights">
                        © 2024 ALL RIGHTS RESERVED
                      </footer>
                    </div>
                  </div>
                </div>
                </form>
        </div>
       
      </div>
      <ToastContainer toastStyle={{
          zIndex:"10",
          position:"absolute",
          margin:"auto",
          alignContent:"center",
          top: "10px", // Force position closer to the top
          transform: "translateY(-50%)", // Adjust to vertically center at the very top
        }}/>
    </>
  );
};

export default Login;
